import React from "react";
// import CaseStudyExtension from "../../../Comman/CaseStudy/CaseStudyExtension";
import "./Extension.css";
import videoSource from '../../../../images/Videos/Case Studies banner.mp4';
import OurValues from "../../AboutUs/teams-group/OurValues";
import ServiceIndustries from "../../../Comman/ServiceComponents/SaasProductService/ServiceIndustries";
// import CEOMessage from "../../../Comman/CeoMassage";


import Vector3 from "../../../../images/chrome-extension-develpment/vector3.svg";
import Vector7 from "../../../../images/chrome-extension-develpment/vector7.svg";
import E_commerce from "../../../../images/chrome-extension-develpment/e-commerce.png";
import saas from "../../../../images/chrome-extension-develpment/saas.png";
import mining from "../../../../images/chrome-extension-develpment/mining.png";
import recruitment from "../../../../images/chrome-extension-develpment/recruitment.png";
import CaseStudyBanner from "../../../Comman/CaseStudyBanner/CaseStudyBanner";
import CaseStudyPageCards from "../../../Comman/CaseStudyBanner/CaseStudyPageCards";

import Integrity from "../../../../images/teams/Integrity.webp";
import ContinouesLearning from "../../../../images/CaseStudiesImagesLogo/Web_Dev_Icon.png";
import Transparancy from "../../../../images/CaseStudiesImagesLogo/AI-ML_Icon.png";
import Web_Auto_Icon from "../../../../images/CaseStudiesImagesLogo/Web_Auto_Icon.png";

const industryData = [
  {
    img: Vector3,
    title: "Health Care ",
  },
  {
    img: Vector7,
    title: "Finance",
  },
  {
    img: E_commerce,
    title: "E-commerce",
  },
  {
    img: saas,
    title: "SaaS",
  },
  {
    img: mining,
    title: "Mining",
  },
  {
    img: recruitment,
    title: "Recruitmemt",
  },
];

const data1 = [
      {
        number: 1,
        para: {
          li: [
            "Empowering businesses with cutting-edge AI/ML solutions, transforming data into actionable insights, and driving innovation for future success and growth.",
          ],
        },
        h6: "AI/ML Development",
        alt: "AI/ML",
        image: Transparancy,
        URL: "/casestudy/ai-ml-development/",
      },
      {
        number: 2,
        para: {
          li: [
            `Specializing in Extension/Addon Development, we enhance your software with advanced capabilities, optimizing performance and user experience seamlessly.`,
          ],
        },
        h6: "Extension/Addon Development",
        alt: "Extension/Addon Development",
        image: Integrity,
        URL: "/casestudy/extension-addon-development/",
      },
      {
        number: 3,
        para: {
          li: [
            `Enhancing online presence through tailored website solutions, optimizing user experience and driving digital success for our valued clients.`,
          ],
        },
        h6: "Website Development",
        alt: "Website Development",
        image: ContinouesLearning,
        URL: "/casestudy/web-development/",
      },
      {
        number: 4,
        para: {
          li: [
            `Streamlining processes and maximizing efficiency through automated web solutions, empowering businesses to focus on growth and innovation effortlessly.`,
          ],
        },
        h6: "Web Automation",
        alt: "Web Automation",
        image: Web_Auto_Icon,
        URL: "/casestudy/web-automation/",
      },
    ];

export default function Extension() {
  return (
    <>
      <CaseStudyBanner subHeading="Case Studies" heading="Tales of Satisfying Customers" description="Get to know more about the Case Studies." />

      <CaseStudyPageCards data1={data1} subHeading="Unlocking Success: Our Case Studies" heading="Case Studies" description="Experience transformative IT solutions. Witness businesses evolve through innovation and streamlined operations in our compelling case studies." />

      <ServiceIndustries industryData={industryData} subHeading="Industries Transformed By Cloud" heading="Industries" description="Our experts will work with you to understand and solve specific industry problems using SaaS." />

    </>
  );
}
