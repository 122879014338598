import React from "react";
import WhoWeAre from "../../../../images/teams/Who we are.webp";
import Vision from "../../../../images/teams/Vision.webp";
import Mission from "../../../../images/teams/Mission.webp";



const WhoWeAreMissionVission = () => {
  const data1 = [
    {
      number: 1,
      para: {
        li: [
          "Since 2019, Lakebrains has developed deep expertise in development on NLP(Sentiment & Behaviour Analysis), Web Application, Browser Extension and HubSpot CMS. In our short period of spam we have majorly worked SaaS based application in sales, customer care and HR field.",
        ],
      },
      h6: "Who We Are ?",
      alt: "Who We Are ?",
      image:
        WhoWeAre,
    },
    {
      number: 2,
      para: {
        li: [
          `To be a global leader in NLP (AI) development services and provide
          world-class NLP Chatbots and AI Powered Saas tools that transforms
          businesses into next level. We seek to boost organizational
          productivity by providing services that go beyond industry values
          and client expectations.`,
        ],
      },
      h6: "Our Vision",
      alt: "Our Vision",
      image:
        Vision,
    },
    {
      number: 3,
      para: {
        li: [
          `To drive transformation and help organizations thrive in the
          digital age by delivering innovative and high-quality solutions.We
          provide result-driven, objective-oriented, and fully functional
          solutions that add value to your organization.`,
        ],
      },
      h6: "Our Mission",
      alt: "Our Mission",
      image:
        Mission,
    }
  ];
  return (
    <>
      <div className="container-fluid  position-relative ">
        <div className="example-classname">
          <div className="container overflow-hidden" style={{paddingBottom:"55px"}}>
            <div className="homepage-heading">
              <h4>our story</h4>
              <h2>About Lakebrains</h2>
              <p>Lakebrains is a AI-powered innovative product development company. We work as technology partner committed to turn ideas to product on fast track pace by bridge tech skill gaps.</p>
            </div>
            <div className="LakeComm-Whyu-Wrapper position-relative">
              <div className="LakeComm-Whyu-Content mt-5">
                <div className="row justify-content-center">
                  {data1.map((items, key) => (
                    <div
                      id={key}
                      key={key}
                      className="col-md-4 LakeHome-Nthchild col-sm-6 my-2"
                    >
                      <div className="LakeComm-Whyu-colum our-services0 m-1 py-5 px-3">
                        <div className="w-100 h-00">
                          <div className="LakeComm-Whyu-ContentImage m-3 mx-auto ">
                            <img className="w-100" src={items.image} alt={items.alt} loading="lazy"/>
                          </div>
                          <div className="LakeComm-Whyu-ContentReason text-center">
                            <h6>{items.h6}</h6>
                          </div>
                          <div className="LakeComm-Whyu-Contentpara text-center">
                            <ul>
                              {items.para.li.map((xx, index) => (
                                <li key={index}>{xx}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default WhoWeAreMissionVission;
