import React from "react";
import { SuperSEO } from "react-super-seo";

function Privacy() {
  return (
    <div>
      <>
      <SuperSEO
        title="Privacy Policy | Protecting Your Personal Information | Lakebrains Technology"
        description="Read our Privacy Policy to understand how Lakebrains Technology a service provided by LakebrainsTechnologies Inc., safeguards your personal information. Learn about the collection, use, and disclosure of personal data, your rights, and how we ensure data security. Contact us for any questions or concerns"
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: "https://www.youtube.com/watch?v=wZ02FUjCYHw&t=3s&ab_channel=LakeBrainsTechnologies",
            summaryCardImageAlt: "video",
            summaryCardSiteUsername: "justinmahar",
          },
        }}
      />
    
        <div className="mt-5 pt-5 containers d-flex justify-content-center">
          <div className="w-75">
            <h2 style={{    color: "#005082"}}>Privacy Policy</h2>
            <p>Last updated on 13 June, 2023</p>
            <hr />
            <p>
              This Privacy Policy outlines our commitment to safeguarding the
              privacy of the personal information that you provide to us or that
              we collect from you, both offline and online, including through
              the use of our website (https://lakebrains.com/). In this Privacy
              Policy, "we," "us," or "our" refers to Lakebrains Technology a service provided
              by LakebrainsTechnologies Inc.
            </p>
            <p>
              When we collect, store, and utilize your personal information, we
              do so in accordance with the guidelines established by the
              Australian Privacy Act 1988 (Cth) and, where applicable, the
              European Union General Data Protection Regulation (EU) 2016/679
              (GDPR).
            </p>
            <br />
            <h4>
              <b>Personal Information:</b>
            </h4>
            <p>
              The types of personal information or personal data that we may
              collect about you include:
            </p>
            <ul>
              <li>Your name</li>
              <li>Your contact details, including email address</li>
              <li>
                Any other personal information requested by us, provided by you,
                or a third party
              </li>
            </ul>
            <p>
              We may collect this personal information directly from you or from
              third parties.
            </p>
            <br />
            <h4>
              <b>Collection and Use of Personal Information:</b>
            </h4>
            <p>
              We collect, hold, use, and disclose personal information for the
              following purposes:
            </p>
            <ul>
              <li>
                Enabling you to access and use our services, including storing
                your information
              </li>

              <li>
                Contacting and communicating with you - Internal record keeping
                and administrative purposes
              </li>
              <li>
                Compliance with legal obligations and resolution of disputes
              </li>
            </ul>
            <br />
            <h4>
              <b>Disclosure of Personal Information to Third Parties:</b>
            </h4>
            <p>
              We may disclose personal information to the following third
              parties:
            </p>
            <ul>
              <li>
                Third-party service providers for the purpose of enabling them
                to provide their services, such as IT service providers, data
                storage, web hosting and server providers, maintenance or
                problem-solving providers, marketing providers, professional
                advisors, and payment systems operators
              </li>
              <li>
                Courts, tribunals, regulatory authorities, and law enforcement
                officers as required by law, in connection with actual or
                prospective legal proceedings, or to establish, exercise, or
                defend our legal rights
              </li>
              <li>
                Third parties to collect and process data, such as Google
                Analytics or other relevant businesses. This may include parties
                that store data outside of Australia, including in the U.S.A.
              </li>
            </ul>
            <p>
              When disclosing your personal information to third parties,
              including data processors, we will ensure that they handle your
              personal information in accordance with this Privacy Policy. The
              third parties will only process your personal information as
              instructed by us, and we require them to comply with the privacy
              principles set out in the GDPR or other mechanisms defined by
              applicable EU and Swiss data protection laws for the transfer and
              processing of personal information. The term "processing" refers
              to any operation or set of operations performed on personal
              information, whether automated or not, including collection,
              recording, organizing, structuring, storage, adaptation or
              alteration, retrieval, consultation, use, disclosure by
              transmission, dissemination, or any other form of making the
              information available.
            </p>
            <p>
              Please note that the following third parties are involved in
              processing your personal information: Google API Services, Google
              Sign-In, Google Cloud Platform, Google Analytics.
            </p>
            <p>
              By providing us with your personal information, you consent to the
              disclosure of your personal information to third parties outside
              of Australia. If you are a citizen of the European Union (EU), you
              also consent to the disclosure of your personal information to
              third parties outside of the EU. Please be aware that if the
              disclosure of your personal information is solely subject to
              Australian privacy laws (not the GDPR), we are not required to
              ensure that those third parties comply with Australian privacy
              laws.
            </p>
            <br />
            <h4>
              <b>Your Rights and Controlling Your Personal Information:</b>
            </h4>
            <p>
              If we receive personal information about you from a third party,
              we will protect it as outlined in this Privacy Policy. If you are
              a third party providing personal information about someone else,
              you represent and warrant that you have obtained the necessary
              consent from that person to provide their personal information to
              us.
            </p>
            <p>
              If you believe that we have violated the Australian Privacy
              Principles or any articles of the GDPR and wish to. If you believe
              that we have violated the Australian Privacy Principles or any
              articles of the GDPR and wish to make a complaint, please contact
              us using the contact information provided at the end of this
              Privacy Policy. We will promptly investigate your complaint and
              provide you with a response.
            </p>
            <p>
              You have the following rights with respect to your personal
              information:
            </p>
            <ol>
              <li>
                Right to Access: You have the right to request access to the
                personal information we hold about you and to receive a copy of
                that information.
              </li>
              <li>
                Right to Rectification: You have the right to request the
                correction of any inaccurate or incomplete personal information
                we hold about you.
              </li>
              <li>
                Right to Erasure: You have the right to request the deletion of
                your personal information in certain circumstances, such as when
                it is no longer necessary for the purposes for which it was
                collected or if you have withdrawn your consent.
              </li>
              <li>
                Right to Restrict Processing: You have the right to request the
                restriction of processing of your personal information in
                certain circumstances, such as when you contest the accuracy of
                the information or when the processing is unlawful.
              </li>
              <li>
                Right to Data Portability: You have the right to receive your
                personal information in a structured, commonly used, and
                machine-readable format and to transmit that information to
                another data controller.
              </li>
              <li>
                Right to Object: You have the right to object to the processing
                of your personal information in certain circumstances, such as
                when the processing is based on legitimate interests or for
                direct marketing purposes.
              </li>
              <p>
                To exercise any of these rights or if you have any questions or
                concerns about our Privacy Policy or practices, please contact
                us using the contact information provided below.
              </p>
            </ol>
            <br />
            <h4>
              <b>Retention of Personal Information:</b>
            </h4>
            <p>
              We will retain your personal information for as long as necessary
              to fulfill the purposes for which it was collected, including for
              the purposes of satisfying any legal, accounting, or reporting
              requirements. To determine the appropriate retention period for
              personal information, we consider the amount, nature, and
              sensitivity of the information, the potential risk of harm from
              unauthorized use or disclosure, the purposes for which we process
              the information, and the applicable legal requirements.
            </p>
            <br />
            <h4>
              <b>Security of Personal Information:</b>
            </h4>
            <p>
              We take reasonable steps to protect your personal information from
              unauthorized access, use, disclosure, alteration, or destruction.
              We implement appropriate technical and organizational measures to
              ensure a level of security appropriate to the risks associated
              with the processing of personal information.
            </p>
            <br />
            <h4>
              <b>Updates to this Privacy Policy:</b>
            </h4>
            <p>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal requirements. Any changes we
              make will be posted on this page, and the updated Privacy Policy
              will take effect immediately upon posting.
            </p>
            <br />
            <h4>
              <b>Contact Us:</b>
            </h4>
            <p>
              If you have any questions, comments, or concerns about this
              Privacy Policy or our privacy practices, please contact us at:
            </p>
            <p>
              Lakebrains Technologies Inc. Address: 612, 6th floor, city center,
              Ashok nagar, University road, Udaipur, Rajasthan.
            </p>
            <p> Email: support@lakebrains.in</p>
            <p> Phone: 9351742998</p>
            <p> We will respond to your inquiry as soon as possible.</p>
          </div>
        </div>
      
      </>
    </div>
  );
}

export default Privacy;
