// Idealeads Images
import IdealeadsBanner from "../../images/Products/Idealeads/landing3-slider-pic2.webp";
import IdealeadsBenefits from "../../images/Products/Idealeads/Benefits/IdealeadsBenefits.webp";
import IdealeadsIcon1 from "../../images/Products/Idealeads/OurTools/idealeads-icon1.webp";
import IdealeadsIcon2 from "../../images/Products/Idealeads/OurTools/idealeads-icon2.webp";
import IdealeadsIcon3 from "../../images/Products/Idealeads/OurTools/idealeads-icon3.webp";
import Benifits1 from "../../images/Products/Idealeads/Benefits/2.webp";
import Benifits2 from "../../images/Products/Idealeads/Benefits/1.webp";
import Benifits3 from "../../images/Products/Idealeads/Benefits/3.webp";
import Benifits4 from "../../images/Products/Idealeads/Benefits/4.webp";


const data = [
  {
    "name": "Idealeads 1",
    "localURL": "idealeads1",
    "type": "NLP",
    "section_1": {
      "title": "Quality B2B Lead Database!",
      "subTitle": "Quality contacts are greatest asset for any business.Get quality B2B lead database which includes CXO, Owner, Director, Partner, VP & Managers of 150+ sectors.",
      "image": IdealeadsBanner,
      "button": {
        "text": "Get Free Leads"
      }
    },
    "section_2": {
      "title": "Quality B2B Leads",
      "subTitle": "Close More Deals with Your Ideal Customer",
      "count": [
        {
          "image": IdealeadsIcon1,
          "endNo": 5,
          "text": "Leads",
          "alt": "Leads",
          "CountIcon": "M +"
        },
        {
          "image": IdealeadsIcon2,
          "endNo": "150",
          "text": "sectors",
          "alt": "sectors",
          "CountIcon": "+"
        },
        {
          "image": IdealeadsIcon3,
          "endNo": "75",
          "text": "cities",
          "alt": "cities",
          "CountIcon": "+"
        }
      ]
    },
    "section_3": {
      "title": "Idealeads B2B Lead Generation Tools",
      "subTitle": "Our Aim to consistently deliver engaging leads. We have worked with a great number of B2B companies and helped them to convert the right prospects into valuable clients.",
      "list": [
        {
          "heading": "B2B Lead Database",
          "para": "Get quality leads of top decision-makers of companies for 150+ sectors globally.",
          "video": "https://www.youtube.com/embed/nP3N-5Hky_I"
        },
        // {
        //   "heading": "Email Finder",
        //   "para": "Get the verified professional email addresses of a person from any company.",
        //   "video": "https://www.youtube.com/embed/lXX5K22mBJc"
        // },
        // {
        //   "heading": "Email Verification",
        //   "para": "Get the verified professional email addresses of a person from any company.",
        //   "video": "https://www.youtube.com/embed/2J7ymwO_T6k"
        // },
        // {
        //   "heading": "Domain Finder & Check",
        //   "para": "Generate Your Lead & Lifetime Access Domain Finder & Check Automatically find the websites domains for any company or brands. Discover everything you need to know about domains.",
        //   "video": "https://www.youtube.com/embed/eDYXqO34rZQ"
        // }
      ]
    },
    "section_4": {
      "title": "Benefits",
      "subTitle": "Why choosing Idealeads could be best decision for your business/Astounding features that Idealeads provide you.",
      "image": IdealeadsBenefits,
      "steps": [
        {
          "para": "Use our B2B lead generation tools for free.",
          "img": Benifits1,
        },
        {
          "para": "Lifetime Access to Quality Lead Database",
          "img": Benifits2,
        },
        {
          "para": "Save time, Focus on your core operation",
          "img": Benifits3,
        },
        {
          "para": "Save 90% of your Sales Budget",
          "img": Benifits4,
        }
      ]
    }
  },
  // {
  //   "name": "Idealeads 2",
  //   "localURL": "idealeads2",
  //   "type": "NLP",
  //   "section_1": {
  //     "title": "Quality B2B Lead Database!",
  //     "subTitle": "Quality contacts are greatest asset for any business.Get quality B2B lead database which includes CXO, Owner, Director, Partner, VP & Managers of 150+ sectors.",
  //     "image": IdealeadsBanner,
  //     "button": {
  //       "text": "Get Free Leads"
  //     }
  //   },
  //   "section_2": {
  //     "title": "Quality B2B Leads",
  //     "subTitle": "Close More Deals with Your Ideal Customer",
  //     "count": [
  //       {
  //         "image": IdealeadsIcon1,
  //         "endNo": 5,
  //         "text": "Leads",
  //         "alt": "Leads",
  //         "CountIcon": "M +"
  //       },
  //       {
  //         "image": IdealeadsIcon2,
  //         "endNo": "150",
  //         "text": "sectors",
  //         "alt": "sectors",
  //         "CountIcon": "+"
  //       },
  //       {
  //         "image": IdealeadsIcon3,
  //         "endNo": "75",
  //         "text": "cities",
  //         "alt": "cities",
  //         "CountIcon": "+"
  //       }
  //     ]
  //   },
  //   "section_3": {
  //     "title": "Idealeads B2B Lead Generation Tools",
  //     "subTitle": "Our Aim to consistently deliver engaging leads. We have worked with a great number of B2B companies and helped them to convert the right prospects into valuable clients.",
  //     "list": [
  //       {
  //         "heading": "B2B Lead Database",
  //         "para": "Get quality leads of top decision-makers of companies for 150+ sectors globally.",
  //         "video": "https://www.youtube.com/embed/nP3N-5Hky_I"
  //       },
  //       {
  //         "heading": "Email Finder",
  //         "para": "Get the verified professional email addresses of a person from any company.",
  //         "video": "https://www.youtube.com/embed/lXX5K22mBJc"
  //       },
  //       {
  //         "heading": "Email Verification",
  //         "para": "Get the verified professional email addresses of a person from any company.",
  //         "video": "https://www.youtube.com/embed/2J7ymwO_T6k"
  //       },
  //       {
  //         "heading": "Domain Finder & Check",
  //         "para": "Generate Your Lead & Lifetime Access Domain Finder & Check Automatically find the websites domains for any company or brands. Discover everything you need to know about domains.",
  //         "video": "https://www.youtube.com/embed/eDYXqO34rZQ"
  //       }
  //     ]
  //   },
  //   "section_4": {
  //     "title": "Benefits",
  //     "subTitle": "Why choosing Idealeads could be best decision for your business/Astounding features that Idealeads provide you.",
  //     "image": IdealeadsBenefits,
  //     "steps": [
  //       {
  //         "para": "Use our B2B lead generation tools for free.",
  //         "img": Benifits1,
  //       },
  //       {
  //         "para": "Lifetime Access to Quality Lead Database",
  //         "img": Benifits2,
  //       },
  //       {
  //         "para": "Save time, Focus on your core operation",
  //         "img": Benifits3,
  //       },
  //       {
  //         "para": "Save 90% of your Sales Budget",
  //         "img": Benifits4,
  //       }
  //     ]
  //   }
  // },
  // {
  //   "name": "Idealeads 3",
  //   "localURL": "idealeads3",
  //   "type": "Web Application",
  //   "section_1": {
  //     "title": "Quality B2B Lead Database!",
  //     "subTitle": "Quality contacts are greatest asset for any business.Get quality B2B lead database which includes CXO, Owner, Director, Partner, VP & Managers of 150+ sectors.",
  //     "image": IdealeadsBanner,
  //     "button": {
  //       "text": "Get Free Leads"
  //     }
  //   },
  //   "section_2": {
  //     "title": "Quality B2B Leads",
  //     "subTitle": "Close More Deals with Your Ideal Customer",
  //     "count": [
  //       {
  //         "image": IdealeadsIcon1,
  //         "endNo": 5,
  //         "text": "Leads",
  //         "alt": "Leads",
  //         "CountIcon": "M +"
  //       },
  //       {
  //         "image": IdealeadsIcon2,
  //         "endNo": "150",
  //         "text": "sectors",
  //         "alt": "sectors",
  //         "CountIcon": "+"
  //       },
  //       {
  //         "image": IdealeadsIcon3,
  //         "endNo": "75",
  //         "text": "cities",
  //         "alt": "cities",
  //         "CountIcon": "+"
  //       }
  //     ]
  //   },
  //   "section_3": {
  //     "title": "Idealeads B2B Lead Generation Tools",
  //     "subTitle": "Our Aim to consistently deliver engaging leads. We have worked with a great number of B2B companies and helped them to convert the right prospects into valuable clients.",
  //     "list": [
  //       {
  //         "heading": "B2B Lead Database",
  //         "para": "Get quality leads of top decision-makers of companies for 150+ sectors globally.",
  //         "video": "https://www.youtube.com/embed/nP3N-5Hky_I"
  //       },
  //       {
  //         "heading": "Email Finder",
  //         "para": "Get the verified professional email addresses of a person from any company.",
  //         "video": "https://www.youtube.com/embed/lXX5K22mBJc"
  //       },
  //       {
  //         "heading": "Email Verification",
  //         "para": "Get the verified professional email addresses of a person from any company.",
  //         "video": "https://www.youtube.com/embed/2J7ymwO_T6k"
  //       },
  //       {
  //         "heading": "Domain Finder & Check",
  //         "para": "Generate Your Lead & Lifetime Access Domain Finder & Check Automatically find the websites domains for any company or brands. Discover everything you need to know about domains.",
  //         "video": "https://www.youtube.com/embed/eDYXqO34rZQ"
  //       }
  //     ]
  //   },
  //   "section_4": {
  //     "title": "Benefits",
  //     "subTitle": "Why choosing Idealeads could be best decision for your business/Astounding features that Idealeads provide you.",
  //     "image": IdealeadsBenefits,
  //     "steps": [
  //       {
  //         "para": "Use our B2B lead generation tools for free.",
  //         "img": Benifits1,
  //       },
  //       {
  //         "para": "Lifetime Access to Quality Lead Database",
  //         "img": Benifits2,
  //       },
  //       {
  //         "para": "Save time, Focus on your core operation",
  //         "img": Benifits3,
  //       },
  //       {
  //         "para": "Save 90% of your Sales Budget",
  //         "img": Benifits4,
  //       }
  //     ]
  //   }
  // },
  // {
  //   "name": "Idealeads 4",
  //   "localURL": "idealeads4",
  //   "type": "Browser Extension",
  //   "section_1": {
  //     "title": "Quality B2B Lead Database!",
  //     "subTitle": "Quality contacts are greatest asset for any business.Get quality B2B lead database which includes CXO, Owner, Director, Partner, VP & Managers of 150+ sectors.",
  //     "image": IdealeadsBanner,
  //     "button": {
  //       "text": "Get Free Leads"
  //     }
  //   },
  //   "section_2": {
  //     "title": "Quality B2B Leads",
  //     "subTitle": "Close More Deals with Your Ideal Customer",
  //     "count": [
  //       {
  //         "image": IdealeadsIcon1,
  //         "endNo": 5,
  //         "text": "Leads",
  //         "alt": "Leads",
  //         "CountIcon": "M +"
  //       },
  //       {
  //         "image": IdealeadsIcon2,
  //         "endNo": "150",
  //         "text": "sectors",
  //         "alt": "sectors",
  //         "CountIcon": "+"
  //       },
  //       {
  //         "image": IdealeadsIcon3,
  //         "endNo": "75",
  //         "text": "cities",
  //         "alt": "cities",
  //         "CountIcon": "+"
  //       }
  //     ]
  //   },
  //   "section_3": {
  //     "title": "Idealeads B2B Lead Generation Tools",
  //     "subTitle": "Our Aim to consistently deliver engaging leads. We have worked with a great number of B2B companies and helped them to convert the right prospects into valuable clients.",
  //     "list": [
  //       {
  //         "heading": "B2B Lead Database",
  //         "para": "Get quality leads of top decision-makers of companies for 150+ sectors globally.",
  //         "video": "https://www.youtube.com/embed/nP3N-5Hky_I"
  //       },
  //       {
  //         "heading": "Email Finder",
  //         "para": "Get the verified professional email addresses of a person from any company.",
  //         "video": "https://www.youtube.com/embed/lXX5K22mBJc"
  //       },
  //       {
  //         "heading": "Email Verification",
  //         "para": "Get the verified professional email addresses of a person from any company.",
  //         "video": "https://www.youtube.com/embed/2J7ymwO_T6k"
  //       },
  //       {
  //         "heading": "Domain Finder & Check",
  //         "para": "Generate Your Lead & Lifetime Access Domain Finder & Check Automatically find the websites domains for any company or brands. Discover everything you need to know about domains.",
  //         "video": "https://www.youtube.com/embed/eDYXqO34rZQ"
  //       }
  //     ]
  //   },
  //   "section_4": {
  //     "title": "Benefits",
  //     "subTitle": "Why choosing Idealeads could be best decision for your business/Astounding features that Idealeads provide you.",
  //     "image": IdealeadsBenefits,
  //     "steps": [
  //       {
  //         "para": "Use our B2B lead generation tools for free.",
  //         "img": Benifits1,
  //       },
  //       {
  //         "para": "Lifetime Access to Quality Lead Database",
  //         "img": Benifits2,
  //       },
  //       {
  //         "para": "Save time, Focus on your core operation",
  //         "img": Benifits3,
  //       },
  //       {
  //         "para": "Save 90% of your Sales Budget",
  //         "img": Benifits4,
  //       }
  //     ]
  //   }
  // },
  // {
  //   "name": "Idealeads 5",
  //   "localURL": "idealeads4",
  //   "type": "Browser Extension",
  //   "section_1": {
  //     "title": "Quality B2B Lead Database!",
  //     "subTitle": "Quality contacts are greatest asset for any business.Get quality B2B lead database which includes CXO, Owner, Director, Partner, VP & Managers of 150+ sectors.",
  //     "image": IdealeadsBanner,
  //     "button": {
  //       "text": "Get Free Leads"
  //     }
  //   },
  //   "section_2": {
  //     "title": "Quality B2B Leads",
  //     "subTitle": "Close More Deals with Your Ideal Customer",
  //     "count": [
  //       {
  //         "image": IdealeadsIcon1,
  //         "endNo": 5,
  //         "text": "Leads",
  //         "alt": "Leads",
  //         "CountIcon": "M +"
  //       },
  //       {
  //         "image": IdealeadsIcon2,
  //         "endNo": "150",
  //         "text": "sectors",
  //         "alt": "sectors",
  //         "CountIcon": "+"
  //       },
  //       {
  //         "image": IdealeadsIcon3,
  //         "endNo": "75",
  //         "text": "cities",
  //         "alt": "cities",
  //         "CountIcon": "+"
  //       }
  //     ]
  //   },
  //   "section_3": {
  //     "title": "Idealeads B2B Lead Generation Tools",
  //     "subTitle": "Our Aim to consistently deliver engaging leads. We have worked with a great number of B2B companies and helped them to convert the right prospects into valuable clients.",
  //     "list": [
  //       {
  //         "heading": "B2B Lead Database",
  //         "para": "Get quality leads of top decision-makers of companies for 150+ sectors globally.",
  //         "video": "https://www.youtube.com/embed/nP3N-5Hky_I"
  //       },
  //       {
  //         "heading": "Email Finder",
  //         "para": "Get the verified professional email addresses of a person from any company.",
  //         "video": "https://www.youtube.com/embed/lXX5K22mBJc"
  //       },
  //       {
  //         "heading": "Email Verification",
  //         "para": "Get the verified professional email addresses of a person from any company.",
  //         "video": "https://www.youtube.com/embed/2J7ymwO_T6k"
  //       },
  //       {
  //         "heading": "Domain Finder & Check",
  //         "para": "Generate Your Lead & Lifetime Access Domain Finder & Check Automatically find the websites domains for any company or brands. Discover everything you need to know about domains.",
  //         "video": "https://www.youtube.com/embed/eDYXqO34rZQ"
  //       }
  //     ]
  //   },
  //   "section_4": {
  //     "title": "Benefits",
  //     "subTitle": "Why choosing Idealeads could be best decision for your business/Astounding features that Idealeads provide you.",
  //     "image": IdealeadsBenefits,
  //     "steps": [
  //       {
  //         "para": "Use our B2B lead generation tools for free.",
  //         "img": Benifits1,
  //       },
  //       {
  //         "para": "Lifetime Access to Quality Lead Database",
  //         "img": Benifits2,
  //       },
  //       {
  //         "para": "Save time, Focus on your core operation",
  //         "img": Benifits3,
  //       },
  //       {
  //         "para": "Save 90% of your Sales Budget",
  //         "img": Benifits4,
  //       }
  //     ]
  //   }
  // },
  // {
  //   "name": "Idealeads 6",
  //   "localURL": "idealeads4",
  //   "type": "Browser Extension",
  //   "section_1": {
  //     "title": "Quality B2B Lead Database!",
  //     "subTitle": "Quality contacts are greatest asset for any business.Get quality B2B lead database which includes CXO, Owner, Director, Partner, VP & Managers of 150+ sectors.",
  //     "image": IdealeadsBanner,
  //     "button": {
  //       "text": "Get Free Leads"
  //     }
  //   },
  //   "section_2": {
  //     "title": "Quality B2B Leads",
  //     "subTitle": "Close More Deals with Your Ideal Customer",
  //     "count": [
  //       {
  //         "image": IdealeadsIcon1,
  //         "endNo": 5,
  //         "text": "Leads",
  //         "alt": "Leads",
  //         "CountIcon": "M +"
  //       },
  //       {
  //         "image": IdealeadsIcon2,
  //         "endNo": "150",
  //         "text": "sectors",
  //         "alt": "sectors",
  //         "CountIcon": "+"
  //       },
  //       {
  //         "image": IdealeadsIcon3,
  //         "endNo": "75",
  //         "text": "cities",
  //         "alt": "cities",
  //         "CountIcon": "+"
  //       }
  //     ]
  //   },
  //   "section_3": {
  //     "title": "Idealeads B2B Lead Generation Tools",
  //     "subTitle": "Our Aim to consistently deliver engaging leads. We have worked with a great number of B2B companies and helped them to convert the right prospects into valuable clients.",
  //     "list": [
  //       {
  //         "heading": "B2B Lead Database",
  //         "para": "Get quality leads of top decision-makers of companies for 150+ sectors globally.",
  //         "video": "https://www.youtube.com/embed/nP3N-5Hky_I"
  //       },
  //       {
  //         "heading": "Email Finder",
  //         "para": "Get the verified professional email addresses of a person from any company.",
  //         "video": "https://www.youtube.com/embed/lXX5K22mBJc"
  //       },
  //       {
  //         "heading": "Email Verification",
  //         "para": "Get the verified professional email addresses of a person from any company.",
  //         "video": "https://www.youtube.com/embed/2J7ymwO_T6k"
  //       },
  //       {
  //         "heading": "Domain Finder & Check",
  //         "para": "Generate Your Lead & Lifetime Access Domain Finder & Check Automatically find the websites domains for any company or brands. Discover everything you need to know about domains.",
  //         "video": "https://www.youtube.com/embed/eDYXqO34rZQ"
  //       }
  //     ]
  //   },
  //   "section_4": {
  //     "title": "Benefits",
  //     "subTitle": "Why choosing Idealeads could be best decision for your business/Astounding features that Idealeads provide you.",
  //     "image": IdealeadsBenefits,
  //     "steps": [
  //       {
  //         "para": "Use our B2B lead generation tools for free.",
  //         "img": Benifits1,
  //       },
  //       {
  //         "para": "Lifetime Access to Quality Lead Database",
  //         "img": Benifits2,
  //       },
  //       {
  //         "para": "Save time, Focus on your core operation",
  //         "img": Benifits3,
  //       },
  //       {
  //         "para": "Save 90% of your Sales Budget",
  //         "img": Benifits4,
  //       }
  //     ]
  //   }
  // }
]


export default data;