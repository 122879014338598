import React from "react";

import data from "./ChatGPT_QAData";
import Bannnerimage from "../../../../images/logo/logo_lakebrains.webp";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const ChatGPT_QA = () => {
    return (
        <>
            <SuperSEO
                title="ChatGPT - Lakebrains"
                description="Our AI-driven product simplifies the analysis of PDF documents by generating comprehensive summaries and answering user queries related to the content."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "ChatGPT",
                        summaryCardSiteUsername: "ChatGPT",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default ChatGPT_QA;
