import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/karibu.png";

const data = [
  {
    slugurl: "/KaribuWeb",
    data: [
      {
        Banner: [
          {
            sector: "Wellness/technology",
            headingtext: "Karibu",
            headingpara:
              "Karibu is an innovative wellness platform offering relaxation and rejuvenation services for mental and physical well-being. Based in Slovenia, it enhances user experience through a user-friendly design and a comprehensive suite of services.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Revolutionizing Online Wellness and Relaxation for Mind and Body Well-Being",
        casestudy: {
          Problem:
            `Develop a professional and user-friendly wellness platform using WordPress with a premium theme. Ensure the site is optimized for speed, mobile responsiveness, and SEO, while maintaining intuitive navigation and engaging content.`,
          challenge: [
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Elementor lacks the required functionality." },
                { First: "Optimizing site speed for improved performance and user retention." },
                { First: "Developing engaging content that resonates with the target audience." },
                { First: "Integrating multiple WordPress plugins may cause functionality and performance issues." },
              ],
            },
            {
              heading: "The Solution:-",
              challengeli: [
                { First: "Utilize Child Themes: Customize safely using PHP without altering the parent theme's functionality." },
                { First: "Optimize Performance: Streamline themes and plugins to enhance website speed and efficiency." },
                { First: "Implement Responsive Design: Ensure design adapts using CSS for optimal user experience on all devices." },
                { First: "Engage in SEO Practices: Implement SEO-friendly CSS strategies to improve search engine visibility." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [
                { First: `Karibu tailored its platform to align with user expectations, resulting in a professional and user-friendly wellness website. The responsive and visually appealing design showcases various wellness services, enhancing user engagement and positioning Karibu as a leader in the wellness industry.` },
              ],
            },
          ],
        },

        companyinfo: {
          logourl: "karibu",
          about: "About ",
          aboutpara:
            "Karibu offers a sanctuary for relaxation through expert massage therapies. Our mission is to rejuvenate the body and mind, promoting well-being and tranquility for individuals seeking an escape from daily stress.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Karibu",
            },
            {
              first: "INDUSTRY:",
              secound: "Wellness/Technology",
            },
            {
              first: "LOCATION:",
              secound: "Slovenia",
            },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
