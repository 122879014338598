import React from 'react'
import HireDeveloper from './HireDeveloper'

export default function HireNode() {
    const data = 
        {
        
            bannersubtitle :"Hire Node JS Developers",
            bannertitle :"Need to Hire Node JS Developers for your next project",
            bannerdescription: "Lakebrains provides the perfect solution for enterprises and startups looking to hire Node JS developers. All of our offshore Node JS developers are skilled in the most current technologies and delivery methodologies, so you don't have to worry about your project getting put on hold with these rising costs. Hire a remote Node JS developer today, and start expecting complex solutions at an affordable price.",
        
    
            modelsubtitle:"Engagement Models",
            modeltitle:"Our Models to Hire Node JS Developers",
            modeldescription:"Get an advantage of different hiring models to collaborate with experts in NodeJS. Different hiring models include contracting, consulting, and permanent roles. You can shape your applications however you see fit with expert guidance.",
        
    
            whatwedo:{
                subtitle:"What We Do ?",
                title:"Hire Node JS Developer for End-to-End Services",
                description:"Working with us will allow you to stay focused on your goals and meet standards without having to worry about the tech side of things. Our NodeJS developers are professional and know JavaScript inside out, making them the perfect choice for your business. We offer services that are tailored to your needs, including full-stack development and round-the-clock support. With our high-performing results-oriented NodeJS development services, your website is optimized for mobile and productivity so you don't have to worry about it.",
            whatwedoinnersection:[
                {
                    title: "Custom Node JS Development",
                    description: "When you hire Node JS developer from Lakebrains, you get a team dedicated to building custom Node-based websites that are recognized for security, scalability, and functionality.",
                },
                {
                    title: "API & Web Service Integration",
                    description: "Hire Node JS developers from Lakebrains, holding profound skills and experience in API development as well as web service integration.",
                },
                {
                    title: "Node JS Migration Services",
                    description: "Our experienced NodeJS development teams help you migrate applications to NodeJS easily and smoothly based on your business requirements.",
                },
                {
                    title: "NodeJS Frontend Development",
                    description: "Front-end web development using NodeJS to create an interactive interface that reduces code complexity.",
                },
                {
                    title: "NodeJS Plugin Development",
                    description: "e professional developers to build complete web applications by developing custom Node.JS plugins.",
                },
                {
                    title: "Support & Maintenance Services",
                    description: "Your website is supported and maintained completely by LakeBrains Node JS developers, who also keep it up to date with the most recent ",
                }
            ]   
            },
            whywearedifferent:{
                subtitle:"Why we are different",
                title:"Key Differentiators of Hiring NodeJS Developers from Lakebrains",
                description:"Work With Node JS Developers Through A Variety Of Hiring Models To Build And Develop Web Applications That Make A Difference."
            },
        
        
            whyus:{
                subtitle:"Why us",
                title:"Why Hire Node JS Developer From Lakebrains",
                description:"Our expert Node JS developers are premier in creating high-octane web applications. We offer our clients the freedom to hire Node JS developers smoothly, which will result in their business growth. Whether you need cross-platform-friendly web applications or single-page web app development, Lakebrains can help you fulfill your needs. Here are some reasons why you should hire Node JS Develoepor Fromn Lakebrains"
            },
        
        
            ourexpertise:{
                description:"Lakebrains has extensive expertise on the top Node component libraries worth trying. Hire Node JS developer from us and build future-ready web apps with the libraries and features you need."
            }
        }

  return (
    <>
    <HireDeveloper
    data = {data}/>
    </>
  )
}
