import React from "react";
import "./why.css";
// import WhyImage from '../Assets/WWimage.svg'

function why() {
  return (
    <div className="container">
      <div className="why-main">
        <div className="why-texts">
          {/* <h2 className='why-h1'>
            Why Us?
        </h2> */}
          <h1 className="why-h1">Types of Chrome Extensions Based Use Cases</h1>
          
            <p className="why-p">
            Boost productivity and achieve seamless website management with
            these AI-powered solutions.
            </p>
      
        </div>
        <br />
        <div className="why-texts2">
          <h1 className="why-h2">AI-Based Extensions</h1>
          <p className="why-p2">
            AI-based Chrome Add ons leverages the power of artificial
            intelligence algorithms to provide users with enhanced productivity
            and automation. These Chrome plugins are designed to make various
            tasks more efficient and user-friendly. Some popular examples
            include
          </p>
        </div>
        <div className="boxes">
          <div className="why-R1">
            <div className="box-1">
              <h1 className="box-h">Content Generation</h1>
              <p className="box-p">
                AI-powered content generation tools like Grammarly and
                Writesonic make writing tasks easier. Grammarly checks for
                grammar errors, and Writesonic creates blog posts based on
                provided topics and keywords. This boosts productivity, allowing
                writers to focus on their creativity while AI takes care of the
                more technical aspects of writing.{" "}
              </p>
            </div>
            <div className="box-1">
              <h1 className="box-h">Career & Education</h1>
              <p className="box-p">
                AI-powered Career & Education extensions provide individualized
                assistance. For example, Career Path Finder suggests suitable
                careers based on skills and interests, while Edu Boost AI
                recommends customized educational materials to improve skills.
                These extensions empower people, making it easier to make
                informed and effective career decisions and learning paths for a
                successful future.{" "}
              </p>
            </div>
          </div>
          <div className="why-R2">
            <div className="box-1">
              <h1 className="box-h">Marketing & Social Media Management</h1>
              <p className="box-p">
                {" "}
                 AI-Powered Marketing & Social Media Management extensions
                revolutionize digital strategies, offering seamless automation
                and optimization. For instance, the Social Media Scheduler
                automates posts and maximizes reach by optimizing timings. These
                extensions simplify marketing efforts, enhance social media
                presence, and drive business growth.
              </p>
            </div>
            <div className="box-1">
              <h1 className="box-h">Productivity Extension</h1>
              <p className="box-p">
                Boost Efficiency with AI-Powered Productivity Extensions:
                Meeting Assistants, Auto Email Reply, and G-Note. These tools
                serve as virtual meeting assistants, streamlining scheduling,
                reminders, and meeting note summaries. Boost productivity with
                AI-powered Chrome plugins for personalized email responses and
                G-Note for seamless note-taking.{" "}
              </p>
            </div>
          </div>
        </div>
        
        <div className="web">
          <div className="why-texts3">
            <h1 className="why-h2">Website Analyzer</h1>
            <p className="why-p3">
              Website analyzer Extension focus on inspecting and analyzing
              websites for specific elements or issues. Some examples are below.
            </p>
          </div>
          <div className="boxes-2">
            <div className="why-R3">
              <div className="box-1">
                <h1 className="box-h">CSS Analyzer</h1>
                <p className="box-p">
                  This type of extension assists web developers in identifying
                  and debugging CSS-related problems on web pages. It can help
                  ensure that a website's visual presentation is consistent and
                  error-free.
                </p>
              </div>
              <div className="box-1">
                <h1 className="box-h">SEO Analysis</h1>
                <p className="box-p">
                  Another website analyzer extension, optimizes web content for
                  search engines, enhancing online visibility and organic
                  traffic.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="web-2">
          <h1 className="why-h2">Web Data Extractors</h1>
          <p className="why-p3">
            Web data extractor plugins streamline data collection. Email
            Providers scan web pages for email addresses, aiding lead
            generation. Prospect extensions gather customer data, enhancing
            marketing and sales strategies. Empower your business with valuable
            insights for success in the digital landscape.
          </p>
        </div>
        
      </div>
    </div>
  );
}

export default why;
