import React from "react";
import OurCommitmentThink from "../../../../images/NewHomePage/OurCommitment-Think.svg";
import OurCommitmentMake from "../../../../images/NewHomePage/OurCommitment-Make.svg";
import OurCommitmentCheck from "../../../../images/NewHomePage/OurCommitment-Check.svg";
import "./OurCommitment.css";

const OurCommitment = () => {
  const data1 = [
    {
      id: 1,
      para: [
        "Opportunity hypothesis",
        "Customer/Market Research",
        "Business Model",
        "JTBD Analysis",
        "Behaviour Model"
      ],
      title: "Think",
      alt: "Our Think",
      image: OurCommitmentThink
    },
    {
      id: 2,
      para: [
        "Personas",
        "Experiment",
        "Wireframe",
        "Prototype",
        "Product"
      ],
      title: "Make",
      alt: "Our Make",
      image: OurCommitmentMake
    },
    {
      id: 3,
      para: [
        "UX testing",
        "A/B testing",
        "Product Telemetrics"
      ],
      title: "Check",
      alt: "Our Check",
      image: OurCommitmentCheck
    }
  ];

  return (
    <div className="OurCommitment-main">
      <div className="container-fluid">
        <div className="OurCommitment-container container-lg overflow-hidden">
          <div className="OurCommitment-text d-flex text-center flex-column align-items-center">
            <h4>Your partner in success</h4>
            <h2>Your Growth, Our Commitment</h2>
            <p>We understand the importance of Iterative Product Development and challenges of Product-Market Fit.</p>
          </div>
          <div className="OurCommitment-compactness">
            <div className="OurCommitment-parent row justify-content-between">
              {data1.map((item) => (
                <div
                  key={item.id}
                  className="col-md-4 col-sm-6"
                  style={{ padding: "0 40px" }}
                >
                  <div className="OurCommitment-Nthchild">
                    <div className="w-100 h-00">
                      <div className="OurCommitment-img mx-auto">
                        <img src={item.image} alt={item.alt} loading="lazy" />
                      </div>
                      <div className="OurCommitment-heading LakeComm-Whyu-ContentReason text-center">
                        <h6>{item.title}</h6>
                      </div>
                      <div className="OurCommitment-para LakeComm-Whyu-Contentpara text-center">
                        {item.para.map((text, index) => (
                          <p key={index}>{text}</p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="LakeHome-Whyus-background1 position-absolute"></div>
            <div className="LakeHome-Whyus-background2 position-absolute"></div>
          </div>
        </div>
        <div className="LakeHome-Whyus-background3 position-absolute"></div>
      </div>
    </div>
  );
};

export default OurCommitment;
