import React from "react";


const Banner = () => {
  return (
    <>
      <div className='components-bg justify-content-center align-items-center d-flex'>
        <div className="header container">
          <div className="row  wi-header">
            <div
              data-aos="fade-up"
              data-aos-duration="1500"
              className="col-3 d-flex justify-content-center align-items-center"
            >
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1500"
              className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center flex-column"
            >
              <div className="mb-5">
              </div>
              <div className="banner-content mt-lg-5">
                <h2 className="underline">About Us</h2>
                <h1>Lakebrains Technologies</h1>

                <p className="header-text">
                  We have all the capabilities to build AI-custom software applications that help streamline the business process and drive revenue.
                </p>
              </div>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1500"
              className="col-3 d-flex justify-content-center align-items-center"
            >
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
