import datalix from "../../../../images/casestudy/datalixLogo2.png";

const data = [
  {
    slugurl: "/datalix",
    data: [
      {
        Banner: [
          {
            sector:"Productivity/ Technology",
            headingtext: "Datalix",
            headingpara:
              "Datalix Chrome Extension helps users to find Ecommerce Companies and Contact Information.",
            image: datalix,
          },
        ],
        heading:
          "Datalix provides users with an Ecommerce Company and Contact info",
        casestudy: {
          intropara:
            "  The Datalix Chrome Extension is a tool designed to help users find information about e-commerce companies and their contact information.The extension crawls through websites and collects data about e-commerce companies and their contact information.",
          challenge: [
            {
              heading: "The Challenges:-",
              challengeli: [
                {
                  First:
                    "Login - Show details of the login user on the extension.",
                },
                { First: "Show company and contact details." },
                {
                  First:
                    "Download the CSV file for company and contact details.",
                },
                { First: "Push company and contact details to Salesforce." },
              ],
            },
            {
              heading: "Solutions:-",
              challengeli: [
                {
                  First:
                    "After clicking on the login button it redirects to the login page. The content script of the extension then retrieves the token and user ID from local storage.",
                },
                { First: "Implement API and map the data." },
                {
                  First:
                    "Implement API for CSV and download data of company and contact details as well as selected contacts.",
                },
                {
                  First:
                    "Implement API for that and connect to WebSocket for pushing data to Salesforce.",
                },
              ],
            },
          ],
        },

        companyinfo: {
            logourl: "infaticaLogo",
          about: "About ",
          aboutpara:
            "The Datalix Chrome Extension is a tool designed to help users find information about e-commerce companies and their contact information.The extension crawls through websites and collects data about e-commerce companies and their contact information.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Datalix",
            },
            {
              first: "Industry:",
              secound: "Productivity",
            },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;