import React from "react";
import Casebanner from "./CaseBanner/Casebanner";
import data from "./casestudyMeinshort.js";
import Bannnerimage from "../../../images/casestudy/meinshort.webp";
import { SuperSEO } from "react-super-seo";

const MeinshortCaseStudy = () => {
  return (
    <>

      <SuperSEO
        title="Meinshort - Lakebrains"
        description="Grow your professional network by sharing all your personal and professional work through a single digital card"
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: { Bannnerimage },
            summaryCardImageAlt: "Meinshort",
            summaryCardSiteUsername: "Meinshort",
          },
        }}
      />
      {data.map((items, key) => (
        <Casebanner key={key} data={items.data} />
      ))}
    </>
  );
};

export default MeinshortCaseStudy;
