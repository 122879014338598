import React, { useState } from "react";
import JackSandsPic from "../../../../images/SubPages/OurTestimonials/JackSandsPic.webp";
import BCGPic from "../../../../images/SubPages/OurTestimonials/BCGPic.webp";
import Eric_Hansen from "../../../../images/SubPages/OurTestimonials/Eric_Hansen.webp";
import Joseph_Ugalde from "../../../../images/SubPages/OurTestimonials/Joseph_Ugalde.jpeg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Ourtestimonials.css";
import Popup1 from "./Popup1";
const OurTestimonials = () => {
  const [toggle, settoggle] = useState(0);
  const [popupstate, setPopupstate] = useState(false);

  const clientData = [
    {
      name: "Jack",
      photo: JackSandsPic,
      position: "CEO",
      review:
        "So, as we've built out my application Interact Technologies, which will be released shortly after the new year, they've taken over all programming, such as Website Development, Chrome Extension, NLP, Keyword Extraction, and on the Back-end, building out HubSpot CMS, and what's nice is that everything is communicating really well. I think probably the best part of working with LakeBrains Technologies is the relationship that we have built that goes way beyond the Client- Vendor Relationship.",
    },
    {
      name: "Joseph Ugalde",
      photo: Joseph_Ugalde,
      position: "CEO",
      review:
        "We worked with Chitransh and team to develop a Chrome Extention that allows one of our customers to add more functionalities inside their Cloud ERP system. The communication was clear, professional and the timing always was right. Their guidance and support through the project was key to have a happy customer. Keep it up the good work.",
    },
    {
      name: "BCG",
      photo: BCGPic,
      position: "CEO",
      review:
        "Overall, I was very happy with the Data Scraping. The quality of the data was up to the mark and encapsulated everything that I had asked for. You guys also had a great turnaround time for the job. Thanks for all the help!",
    },
    {
      name: "Eric Hansen",
      photo: Eric_Hansen,
      position: "CEO",
      review:
        "Lakebrains has helped us develop several AI software products and websites, they have exeeded our expectations. We will continue to work with lakebrains in the future.",
    },
    
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1204, min: 800 },
      items: 1,
      slidesToSlide: 1,
    },
    smalltablet: {
      breakpoint: { max: 800, min: 550 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const handletoggle = (e) => {
    settoggle(e);
    setPopupstate(true);
  };

  return (
    <div className="container-fluid ">
      <div className="example-classname ">
        <div className="container ">
          <div className="testimonials-wrapper">
            <div className="homepage-heading">
              <h4>Our Testimonials</h4>
              <h2>What our Clients says about us</h2>
              <p>
                We deliver the best results by creating dynamic, custom, and
                scalable experiences. Check out our testimonials.
              </p>
            </div>
            <div className=" our-test-wrapper-000 w-100 ">
              <div className="our-test-wrapper-0 container ">
                <div
                  className="col-lg-6 our-test-heading-t-0 text-left "
                  onClick={() => handletoggle(1)}
                >
                  <div className="text-center">
                    <img src={JackSandsPic} alt="Jack Sands" className="" />
                  </div>
                </div>

                <div className="col-lg-6 our-test-wrapper-0-1">
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    //   showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={10000}
                    keyBoardControl={true}
                    customTransition="all 1s ease-in-out"
                    transitionDuration={1000}
                    containerclassName="carousel-container12"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    //   deviceType={props.deviceType}
                    dotListclassName="custom-dot-list-style1"
                    itemclassName="carousel-item-padding-40"
                    className=" text-center align-center "
                  >
                    {clientData.map((items, index) => (
                      <div className="our-test-items-wrapper-0" key={index}>
                        <div className="">
                          <div className="row align-items-center c text-left px-5">
                            <div className="our-test-items-wrapper-0-0  mr-4">
                              <img
                                src={items.photo}
                                className="w-100 rounded-circle  "
                                alt="photo"
                              />
                            </div>
                            <div>
                              <div className="font-w-600 font-s-18 text-1">
                                {items.name}
                              </div>
                              <p className="p-0 m-0 font-s-12 text-lightgrey  ">
                                {items.position}
                              </p>
                            </div>
                            <p className="p-0 m-0 font-s-14 text-lightgrey mt-3 pl-2">
                              {items.review}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>

                  {toggle === 1 ? (
                    <Popup1
                      trigger={popupstate}
                      s
                      url="https://www.youtube.com/watch?v=wZ02FUjCYHw&t=2s&ab_channel=LakeBrainsTechnologies"
                      onClick={() => setPopupstate(false)}
                    ></Popup1>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTestimonials;
