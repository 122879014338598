
import ServicesTopBanner from "../../ServicesTopBanner/ServicesTopBanner";
import ServicesBoxSection from "../../ServicesBoxSection/ServicesBoxSection";
import ServiceTechnologiesWeWork from "../../ServiceTechnologiesWeWork/ServiceTechnologiesWeWork";
import NLP from "../NLP";
import Whyus from "../../../../Comman/Why Us/Whyus";
import OurTestimonials from "../../../SubSection/ourtestimonials/OurTestimonials";
import ScheduleCall from "../../../../Comman/ScheduleCall/ScheduleCall";
import CaseStudySection from "../../../../Comman/CaseStudy/CaseStudy";
import FAQ from "../../../../Comman/FAQ/FAQ";
import { Helmet } from "react-helmet";
import { SuperSEO } from "react-super-seo";
import DemoDataSection from "../../../../Comman/CaseStudy/DemoData";

const NLPData = [
  {
    // image: behavioranalytics,
    h6: "Behavior Analysis",
    alt: "Behavior Analysis",
    link: "",
  },
  {
    // image: Sentiment,
    h6: "Sentiment Analysis",
    alt: "Sentiment Analysis",
    link: "",
  },
  {
    // image: keywords,
    h6: "Keyword Extraction",
    alt: "Keyword Extraction",
    link: "",
  },
  {
    // image: topicmodeling,
    h6: "Topic Modeling",
    alt: "Topic Modeling",
    link: "",
  },
  {
    // image: audiototext,
    h6: "Audio To Text",
    alt: "Audio To Text",
    link: "",
  },
  {
    // image: chatbot,
    h6: "Chatbots",
    alt: "Chatbots",
    link: "",
  },
  {
    // image: tensorflow,
    h6: "Tensorflow",
    alt: "Tensorflow",
    link: "",
  },
  {
    // image: pytorch,
    h6: "PyTorch",
    alt: "PyTorch",
    link: "",
  },
];
// Service data for TechnologiesWeWork section
const services_data_nlp = [
  {
    name: "Custom Models",
    alt: "Custom Models",
    icon: "custom models",
  },
  {
    name: "Validation\n(C&Q and CSV)",
    alt: "Validation\n(C&Q and CSV)",
    icon: "validation",
  },
  {
    name: "Engineering",
    alt: "Engineering",
    icon: "engineering",
  },
  {
    name: "Numpy, Panda, matplotlib",
    alt: "Numpy, Panda, matplotlib",
    icon: "management",
  },
  {
    name: "Manufacturing\nIT",
    alt: "Manufacturing\nIT",
    icon: "manufacturing",
  },
  {
    name: "Technical\nServices",
    alt: "Technical\nServices",
    icon: "technical",
  },
  {
    name: "Web Assembly",
    alt: "Web Assembly",
    icon: "process",
  },
];
const Bdata = [
  {
    // image: Behaviour,
    title: "Behavior Analysis",
    alt: "Behavior Analysis",
    Link: "/api/empathy/",
    LinkText: "Try Demo",
    description:
      "Thanks to our research on Natural Language Processing (NLP), we've developed the ability to analyze, detect, and respond to empathetic situations. This capability is particularly essential in scenarios such as sales and customer care, where sensitivity and empathy are key to providing successful outcomes...",
      href:"/nlp-service-provider-company/Behavior-Analysis",
      Case: [
      {
        image: "",
        CaseTitle: "Sentiment Analysis / Intent Analysis ",
        CaseDescribtion:
          "Companies may understand better what their consumers are saying about them by using NLP-based sentiment analysis. This method of machine learning automatically analyzes data and analyzes the text's emotion.",
      },
    ],
  },
  // {
  //   image: Emotions,
  //   title: "Emotion Analysis",
  //   alt: "Emotion Analysis",
  //   Link: "http://blogs.lakebrains.com/nlp-chatbots/",
  //   LinkText: "More On Emotion Analysis",
  //   description:
  //     "Companies may understand better what their consumers are saying about them by using NLP-based sentiment analysis.This method of machine learning automatically analyzes data and analyzes the text's emotion.",
  //   Case: [
  //     {
  //       image: "",
  //       CaseTitle: "Text Classification/Modeling",
  //       CaseDescribtion:
  //         "The importance of high-quality text categorization is growing for all businesses. Businesses may get significant insights from data that aids in automating a variety of business operations when text data is properly arranged. To help different businesses achieve their unique data classification goals, Lakebrains Technologies provides a variety of text classification solutions.",
  //     },
  //   ],
  // },
  {
    // image: ChatBots,
    title: "Chatbots",
    alt: "Chatbots",
    Link: "https://drive.google.com/file/d/1FfjGxcM4rP4bMwKoXmI8VBPYn35iX8hz/view?usp=share_link" /*not working*/,
    LinkText: "PDF Analyzer",
    Link2: "http://blogs.lakebrains.com/nlp-chatbots/",
    LinkText2: "Empathy Response",
    description:
      "Our team of experts is proficient in building custom chatbots utilizing Large Language Models such as chatgpt, all while relying on your knowledge base as a foundation. By utilizing these technologies and applications, our chatbot can function efficiently as a valuable AI assistant, working alongside you to help achieve your objectives...",
      href:"/nlp-service-provider-company/chatbots",
      Case: [
      {
        image: "",
        CaseTitle: "Confidence Analysis Model ",
        CaseDescribtion:
          "Use this file however you want!It’s yours, free, for personal or commercial use.",
      },
    ],
  },
  {
    // image: AudioToText,
    title: "Audio to Text",
    alt: "Audio to Text",
    Link: "http://blogs.lakebrains.com/nlp-chatbots/",
    LinkText: "More On Audio to Text",
    description:
      "Given our team's proficient knowledge in web development and AI, we are able to optimize and port advanced state-of-the-art speech recognition models straight to your web front-end and chrome extension. As a result, employing these models can provide no-cost high-quality transcription capabilities for edge devices...",
      href:"/nlp-service-provider-company/audio-to-text",
      Case: [
      {
        image: "",
        CaseTitle: "Confidence Analysis Model ",
        CaseDescribtion:
          "Use this file however you want!It’s yours, free, for personal or commercial use.",
      },
    ],
  },
  // {
  //   image: Topic,
  //   title: "Topic Modeling",
  //   alt: "Topic Modeling",
  //   Link: "http://blogs.lakebrains.com/nlp-chatbots/",
  //   LinkText: "More On Topic Modeling",
  //   description:
  //     "Text can contain multiple languages. Companies with a multilingual consumer base frequently have textual data in many languages. To help machine classifiers understand material written in several languages, proper and accurate annotation is necessary. Lakebrains Technologies provides the resources to train your model to correctly identify several languages due to the extensive and multilingual data contributors and expert annotators.",
  //   Case: [
  //     {
  //       image: "",
  //       CaseTitle: "Confidence Analysis Model ",
  //       CaseDescribtion:
  //         "Use this file however you want!It’s yours, free, for personal or commercial use.",
  //     },
  //   ],
  // },
  {
    // image: Notes,
    title: "Notes Capture",
    alt: "Notes Capturing",
    Link: "http://blogs.lakebrains.com/nlp-chatbots/" /*not working*/,
    LinkText: "More on Note Captures",
    description:
      "By incorporating artificial intelligence, the process of capturing notes can be greatly enhanced. Our team of experts has developed a fast and efficient notes capture API that can be seamlessly integrated into any tool, for an even better user experience...",
      href:"/nlp-service-provider-company/notes-capture",
      Case: [
      {
        image: "",
        CaseTitle: "Confidence Analysis Model ",
        CaseDescribtion:
          "Use this file however you want!It’s yours, free, for personal or commercial use.",
      },
    ],
  },
];

const Fdata = [
  {
    heading: "NLP ",
    subData: [
      {
        accordionHead: "How can sentiment analysis be used?",
        accordionPara:
          "Sentiment analysis is used in a number of areas. Brand management companies use it to help them understand what consumers think about their brand. Governments also use it to find out what people feel and think about the policies the government implements. Social media platforms also use it to understand their users and the context of interactions in relation to platform principles.",
        id: "1",
      },

      {
        accordionHead: "Will NLP bring value to my company?",
        accordionPara:
          "Big companies like Google, Facebook, Amazon, and Microsoft have long used NLP to improve their products and services. Many startups are also adopting NLP technology with great success. If you have a business that does customer service, data entry, word processing, or document creation, NLP can save you time and money.\nHowever, there is no guarantee that custom NLP software will achieve this expected value or that the total cost will not be too high to manage. This is why the detailed planning phase and iterative approach are so important in every AI-based project. Only then can you predict your costs, revenue, and break-even points in your growth. There are ways to reduce the risk of not taking advantage of solutions that use AI. LakeBrains' methodology is based on a deep understanding of the project's needs and the capabilities of current AI technologies, along with an iterative approach. Our project methodology focuses on creating value as early as possible and starts small with the development of the IA Proof of Concept.",
        id: "2",
      },
      {
        accordionHead: "What is a good NLP company",
        accordionPara:
          "A good NLP company is a trusted technology company that has established expertise in natural language processing and provides customized solutions based on your business needs.",
        id: "3",
      },
      {
        accordionHead:
          "I have a lot of data. Can Lakebrains help me clean and prepare it for NLP?",
        accordionPara:
          "We have worked with a wide range of data sources and formats. We can assist you with cleaning and preparing data for natural language processing. Not only can our team support you with data pre-processing or annotation, but we can also develop and implement a custom data collection strategy tailored to your specific needs.",
        id: "4",
      },
      {
        accordionHead:
          "How much data do I need for a natural language processing project?",
        accordionPara:
          "There is no single answer to this question. Depending on the type of work you are trying to do, its complexity, and the resources at your disposal, the amount of data required for a natural language processing project will vary. In general, however, you should have as much data as possible when working with deep learning projects, as more data will allow the algorithm to learn and improve its performance.\nOur data scientists are experienced in providing preliminary data quality assessments and preparing a dedicated data collection strategy for our clients.",
        id: "5",
      },
    ],
  },
];

const CaseData = [
  {
    heading: " Voice Commands",
    alt: " Voice Commands",
    // logo: "https://www.interactpro.ai/hubfs/raw_assets/public/InteractPro_Theme/images/Logo.svg",
    subheading: "Browser Control Through Voice Commands",
    para: "'Browser Control Through Voice Commands' is a free chrome extension that allows you to control your chrome browser through voice commands. You can add the extension to your chrome browser and then give commands to perform your task. ",
    btntext: "More On Voice Commands",
    // imgurl: VoiceCommand,

    caseurl: "/casestudy/browser-control-through-voice-commands",
  },
  {
    heading: "Interact Extension",
    alt: "Interact Extension",
    // logo: "https://idealeads.in/wp-content/uploads/2021/10/ideallead_Logo-e1634960127764.webp",
    subheading: "Interact Chrome Extension",
    para: "Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers.",
    btntext: "More On Interact Extension",
    // imgurl: InteractExtension,
    caseurl: "/casestudy/interact-extension",
  },
  {
    heading: "Transcript Extension",
    alt: "Transcript Extension",
    // logo: "https://meinshort.com/static/media/logo.320ce173.svg",
    subheading: "Live Transcript Extension",
    para: "You could want to record lectures from online meetings, online courses, podcasts, and streaming YouTube videos.",
    btntext: "More On Transcript Extension",
    // imgurl: LiveTranscript,
    caseurl: "/casestudy/capture-transcript-from-a-meet",
  },
];
const DemoData = [
  {
    heading: " Voice Commands",
    alt: " Voice Commands",
    // logo: "https://www.interactpro.ai/hubfs/raw_assets/public/InteractPro_Theme/images/Logo.svg",
    subheading: "Browser Control Through Voice Commands",
    para: "'Browser Control Through Voice Commands' is a free chrome extension that allows you to control your chrome browser through voice commands. You can add the extension to your chrome browser and then give commands to perform your task. ",
    btntext: "More On Voice Commands",
    // imgurl: VoiceCommand,

    caseurl: "/casestudy/browser-control-through-voice-commands",
  },
  {
    heading: "Interact Extension",
    alt: "Interact Extension",
    // logo: "https://idealeads.in/wp-content/uploads/2021/10/ideallead_Logo-e1634960127764.webp",
    subheading: "Interact Chrome Extension",
    para: "Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers.",
    btntext: "More On Interact Extension",
    // imgurl: InteractExtension,
    caseurl: "/casestudy/interact-extension",
  },
  {
    heading: "Transcript Extension",
    alt: "Transcript Extension",
    // logo: "https://meinshort.com/static/media/logo.320ce173.svg",
    subheading: "Live Transcript Extension",
    para: "You could want to record lectures from online meetings, online courses, podcasts, and streaming YouTube videos.",
    btntext: "More On Transcript Extension",
    // imgurl: LiveTranscript,
    caseurl: "/casestudy/capture-transcript-from-a-meet",
  },
];

const BehaviorAnalysis = ({ city }) => {
  //  const location = useLocation();
  //  console.log(location);
  return (
    <div>
     

      <SuperSEO
        title="Natural Language Processing Services Provider Company"
        description=""
        lang="en"
        twitter={{
          twitterSummaryCard: {
            // summaryCardImage: { NLPImage },
            summaryCardImageAlt: "img",
            summaryCardSiteUsername: "justinmahar",
          },
        }}
      />
      <ServicesTopBanner
        headTitle={
          city
            ? `Natural Language Processing Services in ${city}`
            : `Chatbots`
        }
        headDes="kmjnhbgvfcgvhbjklkoijuhygtfrfgvhbjnkmoijuyhgtfrftgyhj"
        // video={NLPImage}
      />
      <ServicesBoxSection techtype="Build Next Generation Solution With Lakebrains NLP Experts" />
      <NLP
        data={Bdata}
        title="NLP"
        subTitle="Using our advanced NLP solutions and technology, we understand user queries and the intent of business content to deliver an improved user experience, automated support, and simplified business processes."
      />
      <DemoDataSection DemoData={DemoData} />
      <ServiceTechnologiesWeWork
        Cdata={NLPData}
        title="NLP"
        subTitle="Our Natural Language Programming services have always met customer expectations using NLP technologies (NLPTK, Pytorch), machine code and application programming interfaces. We offer the best solutions to help you propel your business into the digital age as a leader in NLP Solutions. We ensure that the teams working on the development of your application are experts in the use of practical tools."
      />
      {/* <TechnologiesWeWork subTitle="Use natural language processing to better understand your customers and markets. At Lakebrains, we can easily develop solutions that leverage digital conversations and provide valuable insight and support for a wide range of business processes." /> */}
      <CaseStudySection CaseData={CaseData} />
      <Whyus />
      {/* <TechnologiesWeWork Sdata={services_data_nlp} title="NLP" /> */}
      <OurTestimonials />
      <ScheduleCall />
      <FAQ Fdata={Fdata} ServiceType="NLP" />
    </div>
  );
};

export default BehaviorAnalysis;

