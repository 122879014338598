import React from "react";
import { Link } from "react-router-dom";
import "./OurServices.css";
import OS1 from "../../../../images/NewHomePage/Ourservices3.svg";
import OS2 from "../../../../images/NewHomePage/Ourservices2.svg";
import OS3 from "../../../../images/NewHomePage/Ourservices1.svg";

const OurServices = () => {
  const servicesData = [
    {
      id: 1,
      categories: ["Web", "Mobile", "Plugin"],
      title: "SaaS Product Engineering",
      alt: "SaaS Product Engineering",
      image: OS3,
      btnText: "View More",
      caseUrl: "/saas-product-service-provider-company/",
    },
    {
      id: 2,
      categories: ["Generative AI", "NLP", "Computer Vision"],
      title: "AI/ML Development",
      alt: "AI/ML Development",
      image: OS2,
      btnText: "View More",
      caseUrl: "/ai-ml-Development-service-provider-company/",
    },
    {
      id: 3,
      categories: ["AWS", "GCP", "Azure"],
      title: "Cloud Deployment",
      alt: "Cloud Deployment",
      image: OS1,
      btnText: "View More",
      caseUrl: "/Cloud-Deployment-service-provider-company/",
    },
  ];

  return (
    <div className="OurServices-section">
      <div className="container">
        <div className="OurServices-text d-flex text-center flex-column align-items-center">
          <h4>What We Do?</h4>
          <h2>Our Services</h2>
          <p>
            At Lakebrains Technologies, we offer a suite of client-focused, specially tailored design and development services for both web and mobile platforms.
          </p>
        </div>

        <div className="OurServices-parent row justify-content-between">
          {servicesData.map((service) => (
            <div key={service.id} className="col-md-4 col-sm-6">
              <div className="OurServices-Nthchild">
                <div className="OSboxes w-100 h-00">
                  <div className="OurServices-img mx-auto">
                    <img src={service.image} alt={service.alt} loading="lazy" />
                  </div>
                  <div className="OurServices-heading LakeComm-Whyu-ContentReason text-center">
                    <h6>{service.title}</h6>
                  </div>
                  <div className="OurServices-para LakeComm-Whyu-Contentpara text-center">
                    {service.categories.map((category, index) => (
                      <p key={index}>{category}</p>
                    ))}
                  </div>
                  <div className="Services-button d-flex flex-column align-items-center">
                    <Link to={service.caseUrl}>
                      <button>{service.btnText}</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurServices;
