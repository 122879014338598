import React, { useState, useEffect } from "react";
import BannerComponent from "./BannerComponent/BannerComponent";
import OurTools from "./Counts/Counts";
import Step from "./StepsSection/StepByStep";
import Benefits from "./Benefits/Benefits";
import Section5 from "./Section-5/Section5";
import OurTestimonials from "../../pages/SubSection/ourtestimonials/OurTestimonials";
import ScheduleCall from "../../Comman/ScheduleCall/ScheduleCall";
import { SuperSEO } from "react-super-seo";

import "./ProductPage.css";

const ProductLayout = ({ data }) => {
  const [Data, setData] = useState("");

  useEffect(() => {
    setData(data);
  }, [data]);

  return (
    <div className="">
      <SuperSEO
        title={data.section_1.title}
        description={data.section_1.subTitle}
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: data.section_1.image,
            summaryCardImageAlt: data.section_1.title,
            summaryCardSiteUsername: "justinmahar",
          },
        }}
      />
      <BannerComponent Data={Data.section_1} />
      <OurTools Data={Data.section_2} />
      <Step Data={Data.section_3} />
      <Benefits Data={Data.section_4} />
      <Section5 Data={Data} />
      <OurTestimonials />
      <ScheduleCall />
    </div>
  );
};

export default ProductLayout;
