import React from "react";

import Tensorflow from "../../../../images/NLPTechIcons/Tensorflow.svg";
import PyTorch from "../../../../images/NLPTechIcons/PyTorch.svg";
import ONNX from "../../../../images/NLPTechIcons/onnx.webp";
import OpenCV from "../../../../images/NLPTechIcons/OpenCV.svg";
import NumPy from "../../../../images/NLPTechIcons/NumPy2.webp";
import Pandas from "../../../../images/NLPTechIcons/Pandas2.webp";
import Scikit from "../../../../images/NLPTechIcons/Scikit2.webp";
import SageMaker from "../../../../images/NLPTechIcons/sagemaker.webp";
import Matplotlib from "../../../../images/NLPTechIcons/Matplotlib.svg";
import Bokeh from "../../../../images/NLPTechIcons/Bokeh.svg";
import Holoviz from "../../../../images/NLPTechIcons/Holoviz2.webp";
import CNN from "../../../../images/NLPTechIcons/CNN.svg";
import Bert from  "../../../../images/NLPTechIcons/bert.png";
import Huggingface from  "../../../../images/NLPTechIcons/huggingface.png";
import Silero from  "../../../../images/NLPTechIcons/silero_logo.jpg";
import Whisper from  "../../../../images/NLPTechIcons/whisper.png";
import AI from  "../../../../images/NLPTechIcons/imageAI.png";
// import Sherpa from  "../../../../images/NLPTechIcons/Sherpa.ai_logo.svg";
import DialoGPT from  "../../../../images/NLPTechIcons/dialoGPT.PNG";
import Deepspeech from  "../../../../images/NLPTechIcons/deepspeech.jpg";
import { Helmet } from 'react-helmet';



// import RNN from "../../../../images/NLPTechIcons/RNN.svg";

import behavioranalytics from "../../../../images/OurtechStack/behavioranalytics.webp";
import Sentiment from "../../../../images/OurtechStack/Sentiment.webp";
import keywords from "../../../../images/OurtechStack/keywords.webp";
import topicmodeling from "../../../../images/OurtechStack/topicmodeling.webp";
import audiototext from "../../../../images/OurtechStack/audiototext.webp";
import chatbot from "../../../../images/OurtechStack/chatbot.webp";
import tensorflow from "../../../../images/OurtechStack/tensorflow.webp";
import pytorch from "../../../../images/OurtechStack/pytorch.webp";

// Tensorflow
// PyTorch
// ONNX
// OpenCV
// NumPy
// Pandas
// Scikit-Learn
// SageMaker
// Matplotlib
// Bokeh
// Holoviz
// CNN
// RNN

import mysql from "../../../../images/OurtechStack/mysql.svg";
import mongodb from "../../../../images/OurtechStack/mongodb.svg";
import api from "../../../../images/OurtechStack/api.webp";
import django from "../../../../images/OurtechStack/django.svg";
import flask from "../../../../images/OurtechStack/flask.webp";
import nodejs from "../../../../images/OurtechStack/nodejs.svg";
import next from "../../../../images/OurtechStack/nextjs.webp";
import react from "../../../../images/OurtechStack/react.webp";
import express from "../../../../images/OurtechStack/express.svg";
import html from "../../../../images/OurtechStack/html5.svg";
import css from "../../../../images/OurtechStack/css3.svg";
import bootstrap from "../../../../images/OurtechStack/bootstrap.svg";
import less from "../../../../images/OurtechStack/less.svg";
import sass from "../../../../images/OurtechStack/sass.svg";
import extension from "../../../../images/OurtechStack/extension.svg";
import javascript from "../../../../images/OurtechStack/javascript.svg";
import jquery from "../../../../images/OurtechStack/jquery.svg";
import sails from "../../../../images/OurtechStack/sails.webp";

// AWS
import sagemaker from "../../../../images/OurtechStack/sagemaker.webp";
import ecs from "../../../../images/OurtechStack/ecs.webp";
import ec2 from "../../../../images/OurtechStack/ec2.svg";
import s3 from "../../../../images/OurtechStack/s3.svg";
import sns from "../../../../images/OurtechStack/sns.webp";
import apigateway from "../../../../images/OurtechStack/apigateway.webp";
import eks from "../../../../images/OurtechStack/eks.webp";
import lambda from "../../../../images/OurtechStack/lambda.webp";


const FrontendData = [
  {
    logo: next,
    name: "Next.js",
    alt: "Next.js",
    link: "https://nextjs.org/"
  },
  {
    logo: react,
    name: "React.js",
    alt: "React.js",
    link: "https://reactjs.org/",
  },
  {
    logo: javascript,
    name: "Javascript",
    alt: "Javascript",
    link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
  },
  {
    logo: extension,
    name: "Extension",
    alt: "Extension",
    link: "https://chrome.google.com/webstore/category/extensions",
  },
  {
    logo: jquery,
    name: "Jquery",
    alt: "Jquery",
    link: "https://jquery.com/",
  },
  {
    logo: html,
    name: "Html5",
    alt: "Html5",
    link: "https://en.wikipedia.org/wiki/HTML",
  },
  {
    logo: css,
    name: "Css3",
    alt: "Css3",
    link: "https://en.wikipedia.org/wiki/CSS",
  },
  {
    logo: bootstrap,
    name: "Bootstrap",
    alt: "Bootstrap",
    link: "https://getbootstrap.com/",
  },
  {
    logo: sass,
    name: "Sass",
    alt: "Sass",
    link: "https://sass-lang.com/",
  },
];
const BackendData = [
  {
    logo: nodejs,
    name: "Node.js",
    alt: "Node.js",
    link: "https://nodejs.org/"
  },
  {
    logo: express,
    name: "Express.js",
    alt: "Express.js",
    link: "https://expressjs.com/",

  },
  {
    logo: flask,
    name: "Flask",
    alt: "Flask",
    link: "https://flask.palletsprojects.com/"
  },
  {
    logo: django,
    name: "Django",
    alt: "Django",
    link: "https://www.djangoproject.com/"
  },
  {
    logo: api,
    name: "Fast API",
    alt: "Fast API",
    link: "https://fastapi.tiangolo.com/"
  },
  {
    logo: sails,
    name: "Sails.js",
    alt: "Sails.js",
    link: "https://sailsjs.com/"
  },
  {
    logo: mongodb,
    name: "MongoDB",
    alt: "MongoDB",
    link: "https://www.mongodb.com/home"
  },
  {
    logo: mysql,
    name: "MySQL",
    alt: "MySQL",
    link: "https://www.mysql.com/"
  },
];

const AWSData = [
  {
    logo: lambda,
    name: "Lambda",
    alt: "Lambda",
    link: "https://aws.amazon.com/lambda/",
  },
  {
    logo: ec2,
    name: "EC2",
    alt: "EC2",
    link: "https://aws.amazon.com/ec2/",
  },
  {
    logo: ecs,
    name: "ECS",
    alt: "ECS",
    link: "https://aws.amazon.com/ecs/",
  },
  {
    logo: s3,
    name: "S3",
    alt: "S3",
    link: "https://aws.amazon.com/s3/",
  },
  {
    logo: sns,
    name: "SNS",
    alt: "SNS",
    link: "https://aws.amazon.com/sns/",
  },
  {
    logo: sagemaker,
    name: "Sagemaker",
    alt: "Sagemaker",
    link: "https://docs.aws.amazon.com/sagemaker/",
  },
  // {
  //     logo:eps,
  //     name:'EPS'
  // },
  {
    logo: apigateway,
    name: "API Gateway",
    alt: "API Gateway",
    link: "https://aws.amazon.com/api-gateway/"
  },
  {
    logo: eks,
    name: "EKS",
    alt: "EKS",
    link: "https://aws.amazon.com/eks/"
  },
];

const ServiceTechnologiesWeWork = ({ Cdata, title, subTitle }) => {
  console.log("title =>", title);

  const data1 = [
    {
      name: "Tensorflow",
      alt: "Tensorflow",
      logo: Tensorflow,
      link: "https://www.tensorflow.org/",
    },
    {
      name: "PyTorch",
      alt: "PyTorch",
      logo: PyTorch,
      link: "https://pytorch.org/",
    },
    {
      name: "ONNX",
      alt: "ONNX",
      logo: ONNX,
      link: "https://onnx.ai/",
    },
    {
      name: "OpenCV",
      alt: "OpenCV",
      logo: OpenCV,
      link: "https://opencv.org/",
    },
    {
      name: "NumPy",
      alt: "NumPy",
      logo: NumPy,
      link: "https://numpy.org/",
    },
    {
      name: "Pandas",
      alt: "Pandas",
      logo: Pandas,
      link: "https://pandas.pydata.org/",
    },
    {
      name: "Scikit-Learn",
      alt: "Scikit-Learn",
      logo: Scikit,
      link: "https://scikit-learn.org/stable/",
    },
    {
      name: "SageMaker",
      alt: "SageMaker",
      logo: SageMaker,
      link: "https://aws.amazon.com/pm/sagemaker/",
    },
    {
      name: "Matplotlib",
      alt: "Matplotlib",
      logo: Matplotlib,
      link: "https://matplotlib.org/",
    },
    {
      name: "Bokeh",
      alt: "Bokeh",
      logo: Bokeh,
      link: "https://bokeh.org/",
    },
    {
      name: "Holoviz",
      alt: "Holoviz",
      logo: Holoviz,
      link: "https://holoviz.org/",
    },
    {
      name: "CNN",
      alt: "CNN",
      logo: CNN,
      link: "https://us.cnn.com/",
    },
    {
      name: "Bert",
      alt: "Bert",
      logo: Bert,
      link: "https://ai.googleblog.com/2018/11/open-sourcing-bert-state-of-art-pre.html",
    },
    {
      name: "Huggingface",
      alt: "Huggingface",
      logo: Huggingface,
      link: "https://huggingface.co/",
    },
    {
      name: "Silero",
      alt: "Silero",
      logo: Silero,
      link: "https://pytorch.org/hub/snakers4_silero-models_stt/",
    },
    {
      name: "Whisper",
      alt: "Whisper",
      logo: Whisper,
      link: "https://openai.com/research/whisper",
    },
    {
      name: "AI",
      alt: "AI",
      logo: AI,
      link: "https://github.com/OlafenwaMoses/ImageAI",
    },
    // {
    //   name: "Sherpa",
    //   alt: "Sherpa",
    //   logo: Sherpa,
    //   link: "https://k2-fsa.github.io/sherpa/index.html",
    // },
    {
      name: "Deepspeech",
      alt: "Deepspeech",
      logo: Deepspeech,
      link: "https://deepspeech.readthedocs.io/en/r0.9/",
    },
    {
      name: "DialoGPT",
      alt: "DialoGPT",
      logo: DialoGPT ,
      link: "https://huggingface.co/docs/transformers/model_doc/dialogpt",
    }
  ];

  return (
    <>
    <Helmet>
    <title>Lakebrains Technology : Our Tech Stack</title>
    <meta name="robots" content="index,nofollow" />
    </Helmet>
    <div className="">
      {title === "web" ? (
        <div className="container-fluid  position-relative ">
          <div className="example-classname">
            <div className="container LakeComm-Whyu-Wrapper position-relative">
              <div className="homepage-heading">
                <div className="text-center mt-4">
                  <h4>Technologies We Work With?</h4>
                  <h2>Our Tech Stack</h2>
                  <p>{subTitle}</p>
                </div>
              </div>

              <div className=" d-flex flex-column-reverse">
                <div className="container-fluid row mx-0  px-0 ourtechstack-sub-wrapper">
                  {/* NLP */}
                  {/* <div className="col-lg-3 col-md-6 ots-tech-items-endborder-000 px-0 ">
                    <div className=" text-center pb-4 row justify-content-around d-flex  ots-tech-items-wrapper mx-0">
                      {NLPData.map((data) => (
                        <div className="ots-tech-items col-5  ">
                          <a href="#" target="self">
                            <div className="ots-tech-items-01">
                              <img src={data.logo} alt="" className="w-100" />
                            </div>
                            <div className="pt-2 w-100">{data.name}</div>
                          </a>
                        </div>
                      ))}
                    </div>
                    <div className=" text-center ots-heading">NLP</div>
                  </div> */}

                  {/* Frontend*/}
                  <div className="col-lg-4 col-md-6 ots-tech-items-endborder-000 px-0 ">
                    <div className=" text-center pb-4 row justify-content-around d-flex  ots-tech-items-wrapper ots-tech-items-wrapper2  flex-direction- mx-0">
                      {/* <div className='ots-tech-items-endborder'></div> */}
                      {FrontendData.map((data, index) => (
                        <div className="ots-tech-items col-5  " key={index}>
                          <a href={data.link}>
                            <div className="ots-tech-items-01">
                              <img src={data.logo} alt={data.alt} className="w-100" />
                            </div>
                            <div className="pt-2 w-100">{data.name}</div>
                          </a>
                        </div>
                      ))}
                    </div>
                    <div className=" text-center ots-heading">Front End</div>
                  </div>

                  {/* Backend */}
                  <div className="col-lg-4 col-md-6 ots-tech-items-endborder-000 px-0">
                    <div className=" text-center pb-4 row justify-content-around d-flex  ots-tech-items-wrapper border-d-right border-d-left mx-0 ots-tech-items-wrapper2">
                      {/* <div className='ots-tech-items-endborder'></div> */}
                      {BackendData.map((data, index) => (
                        <div className="ots-tech-items col-5" key={index}>
                          <a href={data.link}>
                            <div className="ots-tech-items-01">
                              <img src={data.logo} alt={data.alt} className="w-100" />
                            </div>
                            <div className="pt-2 w-100">{data.name}</div>
                          </a>
                        </div>
                      ))}
                    </div>
                    <div className=" text-center ots-heading">Back End</div>
                  </div>

                  {/* AWS Cloud */}
                  <div className="col-lg-4 col-md-6 ots-tech-items-endborder-000 px-0">
                    <div className=" text-center pb-4 row justify-content-around d-flex  ots-tech-items-wrapper mx-0">
                      {AWSData.map((data, index) => (
                        <div className="ots-tech-items col-5" key={index}>
                          <a href={data.link}>
                            <div className="ots-tech-items-01">
                              <img src={data.logo} alt={data.alt} className="w-100" />
                            </div>
                            <div className="pt-2 w-100">{data.name}</div>
                          </a>
                        </div>
                      ))}
                    </div>
                    <div className=" text-center ots-heading">AWS Cloud</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid  position-relative ">
          <div className="example-classname">
            <div className="container overflow-hidden">
              <div className="LakeComm-Whyu-Wrapper position-relative">
                <div className="homepage-heading">
                  <div className="text-center">
                    <h4>Technologies We Work With?</h4>
                    <h2>Our Tech Stack</h2>
                    <p>{subTitle}</p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 ots-tech-items-endborder-000 px-0 ">
                  <div className=" text-center pb-4 row justify-content-around d-flex  ots-tech-items-wrapper ots-tech-items-wrapper2  flex-direction- mx-0">
                    {/* <div className='ots-tech-items-endborder'></div> */}
                    {title === "NLP"
                      ? data1.map((data, index) => (
                        <div className="ots-tech-items col-12" key={index}>
                          <a href={data.link}>
                            <div className="ots-tech-items-01">
                              <img src={data.logo} alt={data.alt} className="w-100" />
                            </div>
                            <div className="pt-2 w-100">{data.name}</div>
                          </a>
                        </div>
                      ))
                      : FrontendData.map((data, index) => (
                        <div className="ots-tech-items col-12" key={index}>
                          <a href={data.link}>
                            <div className="ots-tech-items-01">
                              <img src={data.logo} alt={data.alt} className="w-100" />
                            </div>
                            <div className="pt-2 w-100">{data.name}</div>
                          </a>
                        </div>
                      ))}
                    {/*  */}
                  </div>
                  {/* <div className=" text-center ots-heading">Front End</div> */}
                </div>
              </div>
            </div>
            <div className="LakeHome-Whyus-background3 position-absolute"></div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default ServiceTechnologiesWeWork;
