import React from 'react'
import HireDeveloper from './HireDeveloper'

export default function HireExtension() {
    const data = 
        {
        
            bannersubtitle :"Hire Browser Extension Developers",
            bannertitle :"Need to Hire Browser Extension Developers for your next project",
            bannerdescription: "Lakebrains provides the perfect solution for enterprises and startups looking to hire Browser Extension JS developers. All of our offshore Browser Extension JS developers are skilled in the most current technologies and delivery methodologies, so you don't have to worry about your project getting put on hold with these rising costs. Hire a remote Browser Extension JS developer today, and start expecting complex solutions at an affordable price.",
        
    
            modelsubtitle:"Engagement Models",
            modeltitle:"Our Models to Hire Browser Extension Developers",
            modeldescription:"Get an advantage of different hiring models to collaborate with experts in Browser Extension. Different hiring models include contracting, consulting, and permanent roles. You can shape your applications however you see fit with expert guidance.",
        
    
            whatwedo:{
                subtitle:"What We Do ?",
                title:"Hire Browser Extension  Developer for End-to-End Services",
                description:"Working with us will allow you to stay focused on your goals and meet standards without having to worry about the tech side of things. Our Browser Extension developers are professional and know JavaScript inside out, making them the perfect choice for your business. We offer services that are tailored to your needs, including full-stack development and round-the-clock support. With our high-performing results-oriented Browser Extension development services, your website is optimized for mobile and productivity so you don't have to worry about it.",
            whatwedoinnersection:[
                {
                    title: "Custom Browser Extension Development",
                    description: "When you hire Browser Extension  developer from Lakebrains, you get a team dedicated to building custom Browser Extension-based websites that are recognized for security, scalability, and functionality.",
                },
                {
                    title: "API & Web Service Integration",
                    description: "Hire Browser Extension developers from Lakebrains, holding profound skills and experience in API development as well as web service integration.",
                },
                {
                    title: "Browser Extension  Migration Services",
                    description: "Our experienced Browser Extension development teams help you migrate applications to Browser Extension easily and smoothly based on your business requirements.",
                },
                {
                    title: "Browser Extension Frontend Development",
                    description: "Front-end web development using Browser Extension to create an interactive interface that reduces code complexity.",
                },
                {
                    title: "Browser Extension Plugin Development",
                    description: "e professional developers to build complete web applications by developing custom Browser Extension. plugins.",
                },
                {
                    title: "Support & Maintenance Services",
                    description: "Your website is supported and maintained completely by LakeBrains Browser Extension developers, who also keep it up to date with the most recent ",
                }
            ]   
            },
            whywearedifferent:{
                subtitle:"Why we are different",
                title:"Key Differentiators of Hiring Browser Extension Developers from Lakebrains",
                description:"Work With Browser Extension Developers Through A Variety Of Hiring Models To Build And Develop Web Applications That Make A Difference."
            },
        
        
            whyus:{
                subtitle:"Why us",
                title:"Why Hire Browser Extension  Developer From Lakebrains",
                description:"Our expert Browser Extension developers are premier in creating high-octane web applications. We offer our clients the freedom to hire Browser Extension  developers smoothly, which will result in their business growth. Whether you need cross-platform-friendly web applications or single-page web app development, Lakebrains can help you fulfill your needs. Here are some reasons why you should hire Browser Extension Develoepor Fromn Lakebrains"
            },
        
        
            ourexpertise:{
                description:"Lakebrains has extensive expertise on the top Browser Extension component libraries worth trying. Hire Browser Extension developer from us and build future-ready web apps with the libraries and features you need."
            }
        }

  return (
    <>
    <HireDeveloper
    data = {data}/>
    </>
  )
}
