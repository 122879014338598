import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './companies.css';
import compnay1 from '../../../images/casestudy/Companys/1.png';
import compnay2 from '../../../images/casestudy/Companys/2.png';
import compnay3 from '../../../images/casestudy/Companys/3.png';
import compnay4 from '../../../images/casestudy/Companys/4.png';
import compnay5 from '../../../images/casestudy/Companys/5.png';
import compnay6 from '../../../images/casestudy/Companys/6.png';
import compnay7 from '../../../images/casestudy/Companys/7.png';

function Companies() {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 15000,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    lazyLoad: true,
  };

  return (
    <>
      <h1 className="our-client clientHeading d-flex justify-content-center">Our Clients</h1>
      <div className="p-4 img-bg logo">
        <Slider className="SliderbackgroundJitendra" ref={sliderRef} {...settings}>
          <div className="ClientsScroller">
            <img src={compnay1} alt="Company 1" className="d-block" loading="lazy" />
          </div>
          <div className="ClientsScroller">
            <img src={compnay2} alt="Company 2" className="d-block" loading="lazy" />
          </div>
          <div className="ClientsScroller">
            <img src={compnay3} alt="Company 3" className="d-block" loading="lazy" />
          </div>
          <div className="ClientsScroller">
            <img src={compnay4} alt="Company 4" className="d-block" loading="lazy" />
          </div>
          <div className="ClientsScroller">
            <img src={compnay5} alt="Company 5" className="d-block" loading="lazy" />
          </div>
          <div className="ClientsScroller">
            <img src={compnay6} alt="Company 6" className="d-block" loading="lazy" />
          </div>
          <div className="ClientsScroller">
            <img src={compnay7} alt="Company 7" className="d-block" loading="lazy" />
          </div>
        </Slider>
      </div>
    </>
  );
}

export default Companies;
