import Logo from "../../../images/logo/logo_lakebrains.webp";
import Bannnerimage from "../../../images/casestudy/browserext.webp";

const data = [
  {
    slugurl: "/browser-control-through-voice-commands",
    data: [
      {
        Banner: [
          {
            sector:"Productivity/Technology",
            headingtext: "Browser Control Through Voice Commands",
            headingpara: `Browser Control Through Voice Commands is a free chrome extension that allows you to control your chrome browser through voice commands.`,
            image: Bannnerimage,
          },
        ],
        heading:
          "Want to control your browser through voice commands?",
        casestudy: {
          Problem: `Existing voice assistant extensions for web browsers face limitations such as not working on special Chrome pages, lacking functionality for managing bookmarks, history, and cookies, capturing personal data, and charging money for additional features.`,
          challenge: [
            {
              heading: "How to use it?",
              challengeli: [
                {First:"Visit the chrome web store. Search 'Browser Control Through Voice Commands'"},
                {First:"Select Add to Chrome."},
                {First:"Now pin the extension in the browser."},
                {First:"Click on the extension icon."},
                {First:"Give commands and enjoy it."},
              ],
            },
            {
              heading:
                "Commands, which are accepted in extension ",
              challengeli: [
                {First:"Open new tab"},
                {First:"Close this tab"},
                {First:"Open next tab"},
                {First:"Open previous tab"},
                {First:"Open new window"},
                {First:"Close this window"},
                {First:"Close other tabs"},
                {First:"Move this tab to next tab"},
                {First:"Move this tab to previous tab"},
                {First:"Refresh current page or Refresh this page or Refresh"},
                {First:"Scroll down"},
                {First:"Scroll up"},
                {First:"Scroll top"},
                {First:"Scroll bottom"},
                {First:"Add bookmark"},
                {First:"Remove bookmark"},
                {First:"Delete history"},
                {First:"Delete cookies"},
                {First:'go to "any website"'},
                {First:'play "any youtube video title"'},
              ],
            },
            {
              heading: 'The Challenge:-',
              challengeli: [
                {First:"They are not allowed to work on special chrome:// pages"},
                {First:"They dont have functionality to add/remove bookmark, delete history and delete cookies."},
                {First:"Some extensions captures user's personal data."},
                {First:"These Extensions charge money for special features"},
              ],
            },
            {
              heading: 'The Approach',
              challengeli: [
               {First:"This extension can work on special chrome. It has accessabilty to bookmaks, history and cookies.Quick and easy setup process. Easy to use and user friendly.It did not capture the user's personal data. It is free to use."}
              ],
            },
            {
              heading: "Impact",
              challengeli: [ {First:`The "Browser Control Through Voice Commands" extension resolves limitations of existing voice assistant extensions by enabling functionality on special Chrome pages, providing access to bookmarks, history, and cookies, ensuring user privacy, and offering a free and user-friendly experience. This leads to an enhanced browsing experience, increased user control over browsing data, and broader accessibility for users seeking voice control capabilities.`},],
            },
          ],
        },

        companyinfo: {
          logourl: Logo,
          about: "About ",
          aboutpara:
            "The 'Browser Control Through Voice Commands' is a product of Lakebrains Technologies that allows users to control their browser through voice commands. This extension is user-friendly, making it a great option for those who want to use their voices to control their browsing experience.",
          companysector: [
            {
              first: "INDUSTRY:",
              secound: "Productivity",
            },
            {
              first: "HQ LOCATION:",
              secound: "Udaipur, Rajasthan",
            },
            {
              name: "Get Extension",
              url: "https://github.com/lakebrains-ind/browser-control-through-voice",
            },
          ],
        },
        customer: [
          // {
          //   feddbackpara:
          //     "We were building a digital marketing strategy from scratch, and from the start we kept a close eye on lead quality through our CRM system. The particular advantage that LinkedIn gives us is the quality of the leads that it generates and we’re able to see how that flows through into ROI. That’s why LinkedIn is one of the channels we’re focusing on going forward.",
          //   image:
          //     "https://www.interactpro.ai/hubfs/Jack%20Sands%20Pic%20(1)%201.svg",
          //   name: "jack sands",
          //   position: "owner",
          // },
        ],
      },
    ],
  },
];

export default data;
