import React from "react";
import Workforce_Participation1 from "../../../../images/Outing/LB_Workforce_Participation (13).jpeg";
import Workforce_Participation2 from "../../../../images/Outing/LB_Workforce_Participation (10).jpeg";
import Workforce_Participation3 from "../../../../images/Outing/LB_Workforce_ParticipationCopy(4).jpg";
import Outing1 from "../../../../images/teams/outing1.webp";
import Outing2 from "../../../../images/teams/outing2.webp";
import Outing3 from "../../../../images/teams/outing3.webp";
import './Outing.css'

const Outings = () => {
  return (
    <>
      <div className="container">
        <div className="homepage-heading">
          <h2>Workforce Participation</h2>
          <p>
            Team outings provide some well-deserved respite for those stressed
            brains and create opportunities for your team to let off steam
            together, which recharges the workforce. This in turn helps to
            increase motivation and leads to increased productivity.
          </p>
        </div>
        <div className="row outing-colum">
          <div className="col-md-4">
            <div
              data-aos="zoom-in-up"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out-cubic"
              className="col-md-4 col-sm-6 col-12"
            >
              <img src={Workforce_Participation1} className="imag0" alt="outing" loading="lazy"></img>
            </div>
          </div>
          <div className="col-md-4">
            <div
              data-aos="zoom-in-up"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out-cubic"
              className="col-md-4 col-sm-6 col-12"
            >
              <img src={Workforce_Participation2} className="imag0" alt="outing" loading="lazy"></img>
            </div>
          </div>
          <div className="col-md-4">
            <div
              data-aos="zoom-in-up"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out-cubic"
              className="col-md-4 col-sm-6 col-12"
            >
              <img src={Workforce_Participation3} className="imag0" alt="outing" loading="lazy"></img>
            </div>
          </div>
        </div>
        <div className="row outing-colum">
          <div className="col-md-4">
            <div
              data-aos="zoom-in-up"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out-cubic"
              className="col-md-4 col-sm-6 col-12"
            >
              <img src={Outing1} className="imag0" alt="outing" loading="lazy"></img>
            </div>
          </div>
          <div className="col-md-4">
            <div
              data-aos="zoom-in-up"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out-cubic"
              className="col-md-4 col-sm-6 col-12"
            >
              <img src={Outing2} className="imag0" alt="outing" loading="lazy"></img>
            </div>
          </div>
          <div className="col-md-4">
            <div
              data-aos="zoom-in-up"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out-cubic"
              className="col-md-4 col-sm-6 col-12"
            >
              <img src={Outing3} className="imag0" alt="outing" loading="lazy"></img>
            </div>
          </div>
          <div className="look-more mt-2 p-2">
            <a href="/Outing/"> In the end, we don't remember the days, we remember the moments! Take a closer look ...{" "}
              <i className="fas fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Outings;
