import React from "react";
import headerImage from "../../../../images/SubPages/NLP/headerGifImage.webp";
import BehaviorImage from "../../../../images/SubPages/NLP/Behavior.webp";
import demoImage from "../../../../images/SubPages/NLP/demoImage.webp";
// import { Carousel } from 'bootstrap'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Behavior1 from "../../../../images/SubPages/NLP/Behavior1.svg";
import Sentiments1 from "../../../../images/SubPages/NLP/Sentiments1.svg";
import Audio1 from "../../../../images/SubPages/NLP/Audio1.svg";
import Keyword1 from "../../../../images/SubPages/NLP/KeyWord1.svg";
import Topic1 from "../../../../images/SubPages/NLP/Topic1.svg";
import Chatbots1 from "../../../../images/SubPages/NLP/Chatbots1.svg";
import Behaviour2 from "../../../../images/SubPages/NLP/Behaviour2.svg";
import "./Advantages.css";


const AdvantagesOfServices = ({ data, title, subTitle,work }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1204, min: 800 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    smalltablet: {
      breakpoint: { max: 800, min: 550 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className="NLP-Wrapper  container-fluid ">
      <div className="container">
        {/* Behavior Analysis Start fr1om here */}
        <div className="npl-behavior-00">
          <div className="homepage-heading">
            <div className="text-center">
              <h4>{work}</h4>
              <h2> {title}</h2>
              <p>{subTitle}</p>
            </div>
          </div>
          <div className="">
            <div>
              <div className=" row  mx-0 justify-content-center main-container">
                {data.map((items, index) => (
                  <div className="col-lg-6 mt-3" key={index}>
                    <div className="NPL-contanier-00 p-4">
                      <div className="d-flex align-items-center px-4 NPL-contanier-00-01 ">
                        <div className="text-left lakeH-NLP-behavior-img singleTech">
                          <img src={items.image} alt={items.alt} width={65} />
                        </div>
                        <div>
                          <h4 className="px-3  font-w-600 font-s-24 lakeH-NLP-behavior-title mb-3 ">
                            {items.title}
                          </h4>
                          <div className=" ml-3 lakeH-NLP-behavior-title-border"></div>
                        </div>
                      </div>

                      <div className="px-3 pt-2 text-greypara  lakeH-NLP-behavior-items-description ">
                        <p className="mb-2">{items.description}</p>

                      </div>
                      {/* {index===1 && (<>
                      <div className='d-flex justify-content-start services-read-more-00-01'><a href={items.Link2}><button className="btn blog-BlogReadMore-btn-01">{items.LinkText2}</button></a></div>
                      <div  className='d-flex justify-content-end  services-read-more-00-02'><a href={items.Link}><button className="btn blog-BlogReadMore-btn-01">{items.LinkText}</button></a></div>
                      </>
                      )}
                     
                     {index!=1 && <div className='d-flex justify-content-end w-100 services-read-more-00-01'><a href={items.Link}><button className="btn blog-BlogReadMore-btn-01">{items.LinkText}</button></a></div>}
                     */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvantagesOfServices;
