import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SuperSEO } from "react-super-seo";
// import "./NotesCapture.css";

const NotesCapture = () => {
  const [response, setResponse] = useState([]);
  const [transcript, setTranscript] = useState("");
  const [captchaStatus, setCaptchaStatus] = useState(false);

  const getTranscriptResponse = () => {
    document.querySelector("#loader_empthy_chatGpt").style.display = "flex";
    
    console.log(transcript);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");

    var raw = transcript;
    console.log(raw, "\nraw");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    // fetch("http://localhost:8080/notes/get-notes", requestOptions)
    fetch(
      // "http://127.0.0.1:5001/lakerains-websites/us-central1/summarizer",
      // 'https://asia-south1-lakerains-websites.cloudfunctions.net/summarizer',
      requestOptions
    )
      .then((response) => {
        console.log("hello");
        return response.json();
      })
      .then((result) => {
        console.log(result); //object
        const dataArray = Object.entries(result);//here entryis in array
        console.log("dataArray", dataArray);
        const data = dataArray[0];
        setResponse(data[1]);
        console.log("data", data[1]);
        document.querySelector("#loader_empthy_chatGpt").style.display = "none";
      })
      .catch((error) => console.log("error", error));
  };

  let captcha;
  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captcha = ref);
    }
  };
  function onChange(value) {
    console.log("SAS", value);
    setCaptchaStatus(!captchaStatus);
    if (value !== null && transcript !== "") {
      document.querySelector("#uploadBtn").style.display = "block";
    } else if (value === null || transcript === "") {
      document.querySelector("#uploadBtn").style.display = "none";
    }
  }
  // textAreaChange
  const textAreaChange = (e) => {
    setTranscript(e.target.value);
    console.log(e.target.value);
    if (captchaStatus && e.target.value !== "") {
      document.querySelector("#uploadBtn").style.display = "block";
    } else if (!captchaStatus || e.target.value === "") {
      document.querySelector("#uploadBtn").style.display = "none";
    }
  };
  return (
    <>
      <div>
      <SuperSEO
        title="NotesCapture - React Component for Transcribing and Summarizing Notes"
        description="NotesCapture is a React component that allows users to paste their transcripts and analyze them. It uses the ReCAPTCHA library for security and the react-super-seo package for SEO optimization. The component provides a loading screen while the analysis is in progress and displays a summary of the transcript once the analysis is complete."
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: "https://www.youtube.com/watch?v=wZ02FUjCYHw&t=3s&ab_channel=LakeBrainsTechnologies",
            summaryCardImageAlt: "video",
            summaryCardSiteUsername: "justinmahar",
          },
        }}
      />
        <div
          className="background-img"
          style={{ padding: "100px 0px", minHeight: "45vh" }}
        >
          <div id="#loading" className="container">
            <div className="" id="loader_empthy_chatGpt">
              <div className="wrapper">
                <div className="loader">
                  <div className="dot"></div>
                </div>
                <div className="loader">
                  <div className="dot"></div>
                </div>
                <div className="loader">
                  <div className="dot"></div>
                </div>
                <div className="loader">
                  <div className="dot"></div>
                </div>
                <div className="loader">
                  <div className="dot"></div>
                </div>
                <div className="loader">
                  <div className="dot"></div>
                </div>
              </div>
              <div className="text">Please wait</div>
            </div>
            <div className="">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="mb-4 ">
                  <div className="d-flex justify-content-center align-items-center">
                    <h4 className="mb-2">
                      Paste your Transcript Here and Analyze:
                    </h4>
                    <button
                      id="uploadBtn"
                      type="button"
                      className="btn ml-3 mb-4"
                      onClick={getTranscriptResponse}
                      style={{ display: "none" }}
                    >
                      Analyze
                    </button>
                  </div>
                  <div className="">
                    {/* <input className="w-75 mx-auto" styles="height: 500px;" type="text" /> */}
                    <textarea
                      name="transcript"
                      id="transcript"
                      cols="50"
                      rows="10"
                      onChange={textAreaChange}
                    ></textarea>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ReCAPTCHA
                    ref={(r) => setCaptchaRef(r)}
                    sitekey="6LeSJQslAAAAANaj5ZW41ZjEzw_jomKjYHZbuhg6"
                    onChange={onChange}
                    theme="light"
                  />
                </div>
                {/* ) : null} */}
              </div>
            </div>
            <div className="">
              {response.summary_text && (
                <>
                  <div className="w-100 d-flex justify-content-center flex-column align-items-center font-dark mt-3">
                    <div className="w-50 font-dark border border-2 p-3 rounded">
                      <h4>Your Summary:</h4>
                      <br />
                      {console.log("response==>", response.summary_text)}
                      {/* {console.log("response==>",JSON.stringify(response[0]))} */}
                      <p>{response.summary_text}</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotesCapture;
