import React from "react";

const Popup2 = (props) => {
  return props.trigger ? (
    <div className="SaveHome-Meeto-Corosulpopup d-flex justify-content-center align-items-center">
      <div className="SaveHome-">
        <div>
          <div className="popup2-form">
            <div className="banner-content-wrap ">
              <div className="banner_content-subscription ">
                <form>
                  <h2>Subscription Form</h2>
                  <h6 className="mt-3">Name</h6>
                  <input type="text" placeholder="Your Name" required />
                  <h6 className="mt-3">E-Mail</h6>

                  <input type="Email" placeholder="Your Email" required />
                  <h6 className="mt-3">Message</h6>
                  <textarea
                    rows="4"
                    cols="50"
                    name="comment"
                    form="usrform"
                    className="w-100"
                    placeholder="Enter text here..."
                  ></textarea>
                  <div
                    className="subscription-close-btn"
                    onClick={() => props.onClick(false)}
                  >
                    <button className="btn-primary btn-product header-blogs-btn btn position-relative">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    null
  );
};

export default Popup2;
