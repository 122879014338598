import React from "react";

import data from "./FloomyWebData";
import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/FloomyLogo.svg";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const FloomyWeb = () => {
    return (
        <>
            <SuperSEO
                title="Floomy - Lakebrains"
                description="Unleashing creativity and productivity through seamless project management solutions."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "Floomy",
                        summaryCardSiteUsername: "Floomy",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default FloomyWeb;
