import Bannnerimage from "../../../../images/logo/logo_lakebrains.webp";

const data = [
  {
    slugurl: "/NoteTakingExtension",
    data: [
      {
        Banner: [
          {
            sector: "Recruitment/ Technology",
            headingtext: "Note Taking Extension",
            headingpara:
              "Existing Chrome extensions for note-taking efficiently managing and organising notes within the extension  and providing a seamless user experience for adding, editing, and accessing notes.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Organise thoughts with intuitive note extension.",
        casestudy: {
          Problem:
            `Introducing  note-taking extension, a versatile tool designed to streamline your digital note-taking experience. With intuitive features and seamless integration into your browser, this extension empowers you to capture ideas, research findings, and important information effortlessly. Enjoy the convenience of organising your notes efficiently.`,
          challenge: [
            {
              heading: "Why To Use:",
              challengeli: [
                { First: "Efficient Organisation: Utilise super headings and nested headings to structure notes hierarchically, simplifying navigation through different topics and subtopics." },
                { First: "Flexibility in Headings Management: Empower users to create, update, and delete headings as required, ensuring a customizable and adaptable note-taking structure." },
                { First: "Enhanced Input with Melodies: Incorporate melodies to effortlessly input essential data into notes, enhancing accessibility and engagement." },
                { First: "Incorporation of Comments: Add comments to provide context and additional information, enriching the depth of notes and facilitating understanding." },
                { First: "Integration of Web Navigation: Navigate to web pages via page links directly within the notes, enabling seamless access to external resources for enhanced referencing and research." },
              ],
            },
            {
              heading: "How To Use It:-",
              challengeli: [
                { First: "Install the Note Taking Chrome Extension." },
                { First: "Pin the extension and click on it to open the extension." },
                { First: "Authenticate yourself using google authentication." },
                { First: "Click on the plus button to create melodies." },
                { First: "Now you are ready to use the extension." },
                // { First: "Turn on/off automatic recording for zoom phone as well as zoom meeting" },
                // { First: "Publish app on zoom marketplace." },
              ],
            },
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Handling of dynamic UI and performance issues that vary site to site." },
                { First: "User is unable to differentiate between super headings and headings." },
                { First: "Synchronising extension among multiple tabs to track changes to data." },
                { First: "Synchronisation of extension with cloud deployed server & database." },
                { First: "Implementing a seamless drag-and-drop functionality to transfer selected text from websites into extension for notes generation." },
                // { First: "Capturing Transcripts: Extract closed captions or transcripts from Zoom meetings in real-time and send them securely to the backend." },
                // { First: "Turn on/off automatic recording for zoom phone as well as zoom meeting" },
              ],
            },
            {
              heading: "The Approaches:",
              challengeli: [
                { First: "Implementing dynamic UI elements for adding comments and page URLs to notes." },
                { First: "Creating a seamless user interface for adding, editing, and accessing notes." },
                { First: "Created a proper hierarchy with symbols which allows to identify the difference between super headings and headings." },
                { First: "Integrating with Chrome storage for efficient data management and synchronisation." },
                { First: "Utilising the window.getSelection() function to capture highlighted text accurately." },
                { First: "Implemented using event listeners to capture  selected text and enable drag-and-drop functionality for seamless note-taking into our extension." },
                // { First: "Transcription Handling: Implement mechanisms to handle meeting recordings without scraping, ensuring compliance with data privacy and security regulations." },
                // { First: "Turning on/off automatic recording on integration time by using zoom’s api and also developed a generic API to turn on off automatic recording" },
                // { First: "Resolved many marketplace problems like updating documentation, resolving OWASP errors, removing unused scopes etc." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: `The implementation of the Note Taking extension addresses the challenges faced by existing note-taking extensions.The extension accurately captures highlighted text within the browser, providing users with a convenient way to save important information. The dynamic UI elements and smooth integration with Chrome storage enhance the user experience, allowing users to customise and organise their notes effortlessly. Overall, the extension promotes efficient note-taking and improves productivity by providing users with a reliable tool for managing their digital notes.` },],
            },
          ],
        },

        companyinfo: {
          logourl:
            "yasmin",
          videoUrl:
            "https://www.youtube.com/embed/mjzSlWPiykM",

          about: "About ",
          aboutpara:
            "note-taking:-The Note Taking Extension enhances Chrome users' productivity by efficiently managing and organizing notes within the browser. It ensures a seamless experience for adding, editing, and accessing notes.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Note Taking",
            },
            {
              first: "INDUSTRY:",
              secound: "Recruitment/ Technology",
            },
            // {
            //   first: "LOCATION:",
            //   secound: "",
            // },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
