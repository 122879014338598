import React from "react";
import "./home.css";
import HomeImage from "../../../images/chrome-extension-develpment/home.svg";
import { PopupButton } from "react-calendly";

function home() {
  return (

    <div className=" d-flex p-0 container">
      <div className="row"></div>
      <div className="home-main">
        <div className="home-text col-lg-7 col-md-12 ">
          <h1 className="home-heading">
            Chrome Extension <br/>  Plugin Development Company{" "}
          </h1>
          <div className="line"></div>
          <span className="home-p">
            Our Chrome Extension Development Company is dedicated to helping
            businesses and developers harness. We develop Chrome addons and
            plugins, including the AI-powered ChatGPT Extension, for an enhanced
            browsing experience. Optimize your journey with our top-notch
            solutions.
          </span>
          <div className="home-btns">
            <button
              className="btn-1 btn-link Hbtn-1"
              onClick={() => {
                window.location.href = "https://lakebrains.com/casestudy/";
              }}
            >
              Case studies
            </button>
            {/* <button type="button" class="">
              Schedule Meeting
            </button> */}
            <PopupButton
              className="btn-2 btn-link Hbtn-2"
              url="https://calendly.com/khushbu-jpao/30min?back=1&month=2024-05"
              rootElement={document.getElementById("root")}
              text="Schedule Meeting"
            />
          </div>
        </div>
        <div className="home-img col-lg-5 col-md-12">
          <img className="img-fluid HI" alt="CE" src={HomeImage} />
        </div>
      </div>
    </div>
  );
}

export default home;
