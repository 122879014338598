import React, { useEffect } from "react";

import AOS from "aos";

AOS.init();

const Carriers = () => {
  useEffect(() => {
    AOS.init();
  }, []);
};

const Achive = ({ archive, heading, subHeading, description }) => {
  return (
    <div className="bg-colour homepage-heading">
      <div className="mb-3">
        <div className="container">
          <h4>{heading}</h4>
          <h2>{subHeading}</h2>
          <p>{description}</p>
          <div className="row justify-content-center">
            {archive.map((item, index) => (
              <div className="col-lg-2 col-md-4 col-6 " key={index}>
                <div className="archive-teams flex text-center">
                  <img className='border-image' src={item.image} alt={item.alt} loading="lazy"/>
                  <h3>{item.heading}</h3>
                  <p>{item.para}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Achive;
