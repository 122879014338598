import React from "react";
import Casebanner from "./CaseBanner/Casebanner";
import data from "./CaseStudyYoutubeData";
import squire from "../../../images/casestudy/squireimg.png"
import { SuperSEO } from "react-super-seo";

const CaseStudyYoutube = () => {
    return (
        <>
            <SuperSEO
                title="Youtube Extra Ad - Lakebrains"
                description="Youtube Extra ad is a chrome extension which can show custom ads on youtube after a given time. After playing the ad for 30 sec it will resume the original video."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { squire },
                        summaryCardImageAlt: "Youtube",
                        summaryCardSiteUsername: "Youtube",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default CaseStudyYoutube;
