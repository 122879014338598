import React from "react";
// This is Frontend images ;
import next from "../../../../images/OurtechStack/nextjs.webp";
import react from "../../../../images/OurtechStack/react.webp";
import express from "../../../../images/OurtechStack/express.svg";
import html from "../../../../images/OurtechStack/html5.svg";
import css from "../../../../images/OurtechStack/css3.svg";
import bootstrap from "../../../../images/OurtechStack/bootstrap.svg";
import less from "../../../../images/OurtechStack/less.svg";
import sass from "../../../../images/OurtechStack/sass.svg";
import extension from "../../../../images/OurtechStack/extension.svg";
import javascript from "../../../../images/OurtechStack/javascript.svg";
import jquery from "../../../../images/OurtechStack/jquery.svg";
import sails from "../../../../images/OurtechStack/sails.webp";

// This is  backend images
import lambda from "../../../../images/OurtechStack/lambda.webp";
import mysql from "../../../../images/OurtechStack/mysql.svg";
import mongodb from "../../../../images/OurtechStack/mongodb.svg";
import api from "../../../../images/OurtechStack/api.webp";
import django from "../../../../images/OurtechStack/django.svg";
import flask from "../../../../images/OurtechStack/flask.webp";
import nodejs from "../../../../images/OurtechStack/nodejs.svg";
import sagemaker from "../../../../images/OurtechStack/sagemaker.webp";
import ecs from "../../../../images/OurtechStack/ecs.webp";
import ec2 from "../../../../images/OurtechStack/ec2.svg";
import s3 from "../../../../images/OurtechStack/s3.svg";
import sns from "../../../../images/OurtechStack/sns.webp";
import apigateway from "../../../../images/OurtechStack/apigateway.webp";
import eks from "../../../../images/OurtechStack/eks.webp";
import behavioranalytics from "../../../../images/OurtechStack/behavioranalytics.webp";
import Sentiment from "../../../../images/OurtechStack/Sentiment.webp";
import keywords from "../../../../images/OurtechStack/keywords.webp";
import topicmodeling from "../../../../images/OurtechStack/topicmodeling.webp";
import audiototext from "../../../../images/OurtechStack/audiototext.webp";
import chatbot from "../../../../images/OurtechStack/chatbot.webp";
import tensorflow from "../../../../images/OurtechStack/tensorflow.webp";
import pytorch from "../../../../images/OurtechStack/pytorch.webp";

//css
import "./ourtechstack.css";

const Ourtechstack = () => {
  const FrontendData = [
    {
      logo: next,
      name: "Next.js",
      alt: "Next.js",
      link: "https://nextjs.org/"
    },
    {
      logo: react,
      name: "React.js",
      alt: "React.js",
      link: "https://reactjs.org/",
    },
    {
      logo: javascript,
      name: "Javascript",
      alt: "Javascript",
      link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    },
    {
      logo: extension,
      name: "Extension",
      alt: "Extension",
      link: "https://chrome.google.com/webstore/category/extensions",
    },
    {
      logo: jquery,
      name: "Jquery",
      alt: "Jquery",
      link: "https://jquery.com/",
    },
    {
      logo: html,
      name: "Html5",
      alt: "Html5",
      link: "https://en.wikipedia.org/wiki/HTML",
    },
    {
      logo: css,
      name: "Css3",
      alt: "Css3",
      link: "https://en.wikipedia.org/wiki/CSS",
    },
    {
      logo: bootstrap,
      name: "Bootstrap",
      alt: "Bootstrap",
      link: "https://getbootstrap.com/",
    },
    {
      logo: less,
      name: "Less",
      alt: "Less",
      link: "https://lesscss.org/"
    },
    {
      logo: sass,
      name: "Sass",
      alt: "Sass",
      link: "https://sass-lang.com/",
    },
  ];
  const BackendData = [
    {
      logo: nodejs,
      name: "Node.js",
      alt: "Node.js",
      link: "https://nodejs.org/"
    },
    {
      logo: express,
      name: "Express.js",
      alt: "Express.js",
      link: "https://expressjs.com/",
    },
    {
      logo: flask,
      name: "Flask",
      alt: "Flask",
      link: "https://flask.palletsprojects.com/"
    },
    {
      logo: django,
      name: "Django",
      alt: "Django",
      link: "https://www.djangoproject.com/"
    },
    {
      logo: api,
      name: "Fast API",
      alt: "Fast API",
      link: "https://fastapi.tiangolo.com/"
    },
    {
      logo: sails,
      name: "Sails.js",
      alt: "Sails.js",
      link: "https://sailsjs.com/"
    },
    {
      logo: mongodb,
      name: "MongoDB",
      alt: "MongoDB",
      link: "https://www.mongodb.com/home"
    },
    {
      logo: mysql,
      name: "MySQL",
      alt: "MySQL",
      link: "https://www.mysql.com/"
    },
    // {
    //     logo:apiintegeration,
    //     name:'API Integration'
    // },
    // {
    //     logo:apidevelopment,
    //     name:'API Development'
    // },
  ];
  const NLPData = [
    {
      logo: behavioranalytics,
      name: "Behavior Analysis",
      alt: "Behavior Analysis",
      link: "https://www.verywellmind.com/what-is-behavior-analysis-2794865",
    },
    {
      logo: Sentiment,
      name: "Sentiment Analysis",
      alt: "Sentiment Analysis",
      link: "https://en.wikipedia.org/wiki/Sentiment_analysis",
    },
    {
      logo: keywords,
      name: "Keyword Extraction",
      alt: "Keyword Extraction",
      link: "https://en.wikipedia.org/wiki/Keyword_extraction",
    },
    {
      logo: topicmodeling,
      name: "Topic Modeling",
      alt: "Topic Modeling",
      link: "https://en.wikipedia.org/wiki/Topic_model",
    },
    {
      logo: audiototext,
      name: "Audio To Text",
      alt: "Audio To Text",
      link: "https://en.wikipedia.org/wiki/Speech_recognition",
    },
    {
      logo: chatbot,
      name: "Chatbots",
      alt: "Chatbots",
      link: "https://www.oracle.com/in/chatbots/what-is-a-chatbot/",
    },
    {
      logo: tensorflow,
      name: "Tensorflow",
      alt: "Tensorflow",
      link: "https://www.tensorflow.org/",
    },
    {
      logo: pytorch,
      name: "PyTorch",
      alt: "PyTorch",
      link: "https://pytorch.org/",
    },
  ];
  const AWSData = [
    {
      logo: lambda,
      name: "Lambda",
      alt: "Lambda",
      link: "https://aws.amazon.com/lambda/",
    },
    {
      logo: ec2,
      name: "EC2",
      alt: "EC2",
      link: "https://aws.amazon.com/ec2/",
    },
    {
      logo: ecs,
      name: "ECS",
      alt: "ECS",
      link: "https://aws.amazon.com/ecs/",
    },
    {
      logo: s3,
      name: "S3",
      alt: "S3",
      link: "https://aws.amazon.com/s3/",
    },
    {
      logo: sns,
      name: "SNS",
      alt: "SNS",
      link: "https://aws.amazon.com/sns/",
    },
    {
      logo: sagemaker,
      name: "Sagemaker",
      alt: "Sagemaker",
      link: "https://docs.aws.amazon.com/sagemaker/",
    },
    // {
    //     logo:eps,
    //     name:'EPS'
    // },
    {
      logo: apigateway,
      name: "API Gateway",
      alt: "API Gateway",
      link: "https://aws.amazon.com/api-gateway/"
    },
    {
      logo: eks,
      name: "EKS",
      alt: "EKS",
      link: "https://aws.amazon.com/eks/"
    },
  ];
  return (
    <div className="example-classname contianer-fluid">
      <div className="ourtechstack-wrapper container px-0 ">
        <div className="homepage-heading ">
          <h4>Key Strength</h4>
          <h2>Our Technical Skills </h2>
          <p>Technologies / Languages / Frameworks</p>
        </div>
        <div className=" d-flex flex-column-reverse">
          <div className="container-fluid row mx-0  px-0 ourtechstack-sub-wrapper">
            {/* NLP */}
            <div className="col-lg-3 col-md-6 ots-tech-items-endborder-000 px-0 ">
              <div className="px-md-0 px-3  text-center pb-4 row justify-content-around d-flex  ots-tech-items-wrapper mx-0">
                {/* <div className='ots-tech-items-endborder'></div> */}
                {NLPData.map((data, index) => (
                  <div className="ots-tech-items col-5" key={index}>
                    <a href={data.link}>
                      <div className="ots-tech-items-01">
                        <img src={data.logo} alt={data.alt} />
                      </div>
                      <div className="pt-2 w-100">{data.name}</div>
                    </a>
                  </div>
                ))}
              </div>
              <div className=" text-center ots-heading">NLP</div>
            </div>

            {/* Frontend*/}
            <div className="col-lg-3 col-md-6 ots-tech-items-endborder-000 px-0 ">
              <div className="px-md-0 px-3 text-center pb-4 row justify-content-around d-flex  ots-tech-items-wrapper ots-tech-items-wrapper2 border-d-xaxis flex-direction- mx-0">
                {FrontendData.map((data, index) => (
                  <div className="ots-tech-items col-5" key={index}>
                    <a href={data.link}>
                      <div className="ots-tech-items-01">
                        <img src={data.logo} alt={data.alt} />
                      </div>
                      <div className="pt-2 w-100">{data.name}</div>
                    </a>
                  </div>
                ))}
              </div>
              <div className=" text-center ots-heading">Front End</div>
            </div>

            {/* Backend */}
            <div className="col-lg-3 col-md-6 ots-tech-items-endborder-000 px-0">
              <div className="px-md-0 px-3 text-center pb-4 row justify-content-around d-flex  ots-tech-items-wrapper border-d-right mx-0">
                {BackendData.map((data, index) => (
                  <div className="ots-tech-items col-5" key={index}>
                    <a href={data.link}>
                      <div className="ots-tech-items-01">
                        <img src={data.logo} alt={data.alt} />
                      </div>
                      <div className="pt-2 w-100">{data.name}</div>
                    </a>
                  </div>
                ))}
              </div>
              <div className=" text-center ots-heading">Back End</div>
            </div>

            {/* AWS Cloud*/}
            <div className="col-lg-3 col-md-6 ots-tech-items-endborder-000 px-0">
              <div className="px-md-0 px-3 text-center pb-4 row justify-content-around d-flex  ots-tech-items-wrapper mx-0">
                {AWSData.map((data, index) => (
                  <div className="ots-tech-items col-5" key={index}>
                    <a href={data.link}>
                      <div className="ots-tech-items-01">
                        <img src={data.logo} alt={data.alt} />
                      </div>
                      <div className="pt-2 w-100">{data.name}</div>
                    </a>
                  </div>
                ))}
              </div>
              <div className=" text-center ots-heading">AWS Cloud</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ourtechstack;
