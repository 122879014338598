import React from "react";
import ServiceIndustries from "../../../Comman/ServiceComponents/SaasProductService/ServiceIndustries";
// import CEOMessage from "../../../Comman/CeoMassage";


import Vector3 from "../../../../images/chrome-extension-develpment/vector3.svg";
import Vector7 from "../../../../images/chrome-extension-develpment/vector7.svg";
import E_commerce from "../../../../images/chrome-extension-develpment/e-commerce.png";
import saas from "../../../../images/chrome-extension-develpment/saas.png";
import mining from "../../../../images/chrome-extension-develpment/mining.png";
import recruitment from "../../../../images/chrome-extension-develpment/recruitment.png";
import CaseStudyBanner from "../../../Comman/CaseStudyBanner/CaseStudyBanner";
import CaseStudyTopCard from "../../../Comman/CaseStudyBanner/CaseStudyTopCard";

import BCG_logo from "../../../../images/CaseStudiesImagesLogo/BCG_logo.png";
import LinkReach from "../../../../images/CaseStudiesImagesLogo/LinkReach.jpeg";
import LinkNavigator from "../../../../images/CaseStudiesImagesLogo/LinkNavigator.png";
import logo_lakebrains from "../../../../images/logo/logo_lakebrains.webp";


const cashStuduCards = [
  {
    img: logo_lakebrains,
    title: "Acuity",
    content: "Generic solution to scrap or generate PDFof any URL",
    URL: "/casestudy/Acuity/",
    category: "Automation",
  },
  {
    img: BCG_logo,
    title: "BCG CaseStudy",
    content: "This Implementation is capable of scraping jobs data of the given company name from glassdoor.",
    URL: "/casestudy/BCG_CaseStudy/",
    category: "Automation",
  },
  {
    img: LinkNavigator,
    title: "Linkedin Navigator",
    content: "Get better leads from linkedin sales navigator",
    URL: "/casestudy/LinkedinSalesNavigator/",
    category: "Automation",
  },
  {
    img: LinkReach,
    title: "Linkedin Reachout",
    content: "Automate the process to reachout to people and more focus on meetings",
    URL: "/casestudy/LinkedinReachout/",
    category: "Automation",
  },
  
];

const industryData = [
  {
    img: Vector3,
    title: "Health Care ",
  },
  {
    img: Vector7,
    title: "Finance",
  },
  {
    img: E_commerce,
    title: "E-commerce",
  },
  {
    img: saas,
    title: "SaaS",
  },
  {
    img: mining,
    title: "Mining",
  },
  {
    img: recruitment,
    title: "Recruitmemt",
  },
];


export default function WebAutoCaseStudyPage() {
  return (
    <>
      <CaseStudyBanner subHeading="Case Studies" heading="Web Automation" description="Get to know more about the Web Automation." />

      <CaseStudyTopCard cashStuduCards={cashStuduCards} subHeading="A Transformative Case Study" heading="Web Automation Case Studies" description="Analyses of web automation tools and their practical applications."  />

      <ServiceIndustries industryData={industryData} subHeading="Industries Transformed By Cloud" heading="Industries" description="Our experts will work with you to understand and solve specific industry problems using SaaS." />

    </>
  );
}
