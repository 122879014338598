import Bannnerimage from "../../../../images/casestudy/floomy.jpg";

const data = [
  {
    slugurl: "/Floomy",
    data: [
      {
        Banner: [
          {
            sector:"Productivity/ Technology",
            headingtext: "Floomy",
            headingpara:
              " AI Meeting Assistant Tool to Generate Minutes Of Meeting for Google Meet.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Maximizing Productivity of your Meetings.",
        casestudy: {
          Problem:
            `Generates AI- Powered notes and insights, so you can focus on the conversation without worrying about taking notes.`,
          challenge: [
            {
              heading: "How it works?",
              challengeli: [
                {First:"Floomy starts/stops automatically upon joining meetings, no manual interaction needed."},
                {First:"You can check the real time transcription whenever required and take snapshots"},
                {First:"You will be sent an email containing MOM,transcriptions, and snapshots."},
              ],
            },
            {
              heading: "The Challenges:- ",
              challengeli: [
                { First: "Capture captions from google meet in real time without any user interaction." },
                { First: "Capture screenshots of the current page." },
                { First: "Algorithm for MOM, Notes and Action Items." },
                { First: "Update transcript in a better UI in real time so that the user can see that transcript." },
                { First: "Manual notes feature" },
              ],
            },
            {
              heading: "Solutions:-",
              challengeli: [
                { First: "Capturing google meet captions from google meet without any user interaction and without any UI changes of google meet by using webRTC." },
                { First: "Capturing screenshots by using “chrome.tabs.captureVisibleTab” API" },
                { First: "Using OpenAi’s chatGpt and LangChain to generate accurate meeting notes and MOM." },
                { First: "Updating transcript in UI on real time by using some custom events" },
                { First: "Saving manual notes in chrome’s local storage and sending them to users through email." },
              ],
            },
          ],
        },

        companyinfo: {
          logourl:
            "yasmin",
          about: "About ",
          aboutpara:
            "Floomy is a Chrome extension that automatically captures and transcribes your online calls in real time. It also generates AI-powered notes and insights, so you can focus on the conversation without worrying about taking notes. Floomy is currently free and supports Google Meet. It's a great way to stay organized and productive during online meetings.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Floomy",
            },
            {
              first: "INDUSTRY:",
              secound: "Productivity",
            },
            {
              first: "LOCATION:",
              secound: " Udaipur, Rajasthan",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
