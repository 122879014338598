import React from "react";
import data from "./AarnasystemsWebData";
import BannerImage from "../../../../images/CaseStudiesImagesLogo/Aarna.png";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const AarnasystemsWeb = () => {
    return (
        <>
            <SuperSEO 
                title="Aarna Systems - Innovators in Portable Bone Density Scanners"
                description="Delivering cutting-edge osteoporosis solutions with user-friendly designs, training, and support for healthcare professionals."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { BannerImage },
                        summaryCardImageAlt: "Aarna Systems",
                        summaryCardSiteUsername: "AarnaSystems",
                    },
                }}
            />

            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default AarnasystemsWeb;
