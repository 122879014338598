import React from "react";
import data from "./BankTransactionData";
import BannerImage from "../../../../images/CaseStudiesImagesLogo/BankTransaction.png"; 
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const BankTransaction = () => {
    return (
        <>
            <SuperSEO 
                title="Bank Transaction Scraper"
                description="Revolutionize transaction data processing with our automated scraping extension, enhancing accuracy and efficiency."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { BannerImage }, 
                        summaryCardImageAlt: "Automated Transaction Data Scraping",
                        summaryCardSiteUsername: "BankTransaction",
                    },
                }}
            />

            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default BankTransaction;
