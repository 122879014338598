import React from "react";
import { useState } from "react";
import "./faq.css";
import Downimg from "../../../images/chrome-extension-develpment/Downimg.svg";
import Upimg from "../../../images/chrome-extension-develpment/Upimg.svg";

const Faq = () => {
  const [faqs, setFaqs] = useState([
    {
      id: 1,
      Heading: "1. What types of Chrome extensions do you develop?",
      Answer:
        "At LakeBrains, we specialize in developing a wide range of Chrome extensions tailored to various needs and purposes.",
      listItem1:
        "A productivity extension that helps users block distractions, manage time, and take notes.",
      listItem2:
        "A social media extension that enhances the functionality of Twitter, making it easier to find content and interact with other users.",
      listItem3:
        "An entertainment extension that adds new features to YouTube, such as the ability to watch videos in a picture-in-picture mode.",
      listItem4:
        "A security and privacy extension that blocks ads and tracking cookies, protecting users' privacy and security.",
      listItem5:
        "An e-commerce extension that enhances the functionality of Amazon, making it easier to find products and compare prices.",
      listItem6:
        "An educational extension that helps users learn new things, such as by providing interactive exercises and quizzes.",
    },
    {
      id: 2,
      Heading: "2. How do you ensure the security of Chrome extensions?",
      Answer:
        "Keeping your Chrome extension secure is our utmost concern. To make this happen, we follow the most effective methods used in the industry and conduct comprehensive security testing. We continuously stay informed about the latest security trends and take necessary steps to safeguard your data and users' privacy effectively.",
    },
    {
      id: 3,
      Heading: "3. Can you integrate AI models and APIs into Chrome extensions?",
      Answer:
        "Absolutely! We excel in integrating AI models and APIs like ChatGPT into Chrome extensions to provide advanced capabilities and personalized experiences. If you require AI-powered customer support, chatbots, or data analysis features, we can provide the expertise to make it a reality.",
    },
    {
      id: 4,
      Heading:
        "4. Do you offer ongoing support and maintenance for Chrome extensions?",
      Answer:
        "Yes, our services don't end with the development of your Chrome extension. We provide continuous maintenance and support to keep your extension current, secure, and compatible with the latest Chrome browser updates. Our committed support team is available round the clock to assist with any problems and provide quick help.",
    },
    {
      id: 5,
      Heading: "5. Can you publish my Chrome Addon on the Chrome Web Store?",
      Answer:
        "Absolutely! We can handle the entire process of publishing your Chrome addon on the Chrome Web Store. We will guide you through the submission requirements, optimize your extension listing for better discoverability, and ensure a smooth publishing process.",
    },
    {
      id: 6,
      Heading:
        "6. How can I get started with LakeBrains for my Chrome extension project?",
      Answer:
        "Getting started with us is easy! Simply reach out to our team through our website or the contact information provided. We would be happy to talk about your project, understand what you want to achieve, and create a customized plan for developing your add-on. For any inquiries, you can also directly email us at sales@lakebrains.in We look forward to hearing from you!.",
    },
    {
      id: 7,
      Heading: "7. Can you migrate a Chrome extension from MV2 to MV3?",
      Answer:
        " Yes, it is possible to migrate a Chrome extension from MV2 to MV3. However, the process involves making significant changes to the extension's codebase and updating the Manifest file to comply with the new format and features of MV3.",
    },
    {
      id: 8,
      Heading: "8. Do you improve already developed extensions?",
      Answer:
        " Yes, we can improve already developed extensions. Our team of experienced developers and experts can work on enhancing and optimizing existing Chrome extensions to meet specific requirements or address issues.",
    },
    {
      id: 9,
      Heading: "9. I have a question that is not mentioned above",
      Answer:
        "Please let us know if there is anything you want to ask apart from the above. We are here to assist you every step of the way. ",
    },
    // {
    //   id: 10,
    //   Heading:
    //     "How much time it will take to generate text from the Spotify Podcast ?",
    //   Answer:
    //     "It offers effortless and accurate conversion through advanced speech recognition technology. Experience time-saving benefits, streamlined content management, and automatic email delivery with User-friendly Interface .",
    // },
    // Add more FAQ items as needed
  ]);

  const [openFAQIndex, setOpenFAQIndex] = useState(null);

  const toggleFAQ = (id) => {
    setOpenFAQIndex((prevIndex) => (prevIndex === id ? null : id));
  };

  return (
    <div className="container-fluid FAQbg">
      <div className="faqMainDiv container">
        <div className="faqDivPart1">
          <h2 className="faqC-h1">Frequently Asked Questions</h2>
          <h1 className="faq-h2">Some Questions And Their Answers</h1>

          <h4 className="faq-text">
            Find the answer you Need .Common Question, Expert Answers
          </h4>
        </div>
        <div className="faqDivPart3">
          {faqs.map((faq) => (
            <div className="faqtitle" key={faq.id}>
              <h2 className="f-head" id="FaqIdC">
                {faq.Heading}
                <button
                  className={openFAQIndex === faq.id ? "activeButton" : ""}
                  onClick={() => toggleFAQ(faq.id)}
                >
                  {openFAQIndex === faq.id ? (
                    <span>
                      <img className="faq-img" alt="UP"  src={Upimg} />
                    </span>
                  ) : (
                    <span>
                      <img className="faq-img" alt="DOWN" src={Downimg} />
                    </span>
                  )}
                </button>
              </h2>
              {openFAQIndex === faq.id && <p id="faqparaC">{faq.Answer}</p>}

              {openFAQIndex === faq.id && (
                <ul className="listItem">
                  {faq.id === 1 && <li>{faq.listItem1}</li>}
                  {faq.id === 1 && <li>{faq.listItem2}</li>}
                  {faq.id === 1 && <li>{faq.listItem3}</li>}
                  {faq.id === 1 && <li>{faq.listItem4}</li>}
                  {faq.id === 1 && <li>{faq.listItem5}</li>}
                  {faq.id === 1 && <li>{faq.listItem6}</li>}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
