import React from "react";
import data from "./JainCommunityData"; 
import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/jainCommunity.png"; 
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const JainCommunity = () => {
    return (
        <>
            <SuperSEO 
                title="Jain Community App - S2S Connect"
                description="Enhancing networking and job opportunities within the Jain community through a user-friendly mobile app."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "Jain Community App",
                        summaryCardSiteUsername: "JainCommunity",
                    },
                }}
            />

            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default JainCommunity;
