import React from "react";
import { useState } from "react";
import "./Questions.css";
import Downimg from "../../../../images/chrome-extension-develpment/Downimg.svg";
import Upimg from "../../../../images/chrome-extension-develpment/Upimg.svg";

const Questions = () => {
  const [faqs, setFaqs] = useState([
    {
      id: 1,
      Heading: "1. Which platforms does it support for live transcription?",
      Answer:
        "The extension supports live transcription on all audio-supported web platforms such as YouTube, Spotify, Google Meet, Zoom, and more.",
    },
    {
      id: 2,
      Heading: "2. Do I have to create an account to use this extension?",
      Answer:
        " No, you don't need to sign up or create an account to start using the extension. The extension will only ask for Google authentication if you choose to upload your transcript to your Google Drive, but that is entirely optional.",
    },
    {
      id: 3,
      Heading:
        "3. Is my data saved on the server or used without my knowledge? What about data privacy?",
      Answer:
        "The Live Transcript extension is a standalone extension that operates solely on your system. It does not send any data, even for transcription, to external servers. You can rest assured that you have full data privacy.",
    },
    {
      id: 4,
      Heading:
        "4. Can we use the transcript with ChatGPT to get action items and summaries?",
      Answer:
        "Absolutely! The transcript is available for free download, and you can feed it to OpenAI's ChatGPT to gain valuable insights. If you need assistance in creating prompts for your specific needs, feel free to contact us at sales@lakebrains.in. We'll be happy to help.",
    },
    {
      id: 5,
      Heading: "5. Which languages does it support?",
      Answer:
        "Currently, the extension supports only the English language. However, we can customize it to work with other languages based on specific needs. Feel free to contact us at sales@lakebrains.in for any language requirements.",
    },
    {
      id: 6,
      Heading: "6.  Is there a cost associated with using this extension?",
      Answer: "No, the extension is completely free to use.",
    },
  ]);

  const [openFAQIndex, setOpenFAQIndex] = useState(null);

  const toggleFAQ = (id) => {
    setOpenFAQIndex((prevIndex) => (prevIndex === id ? null : id));
  };

  return (
    <div className="container-lg">
      <div className="queDiv">
        <div className="queDivPart1">
          <h1 className="que-h">Frequently Asked Questions</h1>

          <h4 className="que-text">
            Curious about Live Transcript ? Ask us anything about our service
            and the solutions , and get factual responses.
          </h4>
        </div>
        <div className="faqDivPart3">
          {faqs.map((faq) => (
            <div className="faqtitle" key={faq.id}>
              <h2 className="f-head" id="">
                {faq.Heading}
                <button
                  className={openFAQIndex === faq.id ? "activeButtonQ" : ""}
                  onClick={() => toggleFAQ(faq.id)}
                >
                  {openFAQIndex === faq.id ? (
                    <span className="QueImg">
                      <img src={Upimg} />
                    </span>
                  ) : (
                    <span className="QueImg">
                      <img src={Downimg} />
                    </span>
                  )}
                </button>
              </h2>
              {openFAQIndex === faq.id && <p id="queparaC">{faq.Answer}</p>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Questions;
