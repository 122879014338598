import React, { useState } from "react";

// This is for backend section
import ServicesTopBanner from "../../ServicesTopBanner/ServicesTopBanner";
import ServicesBoxSection from "../../ServicesBoxSection/ServicesBoxSection";
import ServiceTechnologiesWeWork from "../../ServiceTechnologiesWeWork/ServiceTechnologiesWeWork";
// import TechnologiesWeWork from '../TechnologiesWeWork/TechnologiesWeWork';
import OurTestimonials from "../../../SubSection/ourtestimonials/OurTestimonials";
import AdvantagesOfServices from '../../../Services/BrowserExtension/AdvantagesOfServices'
import Whyus from "../../../../Comman/Why Us/Whyus";

import ScheduleCall from "../../../../Comman/ScheduleCall/ScheduleCall";
import CaseStudySection from "../../../../Comman/CaseStudy/CaseStudy";
import FAQ from "../../../../Comman/FAQ/FAQ";
import { Helmet } from "react-helmet";
import { SuperSEO } from "react-super-seo";
import BlogSlider from '../../../BlogSlider/BlogSlider';
// import Demo from '../../../Comman/HubContactForm/HubContactForm'
import AIimage from '../../../../../images/plugindevelopmentSubPagesIcon/6.png'

// Case Study imgs imports 
import VoiceCommand from '../../../../../images/casestudy/VoiceCommand.webp'
import InteractExtension from '../../../../../images/casestudy/InteractExtension.webp'
import LiveTranscript from '../../../../../images/casestudy/LiveTranscript.webp'

// WHY AI Based Chrome Browser Extension images imports
import Personalization from '../../../../../images/chrome_extension_icons/IconGraphics/Personalization.svg'
import Automation from '../../../../../images/chrome_extension_icons/IconGraphics/Automation.svg'
import Security from '../../../../../images/chrome_extension_icons/IconGraphics/Security.svg'
import Accessibility from '../../../../../images/chrome_extension_icons/IconGraphics/Accessibility.svg'



const FrontendData = [
    {
        // image: next,
        h6: "Next.js",
        alt: "Next.js",
        link: "https://nextjs.org/",
    },
    {
        // image: react,
        h6: "React.js",
        alt: "React.js",
        link: "https://reactjs.org/",
    },
    {
        // image: javascript,
        h6: "Javascript",
        alt: "Javascript",
        link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    },
    {
        // image: extension,
        h6: "Extension",
        alt: "Extension",
        link: "https://chrome.google.com/webstore/category/extensions",
    },
    {
        // image: jquery,
        h6: "Jquery",
        alt: "Jquery",
        link: "https://jquery.com/",
    },
    {
        // image: html,
        h6: "Html5",
        alt: "Html5",
        link: "https://en.wikipedia.org/wiki/HTML",
    },
    {
        // image: css,
        h6: "Css3",
        alt: "Css3",
        link: "https://en.wikipedia.org/wiki/CSS",
    },
    {
        // image: bootstrap,
        h6: "Bootstrap",
        alt: "Bootstrap",
        link: "https://getbootstrap.com/",
    },
    {
        // image: less,
        h6: "Less",
        alt: "Less",
        link: "https://lesscss.org/",
    },
    {
        // image: sass,
        h6: "Sass",
        alt: "Sass",
        link: "https://sass-lang.com/",
    },
];

const Bdata = [
    {
        image: Personalization,
        title: "Personalization",
        alt: "Personalization",
        description:
            "Utilizing artificial intelligence enables a personalized user experience through provision of pertinent content and suggestions. To elucidate further, consider an AI-driven news extension providing articles that align with the interests and inclinations of a particular user as inferred from their historical browsing activity.",
            Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use.",
            },
        ],
    },
    {
        image: Automation,
        title: "Automation",
        alt: "Automation",
        description:
            "The power of artificial intelligence (AI) in automating tasks continues to be a game changer across various industries. For instance an AI driven form filler can significantly reduce the time and effort involved in filling out online forms. Furthermore AI can be used to detect and block malicious Chrome extensions before installation - boosting security for users browsing through these extensions.  .",
            Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use.",
            },
        ],
    },
    {
        image: Security,
        title: "Security",
        alt: "Security",
        description:
            "In addition people with disabilities often face accessibility challenges when accessing web content. With AI technology screen reader extensions can read text aloud assisting those who are visually impaired to navigate more effectively through web pages. ",
        Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use.",
            },
        ],
    },
    {
        image: Accessibility,
        title: "Accessibility",
        alt: "Accessibility",
        description:
            "Also, application in these areas is proof of its potential to simplify complex tasks and make our lives better.",
            Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use.",
            },
        ],
    },
  
];

const Fdata = [
    {
        heading: "Browser Extension",
        subData: [
            {
                accordionHead:
                    " Why should you go with Helpful Insight for Chrome extension Development?",
                accordionPara:
                    "Helpful Insight is a leading Chrome extension development company that specialises in creating simple and complex websites in response to social trends. We have a team of developers who are professionals in developing websites for different businesses. We hire developers who can come up with the best development strategy, helping to increase innovation and scalability.Chrome browser extension development services are also provided for enhanced operation.",
                id: "3",
            },
            {
                accordionHead:
                    "How much does it cost to create a Chrome extension at Helpful Insight?",
                accordionPara:
                    "Our goal is to deliver high-quality, fast and efficient websites at competitive prices entirely based on the services our clients require. We estimate application development costs based on business needs and provide the best services available. Please contact us with your specific requirements, and we will give you the best quote.",
                id: "4",
            },
            {
                accordionHead:
                    "Are Browser extensions the Same as apps",
                accordionPara:
                    'No, browser extensions add functionality to your web browser, modify web pages, or integrate with other services you use your browser with. According to Google, an app is "an application that can run in a browser with a dedicated user interface and typically rich user interaction." Browser extensions are used to enhance the functionality of the browser while running an app with a different user interface inside the browser.',
                id: "5",
            },
            {
                accordionHead:
                    "Why develop a browser extension",
                accordionPara:
                    "Today is the day to develop a custom add-on because it would not be an exaggeration to say that people literally use web browsers every day, which means your chances of finding users for the product will not be far off. And if you want to create a desktop-only product, then developing browser extensions is definitely the most cost-effective way to solve a wide range of business problems.",
                id: "6",
            },
            {
                accordionHead:
                    "Who is the owner of the final source code?",
                accordionPara:
                    "Our clients own 100% of all work done. We transfer the intellectual property rights of the work upon the release of the final payment to the project.",
                id: "6",
            },
            {
                accordionHead:
                    "What if we are not happy with the developer/team provided for my Chrome Browser Extension project?",
                accordionPara:
                    "We believe this is not the case, but we do provide the option to change resources/pools if it does. We also communicate with you regularly to ensure that you are satisfied with the services provided.",
                id: "7",
            },
        ],
    },
];

const extension1 = JSON.stringify(
    <g clip-path="url(#0ba41b69f2)">
        <path
            fill="#ffffff"
            d="M 305.355469 187.5 L 273.214844 187.5 L 273.214844 123.214844 C 273.214844 111.386719 263.613281 101.785156 251.785156 101.785156 L 187.5 101.785156 L 187.5 69.644531 C 187.5 51.898438 173.101562 37.5 155.355469 37.5 C 137.613281 37.5 123.214844 51.898438 123.214844 69.644531 L 123.214844 101.785156 L 58.929688 101.785156 C 47.101562 101.785156 37.5 111.386719 37.5 123.214844 L 37.5 187.5 L 69.644531 187.5 C 87.386719 187.5 101.785156 201.898438 101.785156 219.644531 C 101.785156 237.386719 87.386719 251.785156 69.644531 251.785156 L 37.5 251.785156 L 37.5 316.070312 C 37.5 327.898438 47.101562 337.5 58.929688 337.5 L 123.214844 337.5 L 123.214844 305.355469 C 123.214844 287.613281 137.613281 273.214844 155.355469 273.214844 C 173.101562 273.214844 187.5 287.613281 187.5 305.355469 L 187.5 337.5 L 251.785156 337.5 C 263.613281 337.5 273.214844 327.898438 273.214844 316.070312 L 273.214844 251.785156 L 305.355469 251.785156 C 323.101562 251.785156 337.5 237.386719 337.5 219.644531 C 337.5 201.898438 323.101562 187.5 305.355469 187.5 Z M 305.355469 187.5 "
            fill-opacity="1"
            fill-rule="nonzero"
        />
    </g>
);

// console.log("extension1 =>", extension1);

// Service data for TechnologiesWeWork section
const services_data_browser = [
    {
        name: "AI ML  Model/APIs",
        alt: "AI ML  Model/APIs",
        icon: "custom models",
    },
    {
        name: "HTML, CSS,\n Bootstrap, JS,\n Reacy",
        alt: "HTML, CSS,\n Bootstrap, JS,\n Reacy",
        icon: "validation",
    },
    {
        name: "Web Assembly",
        alt: "Web Assembly",
        icon: "engineering",
    },
    {
        name: "Web Audio API",
        alt: "Web Audio API",
        icon: "management",
    },
    {
        name: "Chrome APis",
        alt: "Chrome APis",
        icon: "manufacturing",
    },
];

const CaseData = [
    {
        heading: " Voice Commands",
        alt: " Voice Commands",
        // logo: "https://www.interactpro.ai/hubfs/raw_assets/public/InteractPro_Theme/images/Logo.svg",
        subheading: "Browser Control Through Voice Commands",
        para: "'Browser Control Through Voice Commands' is a free chrome extension that allows you to control your chrome browser through voice commands. You can add the extension to your chrome browser and then give commands to perform your task. ",
        btntext: "More On Voice Commands",
        imgurl: VoiceCommand,

        caseurl: "/casestudy/browser-control-through-voice-commands",


    },
    {
        heading: "Interact Extension",
        alt: "Interact Extension",
        // logo: "https://idealeads.in/wp-content/uploads/2021/10/ideallead_Logo-e1634960127764.webp",
        subheading: "Interact Chrome Extension",
        para: "Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers.",
        btntext: "More On Interact Extension",
        imgurl: InteractExtension,
        caseurl: "/casestudy/interact-extension",
    },
    {
        heading: "Transcript Extension",
        alt: "Transcript Extension",
        // logo: "https://meinshort.com/static/media/logo.320ce173.svg",
        subheading: "Live Transcript Extension",
        para: "You could want to record lectures from online meetings, online courses, podcasts, and streaming YouTube videos.",
        btntext: "More On Transcript Extension",
        imgurl: LiveTranscript,
        caseurl: "/casestudy/capture-transcript-from-a-meet",
    },
];

const AiBrowserExtension = ({ city }) => {

    return (
        <div>
            <SuperSEO
        title="Chrome Browser Extension Development Company"
        description="Lakebrains is the best chrome browser extension development services company. Lakebrains build high-quality cross-platform Chrome Extension plugins. Also we help in Migrate manifest V2 to V3."
        lang="en"
        twitter={{
          twitterSummaryCard: {
            // summaryCardImage: {BrowserImage},
            summaryCardImageAlt: 'img',
            summaryCardSiteUsername: "justinmahar",
          },
        }}
      />
            <ServicesTopBanner
                headTitle={
                    city
                        ? `AI based Chrome Browser Extension Development company in ${city}`
                        : `AI based Chrome Browser Extension`
                }
                headDes="We fortunate enough to work on something extra ordinary where we have build the chrome browser extension which is having AI capability like live behavior analysis, transcription, noise cancellation etc. Our team will keep pushing our limits in build more smart browser extensions."
                video={AIimage}
            />
            
            <ServicesBoxSection techtype="Build AI Powered Chrome Browser Extension with Lakebrains Experts" />
            
            {/* 6 banners */}
            <AdvantagesOfServices
                data={Bdata}
                work="Why"
                title="AI based Chrome Browser Extension"
                subTitle="advantages of AI-based Chrome extensions"
            />
            <ServiceTechnologiesWeWork
                Cdata={FrontendData}
                title="Browser"
                subTitle="We have worked tirelessly to create a small and creative browser extension that handles huge amounts of data and complex transactions without sacrificing performance. As a result, our best browser extension development solution uses the latest technologies and ensures the highest quality."
            />

            {/* CASE STUDY SECTION */}
            <CaseStudySection CaseData={CaseData} />
            <Whyus />
            <OurTestimonials />
            <ScheduleCall />
            <BlogSlider />
            <FAQ Fdata={Fdata} ServiceType="Browser Extension" />
        </div>
    );
};

export default AiBrowserExtension;
