import Bannnerimage from "../../../images/CaseStudiesImagesLogo/Interact.webp";
import Logo from "../../../images/logo/logo_lakebrains.webp";


const data = [
  {
    slugurl: "/interact-extension",
    data: [
      {
        Banner: [
          {
            sector: "Sales/ Productivity",
            headingtext: "Interact Chrome Extension",
            headingpara:
              "Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Turn all your Calls into AI-Powered conversation intelligence tools",
        casestudy: {
          Problem:
            `"Sales representatives lack an AI-powered prospecting tool that offers real-time guidance, behavior analysis, and integrates with noise cancellation. Existing solutions fail to provide the necessary features such as transcription, AI model integration, and script management within a Chrome extension."`,
          challenge: [
            {
              heading: "The Challenge ",
              challengeli: [
                { First: "Transcribe the sales rep's and customer's voice" },
                { First: "Sales rep's behaviour analysis" },
                { First: "Integrate with krisp noise cancellation." },
                { First: "Run AI models into the chrome extension" },
                { First: "Manage scripts and visualize them in the extension only." },
              ],
            },
            {
              heading: "The Approach ",
              challengeli: [
                { First: "Capturing both audio streams and processed them with 'symbl.ai' to transcribe" },
                { First: "Detecting behaviours like overlaping, long pauses, energy on the basis of audio streams and silence detection." },
                { First: "Integraed Krisp noise cancellation on both audio streams using web assembly." },
                { First: "Able to run the AI/ML models in the extension through web assembly and external backend APIs." },
                { First: "Used local storage to store script details and used ReactJS to visualize them." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: " The implementation of an AI-powered prospecting tool with real-time guidance, behavior analysis, and noise cancellation integration can significantly improve sales team performance. By providing personalized coaching and insights, it empowers sales representatives to engage effectively with prospects, leading to increased deal closures. The integration of AI models and script management within the Chrome extension streamlines workflows, saving time and enabling sales reps to focus on building strong customer relationships. Overall, this tool has the potential to drive higher sales productivity, customer satisfaction, and revenue growth for the organization." },],
            },
          ],
        },

        companyinfo: {
          logourl:
            Logo,
          about: "About ",
          aboutpara:
            "Anything we can do to eliminate sales rep stress, provide confidence on every call and presentation, and drive more sales is important to us.",
          companysector: [
            {
              first: "INDUSTRY: ",
              secound: "Networking / Call Center",
            },

            {
              first: "HQ LOCATION:",
              secound: "Ohio, Us",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
