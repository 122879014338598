import Bannnerimage from "../../../../images/casestudy/dropscale.png";

const data = [
  {
    slugurl: "/dropscale",
    data: [
      {
        Banner: [
          {
            sector: "E-commerce/Online Retail",
            headingtext: "Dropscale aliexpress collector Extension",
            headingpara:
              "Sharing aliexpress product detail with dropscale to manage them at one place",
            image: Bannnerimage,
          },
        ],
        heading:
          "Product analysis tool for ali express",
        casestudy: {
          Problem:
            `"The "Product Analysis Tool for Ali Express" faces challenges in appending new buttons on the live website to match its UI, extracting product IDs, order IDs, and tracking IDs from the Ali Express website without altering the existing pages."`,
          challenge: [
            {
              heading: "The Challenges:- ",
              challengeli: [
                { First: "Append New Button on live website same as website Ui" },
                { First: "Get the product id from aliexpress website." },
                { First: "Get the Order id from aliexpress Order section." },
                { First: "Get the Tracking id from aliexpress Orders without changing the Order page ." },
              ],
            },
            {
              heading: "The Approaches:-",
              challengeli: [
                { First: "Adding new buttons on aliexpress live website with the content script" },
                { First: "Extract the Product id from product overview section in aliexpress" },
                { First: "Extract the Order id from order section in aliexpress" },
                { First: "Extract the Tracking id from order section in aliexpress without changing the page. We open an iframe and get tracking id from it without showing the iframe" },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: `The implementation of solutions for the extension addresses these challenges. By adding new buttons that blend with the Ali Express website's UI, users can easily access the product analysis tool. The extraction of product IDs, order IDs, and tracking IDs from their respective sections on the website streamlines the data collection process for analysis and evaluation. The ability to extract tracking IDs without displaying an iframe enhances the user experience and maintains a seamless browsing experience. Overall, the "Product Analysis Tool for Ali Express" extension improves efficiency, data analysis, and decision-making for users navigating the Ali Express platform.` },],
            },
          ],
        },

        companyinfo: {
          logourl:
            "Bannnerimage",
          about: "About ",
          aboutpara:
            "This Dropscale extension gives you the capability to scrap order id, product id and tracking id from the aliexpress and share that data with dropscale webapp",
          companysector: [
            {
              first: "Company Name:",
              secound: "dropscale",
            },
            {
              first: "INDUSTRY:",
              secound: "Productivity",
            },
            {
              first: "LOCATION:",
              secound: "United States",
            },
          ],
        },
        customer: [
        ],
      },
    ],
  },
];

export default data;
