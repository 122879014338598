import React from "react";
import containerVideo from "../../../../images/LiveTranscriptionExtension/LiveTranscriptionExtension.mp4";
import "./LiveTranscriptGet.css";
import Feature1 from "../../../../images/LiveTranscriptionExtension/transcript_feature/Puzzle2.png";
import Feature2 from "../../../../images/LiveTranscriptionExtension/transcript_feature/Puzzle5.png";
import Feature3 from "../../../../images/LiveTranscriptionExtension/transcript_feature/Puzzle4.png";

const LiveTranscriptGet = () => {
  return (
    <>
      <div className="container-lg transcript-get-container">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center live-get-container">
            <div style={{paddingLeft: "5vw"}}>
              <div className="live-get-text">
                <div className="live-get-text1">Ready to get started ?</div>
                <div className="live-get-text2">
                Focus on consuming, Not on note taking
                </div>
              </div>
              <button
                className="live-get-btn"
                onClick={() => {
                  window.open(
                    "https://chrome.google.com/webstore/detail/live-transcript-extension/bbdgdpkamhamfdcbbkbooomiliaiahpn"
                  );
                }}
              >
                Add to chrome, Its free
              </button>
            </div>
          </div>
          <div className="live-get-img">
            <img className="get-img3" src={Feature3} alt="" />
            <img className="get-img1" src={Feature1} alt="" />
            <img className="get-img2" src={Feature2} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveTranscriptGet;
