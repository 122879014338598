import BannerImage from "../../../../images/CaseStudiesImagesLogo/Ovolinkedln.png";

const data = [
  {
    slugurl: "/OvoLinkedIn",
    data: [
      {
        Banner: [
          {
            sector: "Technology",
            headingtext: "OVO - LinkedIn",
            headingpara:
              "OVO is a Chrome extension that generates customizable LinkedIn comments using scraped post data and OpenAI, enhancing user engagement efficiently.",
            image: BannerImage,
          },
        ],
        heading:
          "Streamlining LinkedIn Engagement with Automated, Personalized Comments",
        casestudy: {
          Problem:
            `OVO needed to automate the generation of LinkedIn comments based on post data, allowing users to customize them using tone, persona, and goal values.`,
          challenge: [
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Automating meaningful LinkedIn comment generation." },
                { First: "Customizing comments with tone, persona, and goal inputs." },
                { First: "Ensuring data security and privacy." },
              ],
            },
            {
              heading: "The Solution:-",
              challengeli: [
                { First: "Scraping Post Data: Extracting LinkedIn post content." },
                { First: "Custom User Inputs: Allowing tone, persona, and goal customization." },
                { First: "OpenAI Integration: Generating tailored comments." },
                { First: "Google Authentication & JWT: Secure login and data communication." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [
                { First: `OVO streamlines LinkedIn engagement, enabling users to quickly generate personalized comments, saving time and boosting interaction.` },
              ],
            },
          ],
        },

        companyinfo: {
          logourl: "ovo",
          about: "About ",
          aboutpara:
            "OVO allows users to scrape LinkedIn post data and generate comments with customized tone, persona, and goal using OpenAI, enhancing online engagement effortlessly.",
          companysector: [
            {
              first: "Company Name:",
              secound: "OVO",
            },
            {
              first: "INDUSTRY:",
              secound: "OpenAi/Technology",
            },
            {
              first: "LOCATION:",
              secound: "Dubai",
            },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
