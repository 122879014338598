import Bannnerimage from "../../../../images/casestudy/google docs colletor extension.webp";
import Logo from "../../../../images/logo/logo_lakebrains.webp";

const data = [
  {
    slugurl: "/googleDocsDataCollector",
    data: [
      {
        Banner: [
          {
            sector: " Productivity/Document Management",
            headingtext: "Google Docs Data Collector",
            headingpara:
              "Google docs data collector is a valuable chrome extension that scraps selected data from docs canvas & googles docs APIs",
            image: Bannnerimage,
          },
        ],
        heading:
          "This Google docs data collector extension to scrap selected data from docs canvas",
        casestudy: {
          Problem:
            `"The "Google Docs Data Collector" extension faces challenges in extracting selected data from the Google Docs canvas, appending/replacing data back into the selected area, and implementing a copy-to-clipboard functionality.            "`,
          challenge: [
            {
              heading: "How to use it:- ",
              challengeli: [
                { First: "Download the Google docs data collector extension." },
                { First: "Select any text in google docs." },
                { First: "Click on the docs icon to get data in Non-Complaint Selection the extension." },
                { First: "Copy from the compliant section." },
                { First: "Chose from the button Replace non-compliant text, Copy to Clipboard &  Submit To Compliance." },
                { First: "Click on the close button to close the extension." },
              ],
            },
            {
              heading: "The Challenges:- ",
              challengeli: [
                { First: "Get selected data from google docs canvas to extension." },
                { First: "Append/Replace data back to selected data in docs." },
                { First: "Copy to clipboard on button click." },
              ],
            },
            {
              heading: "The Approaches:-",
              challengeli: [
                { First: "Getting selected data from docs to extension by selection prototype in inner html document in canvas." },
                { First: "Replacing selected data from docs to extension by selection prototype in inner html document in canvas." },
                { First: "We copied to the clipboard by clipboardData.getData('text/plain')javascript command." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: `The "Google Docs Data Collector" extension improves productivity and organization by enabling users to extract selected data from the Google Docs canvas, manipulate it, and conveniently copy it to the clipboard. This streamlines the data collection process, enhances document management, and enhances the overall user experience for individuals working with Google Docs.` },],
            },
          ],
        },

        companyinfo: {
          logourl:
            Logo,
          about: "About ",
          aboutpara:
            "This Google docs data collector extension gives you the capability to scrap selected data from docs canvas & googles docs APIs and change selected text and save it in the database & append it back to docs canvas.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Project Orbit",
            },
            {
              first: "INDUSTRY:",
              secound: "Unknown",
            },
            {
              first: "LOCATION:",
              secound: "United States",
            },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
