import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CaseStudyTopCard.css";

export default function CaseStudyTopCard({ cashStuduCards, heading, subHeading, description }) {
    const itemsPerPage = 8; // Number of items per page
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [uniqueCategories, setUniqueCategories] = useState([]);

    useEffect(() => {
        // Extract unique categories from cashStuduCards
        const categories = cashStuduCards.reduce((acc, curr) => {
            if (!acc.includes(curr.category)) {
                acc.push(curr.category);
            }
            return acc;
        }, []);
        setUniqueCategories(categories);
    }, [cashStuduCards]);

    // Filter items based on selected category
    const filteredItems = selectedCategory
        ? cashStuduCards.filter((item) => item.category === selectedCategory)
        : cashStuduCards;

    // Calculate the index range for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const nextPage = () => {
        if (currentPage < Math.ceil(filteredItems.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category === "All" ? null : category);
        setCurrentPage(1); // Reset to first page when changing category
    };

    // Render pagination only if needed
    const shouldRenderPagination = filteredItems.length > itemsPerPage;

    // console.log(uniqueCategories.length);

    return (
        <>
            <section className="CaseStudyTopCard_Container">
                <div className="container d-flex justify-content-center flex-column align-items-center">
                    <div className="OurCommitment-text d-flex text-center flex-column align-items-center" style={{ marginBottom: "65px" }}>
                        <h4>{subHeading}</h4>
                        <h2>{heading}</h2>
                        <p>{description}</p>
                    </div>

                    {/* Category buttons */}
                    {uniqueCategories.length > 1 && (
                        <div className="mb-5 Card_Catagory_Section d-flex flex-wrap align-items-center justify-content-center">
                            <button
                                className={`btn ${selectedCategory === null ? 'active-category-btn' : 'inactive-category-btn'} mr-2`}
                                onClick={() => handleCategoryChange("All")}
                            >
                                All
                            </button>

                            {uniqueCategories.map((category, index) => (
                                <button
                                    key={index}
                                    className={`btn ${selectedCategory === category ? 'active-category-btn' : 'inactive-category-btn'} mr-2`}
                                    onClick={() => handleCategoryChange(category)}
                                >
                                    {category}
                                </button>
                            ))}
                        </div>
                    )}


                    <div className="w-100">
                        <div className="CaseStudyTopCard_Content_Container row justify-content-center industries-section-saas-main-div">
                            {currentItems.map((item) => (
                                <div className="col-lg-3 col-md-4 col-sm-6 col-12 text-center" key={item.id}>
                                    <Link to={item.URL} className="pb-3 h-100">
                                        <div className="CaseStudyTopCard_Content py-5 px-3 h-100">
                                            <div className="CaseStudyTopCard_Company_Logo mb-2">
                                                <img src={item.img} alt="" className="w-100" />
                                            </div>
                                            <h6 className="pb-2" style={{ color: "black" }}>{item.title}</h6>
                                            <p style={{ color: "#575757" }}>{item.content}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Pagination */}
                    {shouldRenderPagination && (
                        <ul className="pagination mt-5">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <button onClick={prevPage} className="page-link">Previous</button>
                            </li>
                            {Array(Math.ceil(filteredItems.length / itemsPerPage)).fill().map((_, index) => (
                                <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                    <button onClick={() => paginate(index + 1)} className="page-link">{index + 1}</button>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === Math.ceil(filteredItems.length / itemsPerPage) ? 'disabled' : ''}`}>
                                <button onClick={nextPage} className="page-link">Next</button>
                            </li>
                        </ul>
                    )}
                </div>
            </section>
        </>
    );
}
