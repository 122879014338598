import React, { useEffect } from "react";
import { useState } from "react";
import Downimg from "../../../../images/chrome-extension-develpment/Downimg.svg";
import Upimg from "../../../../images/chrome-extension-develpment/Upimg.svg";

import AOS from "aos";

AOS.init();

const Carriers = () => {
  useEffect(() => {
    AOS.init();
  }, []);
};


const ServiceFaq = ({ serviceData }) => {
  const [faqs, setFaqs] = useState([

  ]);

  const [openFAQIndex, setOpenFAQIndex] = useState(null);

  const toggleFAQ = (id) => {
    setOpenFAQIndex((prevIndex) => (prevIndex === id ? null : id));
  };

  return (
    <div className="container-fluid FAQbg" style={{ margin: "0" }}>
      <div className="faqMainDiv container" style={{ marginBottom: "0", padding: "130px 0" }}>
        <div className="OurCulture-text d-flex flex-column align-items-center">
          <h4 className="">Frequently Asked Questions</h4>
          <h2 className="">Some Questions And Their Answers</h2>
          <p className="">
            Find the answer you need. Common Question, Expert Answers.
          </p>
        </div>
        <div className="faqDivPart3" style={{ paddingTop: "70px" }}>
          {serviceData.map((faq) => (
            <div className="faqtitle" key={faq.id}>
              <h2 className="f-head" id="FaqIdC">
                {faq.Heading}
                <button
                  className={openFAQIndex === faq.id ? "activeButton" : ""}
                  onClick={() => toggleFAQ(faq.id)}
                >
                  {openFAQIndex === faq.id ? (
                    <span>
                      <img className="faq-img" alt="UP" src={Upimg} loading="lazy"/>
                    </span>
                  ) : (
                    <span>
                      <img className="faq-img" alt="DOWN" src={Downimg} loading="lazy"/>
                    </span>
                  )}
                </button>
              </h2>
              {openFAQIndex === faq.id && <p id="faqparaC">{faq.Answer}</p>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceFaq;
