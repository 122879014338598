import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Requires a loader
import "react-tabs/style/react-tabs.css";
import "aos/dist/aos.css";
import AOS from "aos";

import ChitranshJain from "../../../../images/teams/01.webp";
import KhushbuJain from "../../../../images/teams/02.webp";
import Anirban_Mandal from "../../../../images/teams/Anirban_Mandal.png";
import Kishore_Kumar from "../../../../images/teams/Kishore_Kumar (2).png";

const Teams = () => {
  useEffect(() => {
    AOS.init();
    return () => {
      AOS.refresh();
    };
  }, []);

  const data = [
    {
      executive: [
        {
          name: "Chitransh Jain",
          alt: "Chitransh Jain",
          position: "Chitransh, an AI and SaaS product development expert, boasts a decade-long career with stints at Sony, Qualcomm, and Einfochips. Armed with a B.Tech in Electronics & Communication, he founded Lakebrains, focusing on automating businesses through AI, catering to renowned firms like BCG, Acuity, and startups.",
          image: ChitranshJain,
        },
        {
          name: "Khushbu Jain",
          alt: "Khushbu Jain",
          position: "Khushbu, a seasoned web developer with 7 years of experience, co-founded Lakebrains alongside her husband Chitransh, an AI and ML expert with 10+ years of industry know-how. Together, they lead a team focused on automating business processes for clients like BCG, Acuity, and startups, ensuring rapid, high-quality results.",
          image: KhushbuJain,
        },
        {
          name: "Anirban Mandal",
          alt: "Anirban Mandal",
          position: "Embedded DSP professional having 9+ years of experience in the audio domain for mobile, IoT, and automotive industry. Good knowledge of porting and optimization of different audio algorithms onto QDSP, ARM, x86, Xtensa processors with AI/ML model training on Tensorflow platform.",
          image: Anirban_Mandal,
        },
        {
          name: "Kishore K.",
          alt: "Kishore K.",
          position: "Embedded system software professional having 8+ years versatile experience of working in the semiconductor industry with audio/video and automotive domains. Working exposure of video post-processing firmware design for Sony X1 processors.",
          image: Kishore_Kumar,
        },
      ],
    },
  ];

  return (
    <div className="container teams-member-column">
      <div className="each-team">
        <div className="homepage-heading" style={{ margin: "0", marginTop: "30px" }}>
          <h4>Our Team</h4>
          <h2>Want to work on cutting edge technologies?</h2>
          <p>We are very selective about adding people to our team. We pair young, tech-savvy entrepreneurs with seasoned industry experts who work tirelessly to help others succeed.</p>
        </div>
        <div className="New_Team_card row justify-content-center mt-5">
          {data.map((item, index) => {
            const { executive } = item;
            return (
              <React.Fragment key={index}>
                {executive.map((member, idx) => (
                  <div
                    key={idx}
                    data-aos="zoom-in"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    className="col-sm-6 d-flex justify-content-center"
                  >
                    <div className="New_Team member-card0">
                      <div className="member-card_Img">
                        <img
                          className="images0"
                          src={member.image}
                          alt={member.alt}
                          loading="lazy"
                        />
                      </div>
                      <div className="teamMemberDetail">
                        <h4><b>{member.name}</b></h4>
                        <p>{member.position}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Teams;
