import React from "react";
import "./DriveMetrics.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const DriveMetrics = () => {
  return (
    <div className="example-classname">
      <div className="container-fluid px-0 pt-5">
        <div className="DriveMetrics-wrapper text-white">
          <div className="container">
            <div className="DriveMetrics-container1-wrapper text-center p-3">
              <h2 className="mt-2 font-w-700">Revolutionize your Web Experience with AI-Powered Solutions</h2>
              <div className="row justify-content-center">
                <div className="col-lg-3 col-md-4 col-sm-6 mt-3">
                  <h2 className="font-w-700 font-s-40">
                    <CountUp end={40} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className="px-2" ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </h2>
                  <p className="font-s-20">Customers</p>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mt-3">
                  <h2 className="font-w-700 font-s-40">
                    <CountUp end={100} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className="px-2" ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </h2>
                  <p className="font-s-20">Projects</p>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 mt-3">
                  <h2 className="font-w-700 font-s-40">
                    <CountUp end={25} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className="px-2" ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    +
                  </h2>
                  <p className="font-s-20">Headcounts</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriveMetrics;

