import BannerImage from "../../../../images/CaseStudiesImagesLogo/Aarna.png";

const data = [
  {
    slugurl: "/AarnasystemsWeb",
    data: [
      {
        Banner: [
          {
            sector: "Healthcare/Technology",
            headingtext: "Aarna Systems",
            headingpara:
              "Aarna Systems specializes in innovative portable bone density scanners, providing advanced solutions for osteoporosis detection. Trusted nationwide by hospitals and clinics, they ensure seamless integration and reliable performance in healthcare diagnostics.",
            image: BannerImage,
          },
        ],
        heading:
          "Leading Innovators in Portable Bone Density Scanners and Osteoporosis Solutions",
        casestudy: {
          Problem:
            `The medical Bone Density Scanner Manufacturers equipment information site is to educate and inform visitors about bone density scanning technology, services offered by manufacturers, and related medical equipment information.`,
          challenge: [
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Educating healthcare professionals on product benefits." },
                { First: "Navigating complex regulatory compliance for medical devices." },
                { First: "Building brand awareness and customer trust in a competitive market." },
              ],
            },
            {
              heading: "The Solution:-",
              challengeli: [
                { First: "User-Friendly Design: Developed intuitive, easy-to-use interfaces for their devices to improve user experience." },
                { First: "Comprehensive Training: Provided training and educational resources to healthcare professionals on device usage and benefits." },
                { First: "Market Research: Conducted thorough market research to understand customer needs and preferences, shaping the design and marketing approach." },
                { First: "Focus on Quality Assurance: Implemented stringent quality control and testing to ensure regulatory compliance and high performance." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [
                { First: `Aarna Systems successfully positioned itself as a premier provider of portable bone density scanners in India. Their products are trusted by medical professionals and hospitals nationwide, with a strong reputation for reliability, high performance, and seamless integration in healthcare environments.` },
              ],
            },
          ],
        },

        companyinfo: {
          logourl: "aarnasystems",
          about: "About ",
          aboutpara:
            "Aarna Systems and Wellness Pvt. Ltd is a healthcare technology company specializing in portable bone density scanners for precise osteoporosis detection. Their solutions are trusted by medical institutions across India for their accuracy and reliability.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Aarna Systems and Wellness Pvt. Ltd",
            },
            {
              first: "INDUSTRY:",
              secound: "Healthcare Technology",
            },
            {
              first: "LOCATION:",
              secound: "Udaipur, India",
            },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
