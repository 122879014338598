import React from "react";
import ServiceIndustries from "../../../Comman/ServiceComponents/SaasProductService/ServiceIndustries";
// import CEOMessage from "../../../Comman/CeoMassage";
import Vector3 from "../../../../images/chrome-extension-develpment/vector3.svg";
import Vector7 from "../../../../images/chrome-extension-develpment/vector7.svg";
import E_commerce from "../../../../images/chrome-extension-develpment/e-commerce.png";
import saas from "../../../../images/chrome-extension-develpment/saas.png";
import mining from "../../../../images/chrome-extension-develpment/mining.png";
import recruitment from "../../../../images/chrome-extension-develpment/recruitment.png";
import CaseStudyBanner from "../../../Comman/CaseStudyBanner/CaseStudyBanner";
import CaseStudyTopCard from "../../../Comman/CaseStudyBanner/CaseStudyTopCard";
import Extension from "../CaseStudyCarousel/Extension";



import Live_Icon from "../../../../images/CaseStudiesImagesLogo/live.png";
import Squire from "../../../../images/CaseStudiesImagesLogo/Squire.png";
import Rea from "../../../../images/CaseStudiesImagesLogo/Rea.png";
import Interact from "../../../../images/CaseStudiesImagesLogo/Interact.webp";
import TextHilight from "../../../../images/CaseStudiesImagesLogo/TextHilight.svg";
import PreScouter from "../../../../images/CaseStudiesImagesLogo/PreScouter.png";
import DataLix from "../../../../images/CaseStudiesImagesLogo/DataLix.png";
import DropScalpng from "../../../../images/CaseStudiesImagesLogo/DropScalpng.png";
import FloomyLogo from "../../../../images/CaseStudiesImagesLogo/FloomyLogo.svg";
import gnote from "../../../../images/CaseStudiesImagesLogo/gnote.png";
import YouTubeLogo from "../../../../images/CaseStudiesImagesLogo/YouTubeLogo.webp";
import NoteTaking from "../../../../images/CaseStudiesImagesLogo/NoteTaking.svg";

import microsoft_outlook from "../../../../images/CaseStudiesImagesLogo/microsoft-outlook-icon.webp";
import word_file from "../../../../images/CaseStudiesImagesLogo/word-file-icon.svg";
import webrtc from "../../../../images/CaseStudiesImagesLogo/webrtc-icon.svg";
// import google_docs from "../../../../images/CaseStudiesImagesLogo/google-docs.svg";
import openai from "../../../../images/CaseStudiesImagesLogo/openai-icon.svg";
import Intatica from "../../../../images/CaseStudiesImagesLogo/Intatica.png";
import Voice_Icon from "../../../../images/CaseStudiesImagesLogo/VoiceComand_Icon.png";
import logo_lakebrains from "../../../../images/logo/logo_lakebrains.webp";
import Ovolinkedln from '../../../../images/CaseStudiesImagesLogo/Ovolinkedln.png';
import BankTransaction from '../../../../images/CaseStudiesImagesLogo/BankTransaction.png'

const cashStuduCards = [
  {
    img: Live_Icon,
    title: "Live Transcript",
    content: "Want to transcribe meetings, online courses, youtube videos and podcasts?",
    URL: "/casestudy/capture-transcript-from-a-meet/",
    category: "Extension",
  },
  {
    img: Squire,
    title: "Squire Extension",
    content: "Unlock Recruiter Potential with Squire!",
    URL: "/casestudy/squire/",
    category: "Extension",
  },
  {
    img: PreScouter,
    title: "PreScouter User-360",
    content: "Gmail Add-on to get the past interactions we have had with any specific lead/contact.",
    URL: "/casestudy/PreScouter-User-360/",
    category: "Add-on",
  },
  {
    img: Rea,
    title: "Rea Extension",
    content: "Save bookmarks and set notifications alarm to any bookmarks",
    URL: "/casestudy/rea-extention/",
    category: "Extension",
  },
  {
    img: Interact,
    title: "Interact Extension",
    content: "Turn all your Calls into AI-Powered conversation intelligence tools",
    URL: "/casestudy/interact-extension/",
    category: "Extension",
  },
  {
    img: Intatica,
    title: "Detector Video",
    content: "This chrome extension gives you the capability to download videos from different sites including social media platforms",
    URL: "/casestudy/video-downloader-by-detector-video/",
    category: "Extension",
  },
  {
    img: TextHilight,
    title: "Text Highlight",
    content: "Chrome extension to show highlighted text in different WPM(Words per min)",
    URL: "/casestudy/text-highlight-extention/",
    category: "Extension",
  },
  {
    img: YouTubeLogo,
    title: "Youtube Extra Ad",
    content: "Custom ads on youtube with Youtube Extra Ad Chrome extension",
    URL: "/casestudy/Youtube-Extra-Ad-Chrome-Extension/",
    category: "Extension",
  },
  {
    img: openai,
    title: "Gmail GPT Auto Reply",
    content: "Reply your emails smartly with Gmail GPT Auto Reply Extension",
    URL: "/casestudy/Write-GPT-OpenAI/",
    category: "Extension",
  },
  {
    img: word_file,
    title: "Google docs data collector",
    content: "This Google docs data collector extension to scrap selected data from docs canvas",
    URL: "/casestudy/Google-Docs-Data-Collector/",
    category: "Extension",
  },
  {
    img: Squire,
    title: "Zoom App",
    content: "Empower recruiters with seamless Zoom integration.",
    URL: "/casestudy/SquireZoom/",
    category: "Add-on",
  },
  {
    img: DropScalpng,
    title: "Dropscale Extension",
    content: "Sharing aliexpress product detail with dropscale to manage them at one place",
    URL: "/casestudy/dropscale/",
    category: "Extension",
  },
  {
    img: webrtc,
    title: "Screen Recorder",
    content: "Chrome Extension to Capture/Record chrome tabs screen",
    URL: "/casestudy/screen_rec_ext/",
    category: "Extension",
  },
  {
    img: DataLix,
    title: "Datalix",
    content: "Datalix provides users with an Ecommerce Company and Contact info",
    URL: "/casestudy/datalix/",
    category: "Extension",
  },
  {
    img: FloomyLogo,
    title: "Floomy Extension",
    content: "Generates AI- Powered notes and insights, so you can focus on the conversation without worrying about taking notes.",
    URL: "/casestudy/floomy/",
    category: "Extension",
  },
  {
    img: gnote,
    title: "G-note",
    content: "G-note is an AI-powered note-capturing app that frees you from tedious note taking.",
    URL: "/casestudy/Gnote/",
    category: "Extension",
  },
  {
    img: microsoft_outlook,
    title: "Outlook Addon",
    content: "Elevate your email communication effortlessly.",
    URL: "/casestudy/OutlookAddon/",
    category: "Add-on",
  },
  {
    img: NoteTaking,
    title: "Note Taking Extension",
    content: "Organise thoughts with intuitive note extension.",
    URL: "/casestudy/NoteTakingExtension/",
    category: "Extension",
  },
  {
    img: Squire,
    title: "Ringcentral App",
    content: "Unlock Recruiter Potential with Squire!",
    URL: "/casestudy/SquireRingCentral/",
    category: "Add-on",
  },
  {
    img: Voice_Icon,
    title: "Voice Assistant",
    content: "Want to control your browser through voice commands?",
    URL: "/casestudy/browser-control-through-voice-commands/",
    category: "Extension",
  },
  {
    img: logo_lakebrains,
    title: "Clozure Rxtension",
    content: "Streamline your typing with shortcuts and organise content with tags.",
    URL: "/casestudy/clozure-extension/",
    category: "Extension",
  },
  {

    img: Ovolinkedln,
    title: "OVO - LinkedIn",
    content: "Streamlining LinkedIn engagement through customizable comment generation using OpenAI.",
    URL: "/casestudy/OvoLinkedIn/",
    category: "WebDev",
},
{
  img: BankTransaction,
  title: "Bank Transaction Scraper",
  content: "Revolutionizing transaction data processing through automated scraping from bank statements.",
  URL: "/casestudy/BankTransaction/",
  category: "Finance",
}


];

const industryData = [
  {
    img: Vector3,
    title: "Health Care ",
  },
  {
    img: Vector7,
    title: "Finance",
  },
  {
    img: E_commerce,
    title: "E-commerce",
  },
  {
    img: saas,
    title: "SaaS",
  },
  {
    img: mining,
    title: "Mining",
  },
  {
    img: recruitment,
    title: "Recruitmemt",
  },
];


export default function ExtensionAddonCaseStudyPage() {
  return (
    <>
      <CaseStudyBanner subHeading="Case Studies" heading="Extension/ Addon Development" description="Get to know more about the Extension/ Addon Development." />

      <CaseStudyTopCard cashStuduCards={cashStuduCards} subHeading="A Transformative Case Study" heading="Extension/Addon Case Studies" description="Analyses of specific software extensions or addons and their impacts." />

      <ServiceIndustries industryData={industryData} subHeading="Industries Transformed By Cloud" heading="Industries" description="Our experts will work with you to understand and solve specific industry problems using SaaS." />

    </>
  );
}
