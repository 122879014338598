import React from "react";
import containerVideo from "../../../../images/LiveTranscriptionExtension/LiveTranscriptionExtension.mp4";
import youtube from "../../../../images/LiveTranscriptionExtension/logos_youtube-icon.svg";
import spotify from "../../../../images/LiveTranscriptionExtension/logos_spotify.svg";
import zoom from "../../../../images/LiveTranscriptionExtension/logos_zoom.svg";
import meet from "../../../../images/LiveTranscriptionExtension/logos_google-meet.svg";

import "./LiveTranscriptContainer.css";

const LiveTranscriptContainer = () => {
  return (
    <>
      <div className="container-lg">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center live-transcript-container">
            <div className="">
              <span className="live-transcript-heading">
                Live Transcript Extension
              </span>
              <div className="live-transcript-text">
                Accurate, Secure, and Free Real-time Transcriptions for Any Web
                Platform!
              </div>
              <div className="d-flex align-items-center live-transcript-inBtn">
                <button
                  className="live-transcript-btn"
                  onClick={() => {
                    window.open(
                      "https://chrome.google.com/webstore/detail/live-transcript-extension/bbdgdpkamhamfdcbbkbooomiliaiahpn"
                    );
                  }}
                >
                  Add to chrome, Its free
                </button>
                <div className="live-transcript-100 live-transcript-1001">100% Free</div>
              </div>

              <div className="socialIcon">
                <img className="youtubeLogo" src={youtube} alt="" />
                <img className="spotifyLogo" src={spotify} alt="" />
                <img className="meetLogo" src={meet} alt="" />
                <img className="zoomLogo" src={zoom} alt="" />
              </div>
            </div>
          </div>
          <div className="live-transcript-video d-flex align-items-center">
            {/* <img className="w-100 getImg" src={GSIMAGE} alt="" /> */}
            <video className="w-100 getVideo" controls>
              <source src={containerVideo} type="video/mp4" />
            </video>
            <div className="live-transcript-100 live-transcript-1002">100% Free</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveTranscriptContainer;
