import React from "react";

import data from "./GoogleDocsDataCollectorData";
import Bannnerimage from "../../../../images/casestudy/google docs colletor extension.webp";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const GoogleDocsDataCollector = () => {
    return (
        <>
            <SuperSEO
                title="Google docs data collector - Lakebrains"
                description="This Google docs data collector extension to scrap selected data from docs canvas"
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "Google docs data collector",
                        summaryCardSiteUsername: "Google docs data collector",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default GoogleDocsDataCollector;
