import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS CSS

const ServiceExecution = ({ execution, heading, subHeading, description }) => {
    useEffect(() => {
        AOS.init(); // Initialize AOS inside the useEffect to ensure it's initialized after the component mounts
    }, []);

    function isMobileDevice() {
        return window.innerWidth < 768; // You can adjust the breakpoint as needed
    }

    return (
        <>
            <section className="industries-section-saas pb-5">
                <div className="container-lg d-flex justify-content-center flex-column align-items-center">
                    <div className="OurCommitment-text d-flex text-center flex-column align-items-center" style={{ marginBottom: "88px" }}>
                        <h4>{subHeading}</h4>
                        <h2>{heading}</h2>
                        <p>{description}</p>
                    </div>

                    <div className="industries-section-saas-main-div-parent w-100">
                        <div className="row justify-content-center align-items-center industries-section-saas-main-div Execution-section-saas-main-div">
                            {isMobileDevice() ? (
                                execution
                                    .sort((a, b) => parseInt(a.id) - parseInt(b.id))
                                    .map((i) => (
                                        <div className="col-md-4 col-sm-6 d-flex justify-content-center industries-section-saas-child-div" key={i.id}>
                                            <div className="industries-section-content d-flex justify-content-center flex-column align-items-center">
                                                <div className="industries-section-saas-id">{i.id}</div>
                                                <img src={i.img} alt="" className="industries-section-saas-img" loading="lazy"/>
                                                <p className="industries-section-saas-p text-center pt-2">{i.title}</p>
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                execution.map((i) => (
                                    <div className="col-md-4 col-sm-6 d-flex justify-content-center industries-section-saas-child-div" key={i.id}>
                                        <div className="industries-section-content d-flex justify-content-center flex-column align-items-center">
                                            <div className="industries-section-saas-id">{i.id}</div>
                                            <img src={i.img} alt="" className="industries-section-saas-img" loading="lazy"/>
                                            <p className="industries-section-saas-p text-center pt-2">{i.title}</p>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServiceExecution;
