import React, { useState } from "react";
import "./form.css";
// import emailjs from 'emailjs-com';

// function Form() {
  
  // const [form, setForm] = useState({
  //   name: "",
  //   email: "",  
  //   phone: "",
  //   message: ""
  // });


  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   // validate form
  //   if(!form.name || !form.email || !form.phone) {
  //     alert("Please fill all fields");
  //     return; 
  //   }

  //   // submit to API
  //   const response = await fetch("/api/form", {
  //     method: "POST",
  //     body: JSON.stringify(form)
  //   });
    

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   // const {name , email , phone , message} = user;

  //   const response = await fetch("/User-details", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(form) 
  //   });

  //   if(response.ok) {
  //     const data = await response.json();
  //     console.log(data);
  //     // reset form
  //   } else {
  //     console.log("Error"); 
  //   }
  // }


// const [name, setName] = useState();
// const [mobile , setMobile] = useState();
// const [email , setEmail] = useState();
// const HandleSubmit = () => {
//  if(name && mobile && email){

//   alert("Our team will get back to you shortly")
//  }
//  else{
//   alert("Please fill the inputs")
//  }
// }

// const [user , setUser] = useState(
//   {
//     name: "" , email: "", number: "", message: ""
//   }
// );

// let name,  value;
// const handleInputs = (e) => {
//   console.log(e);
//   name = e.target.name;
//   value = e.target.value;
   

//   setUser({...user, [name]:value})
  

// }


function Form() {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (name && mobile && email) {
      const formData = {
        name: name,
        mobile: mobile,
        email: email,
        message: message
      };

      try {
        const response = await fetch("http://127.0.0.1:8080/user/submit-form", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          const data =  response.json();
          console.log(data);
          
          window.alert("Our team will get back to you shortly");
          // Reset form fields
          setName("");
          setMobile("");
          setEmail("");
          setMessage("");
        } else {
          console.log("Error");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      window.alert("Please fill all fields");
    }
  };




  return (
    <div className="container-lg form-main">
      <div className="form-texts">
        <h1 className="form-heading">Put Life Into Your Ideas With Us</h1>
        <span className="form-t">
          Let’s discuss your project and find out what we can do to provide
          value.
        </span>
      </div>

      <form onSubmit={handleSubmit}   className="form-content" >
        <div className="form-floating f-box ">
          <input
            type="text"
            className="form-control in"
            id="name"
            placeholder="NAME"
            // value={user.name}
            
            onChange={e => setName(e.target.value)} 
            // onChange= {handleInputs}

          />
          {/* <label for="floatingInput">NAME</label> */}
        </div>
        <div className="form-floating f-box ">
          <input
            type="number"
            className="form-control in"
            id="number"
            placeholder="MOBILE"
            // value={user.number}
            required
            // onChange= {handleInputs}
           onChange={e => setMobile(e.target.value)}
          />
      
        </div>
        <div className="form-floating f-box ">
          <input
            type="email"
            className="form-control in"
            id="email"
            placeholder="EMAIL"
            // value={user.email}
            required
            // onChange= {handleInputs}
           onChange={e => setEmail(e.target.value)}
          />
        
        </div>

        <div className="form-floating f-boxM ">
          <textarea
            class="form-control in1"
            id="message"
            rows="3"
            placeholder="MESSAGE"
            onChange={e => setMessage(e.target.value)} 
            // value={user.message}
          
          />
          {/* <label for="floatingInput">MESSAGE</label> */}
        </div>
        <button class=" f-btn" onClick={handleSubmit}  >SUBMIT</button>
      </form>
    

    </div>
  );
}

export default Form;
