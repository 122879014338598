import React from "react";

//HomeImage
import cloudDeployment from "../../../../images/chrome-extension-develpment/cloudDeployment.svg";
//css
import "./saasProductPage.css";

//execution
import understand from "../../../../images/chrome-extension-develpment/gather-saas.svg";
import selectCloud from "../../../../images/chrome-extension-develpment/selectcloud.svg";
import buildarc from "../../../../images/chrome-extension-develpment/buildarc.svg";
import deploy from "../../../../images/chrome-extension-develpment/deploy.svg";
import monitor from "../../../../images/chrome-extension-develpment/monitor.svg";

import TechStackSaas from "./techStackSaas";

import ServiceOurBenefits from "../../../Comman/ServiceComponents/SaasProductService/ServiceOurBenefits";
import ServiceFaq from "../../../Comman/ServiceComponents/SaasProductService/ServiceFaq";
import ConnectOurExperts from "../../../Comman/ServiceComponents/SaasProductService/ConnectOurExperts";
import ServiceIndustries from "../../../Comman/ServiceComponents/SaasProductService/ServiceIndustries";

//UseCases
import storage from "../../../../images/chrome-extension-develpment/storage.svg";
import testingAndDeployment from "../../../../images/chrome-extension-develpment/testingAndDeployment.svg";
import serverlessComputing from "../../../../images/chrome-extension-develpment/serverlessComputing.svg";
import aiAsAService from "../../../../images/chrome-extension-develpment/aiAsAService.svg";
import saasDeployment from "../../../../images/chrome-extension-develpment/saasDeployment.svg";
import analytics from "../../../../images/chrome-extension-develpment/analytics.svg";


import ServiceUseCases from "../../../Comman/ServiceComponents/SaasProductService/ServiceUseCases";
import ServiceExecution from "../../../Comman/ServiceComponents/SaasProductService/ServiceExecution";
import ServiceBanner from "../../../Comman/ServiceComponents/SaasProductService/ServiceBanner";

//OurBenefits
import SubjectMatterExperts from "../../../../images/chrome-extension-develpment/SubjectMatterExperts.png";
import MultiPlatformSupport from "../../../../images/chrome-extension-develpment/MultiPlatformSupport.png";
import IntegratedServices from "../../../../images/chrome-extension-develpment/IntegratedServices.png";
import Support24 from "../../../../images/chrome-extension-develpment/Support24.png";
import ProblemSolving from "../../../Comman/NewHomeComponent/ProblemSolving/ProblemSolving";

//aws logos
import lambda from "../../../../images/OurtechStack/lambda.webp";
import sagemaker from "../../../../images/OurtechStack/sagemaker.svg";
import cloud9 from "../../../../images/OurtechStack/Cloud9.svg";
import ecs from "../../../../images/OurtechStack/ecs.webp";
import ec2 from "../../../../images/OurtechStack/ec2.svg";
import s3 from "../../../../images/OurtechStack/s3.svg";
import beanstalk from "../../../../images/OurtechStack/beanstalk.svg";
import codebuild from "../../../../images/OurtechStack/codebuild.svg";
import smtp from "../../../../images/OurtechStack/smtp.png";

//GCP logos
import cloudbuild from "../../../../images/OurtechStack/cloudbuild.svg";
import cloudrun from "../../../../images/OurtechStack/cloudrun.svg";
import cloudstorage from "../../../../images/OurtechStack/cloudstorage.svg";
import cloudfunctions from "../../../../images/OurtechStack/cloudfunctions.svg";
import firebasehosting from "../../../../images/OurtechStack/firebasehosting.svg";
import firebasefunction from "../../../../images/OurtechStack/firebasefunction.svg";
import vms from "../../../../images/OurtechStack/vms.png";

//other logos
import azurestorage from "../../../../images/OurtechStack/azurestorage.svg";
import appservice from "../../../../images/OurtechStack/appservice.svg";
import azuresql from "../../../../images/OurtechStack/azuresql.svg";
import azurefunction from "../../../../images/OurtechStack/azurefunction.svg";
import devops from "../../../../images/OurtechStack/devops.svg";
import cloud from "../../../../images/OurtechStack/azure.jpeg";
import vm from "../../../../images/OurtechStack/vm.svg";
import cp from "../../../../images/OurtechStack/cp.svg";
import ocean from "../../../../images/OurtechStack/ocean.svg";
import a2 from "../../../../images/OurtechStack/a2hosting.png";
import AWS from "../../../../images/OurtechStack/CloudD.svg";
import GCP from "../../../../images/OurtechStack/CDgcp.svg";
import Azure from "../../../../images/OurtechStack/CDAzure.svg"

import ProblemSolvingImg from "../../../../images/SubPages/OurTestimonials/ProblemSolvingImg.png";
//OurExpert
import Chitransh from "../../../../images/ourExperts/ChitranshJain.webp";

// industries data
import Vector3 from "../../../../images/chrome-extension-develpment/vector3.svg";
import Vector7 from "../../../../images/chrome-extension-develpment/vector7.svg";
import E_commerce from "../../../../images/chrome-extension-develpment/e-commerce.png";
import saas from "../../../../images/chrome-extension-develpment/saas.png";
import mining from "../../../../images/chrome-extension-develpment/mining.png";
import recruitment from "../../../../images/chrome-extension-develpment/recruitment.png";

import Services from "../../../Comman/ServiceComponents/SaasProductService/Services";

const industryData = [
  {
    img: Vector3,
    title: "Health Care ",
  },
  {
    img: Vector7,
    title: "Finance",
  },
  {
    img: E_commerce,
    title: "E-commerce",
  },
  {
    img: saas,
    title: "SaaS",
  },
  {
    img: mining,
    title: "Mining",
  },
  {
    img: recruitment,
    title: "Recruitmemt",
  },
];

const execution = [
  {
    id: "1",
    img: understand,
    title: "Understand the requirements",
  },
  {
    id: "2",
    img: selectCloud,
    title: "Select a cloud provider",
  },
  {
    id: "3",
    img: buildarc,
    title: "Architecture building",
  },
  {
    id: "5",
    img: deploy,
    title: "Cloud cost management",
  },
  {
    id: "4",
    img: monitor,
    title: "Deploy scalable solution",
  }
];

const UseCases = [
  {
    img: storage,
    title: "Storage",
    description: "Cloud storage can be used to store files for an office or directory in a content repository and disaster recovery.",
  },
  {
    img: testingAndDeployment,
    title: "Testing and Deployment",
    description: "Cloud testing and deployment are processes that help organizations test and deploy applications. Cloud testing involves using a third-party service provider's cloud computing resources to test software applications. ",
  },
  {
    img: serverlessComputing,
    title: "Serverless Computing",
    description: "Serverless computing allows developers to build and run applications without having to manage servers or backend infrastructure. The cloud provider takes care of all routine infrastructure management and maintenance.",
  },
  {
    img: aiAsAService,
    title: "AI as a service",
    description: "AI as a Service (AIaaS) is a cloud-based tool for easy AI experimentation, offering cost-effective testing and deployment of AI applications without risking core infrastructure.",
  },
  {
    img: saasDeployment,
    title: "SaaS Deployment",
    description: "SaaS cloud deployment is a model that allows software vendors to deliver their products via cloud computing. In a SaaS deployment, the software vendor owns and maintains the software and hardware infrastructure that delivers the product.",
  },
  {
    img: analytics,
    title: "Big Data Analytics",
    description: "Cloud big data analytics uses algorithms in the cloud to discover patterns and insights in large data sets, aiding businesses in making informed decisions.",
  },
];

const OurBenefitsData = [{
  para: "Subject matter experts",
  alt: "SubjectMatterExperts",
  image:
    SubjectMatterExperts,
},
{
  para: "Multi platform support",
  alt: "MultiPlatformSupport",
  image:
    MultiPlatformSupport,
},
{
  para: "Integrated services",
  alt: "IntegratedServices",
  image:
    IntegratedServices,
},
{
  para: "24 hrs support",
  alt: "Support24",
  image:
    Support24,
},
]

const ProblemSolvingData = [
  {
    heading: "Live Transcript Extension",
    photo: ProblemSolvingImg,
    description:
      "So, as we've built out my application Interact Technologies, which will be released shortly after the new year, they've taken over all programming, such as Website Development, Chrome Extension, NLP, Keyword Extraction, and on the Back-end, building out HubSpot CMS, and what's nice is that everything is communicating really well. I think probably the best part of working with LakeBrains Technologies is the relationship that we have built that goes way beyond the Client- Vendor Relationship.",
    btntext: "View more",
    caseurl: "/casestudy/browser-control-through-voice-commands/",
  },
  {
    heading: "Generic Web Scraper (PDF Generator)",
    photo: ProblemSolvingImg,
    description: " Generic Web Scraper is a Javascript (Node Js) Implementation which is responsible for opening a new browser then switch to the given url and generate pdf files, Extract text from the given url and export html of the url and save all these files on S3 Bucket",
    btntext: "View more",
    caseurl: "/casestudy/browser-control-through-voice-commands/",
  },
  {
    heading: "Browser Control Through Voice Commands",
    photo: ProblemSolvingImg,
    description:
      "So, as we've built out my application Interact Technologies, which will be released shortly after the new year, they've taken over all programming, such as Website Development, Chrome Extension, NLP, Keyword Extraction, and on the Back-end, building out HubSpot CMS, and what's nice is that everything is communicating really well. I think probably the best part of working with LakeBrains Technologies is the relationship that we have built that goes way beyond the Client- Vendor Relationship.",
    btntext: "View More",
    caseurl: "/casestudy/browser-control-through-voice-commands/",
  },
  {
    heading: "Browser Control Through Voice Commands",
    photo: ProblemSolvingImg,
    description:
      "So, as we've built out my application Interact Technologies, which will be released shortly after the new year, they've taken over all programming, such as Website Development, Chrome Extension, NLP, Keyword Extraction, and on the Back-end, building out HubSpot CMS, and what's nice is that everything is communicating really well. I think probably the best part of working with LakeBrains Technologies is the relationship that we have built that goes way beyond the Client- Vendor Relationship.",
    btntext: "View More",
    caseurl: "/casestudy/browser-control-through-voice-commands/",
  },

];

const data1 = [
  {
    name: "GCP",
    info: [
      {
        logo: cloudrun,
        name: "Cloud Run",
        alt: "CLoud Run",
        link: "https://cloud.google.com/run",
      },
      {
        logo: cloudbuild,
        name: "Cloud Build",
        alt: "Cloud Build",
        link: "https://cloud.google.com/build",
      },
      {
        logo: cloudfunctions,
        name: "Cloud Functions",
        alt: "Cloud Functions",
        link: "https://cloud.google.com/functions",
      },
      {
        logo: cloudstorage,
        name: "Cloud Storage",
        alt: "Cloud Storage",
        link: "https://cloud.google.com/storage",
      },
      {
        logo: firebasefunction,
        name: "Firebase Function",
        alt: "Firebase Function",
        link: "https://firebase.google.com/docs/functions",
      },
      {
        logo: firebasehosting,
        name: "Firebase hosting",
        alt: "Firebase hosting",
        link: "https://firebase.google.com/docs/hosting",
      },
      {
        logo: vms,
        name: "VMs",
        alt: "vms",
        link: "https://cloud.google.com/compute",
      }]
  },
  {
    name: "AWS",
    info: [
      {
        logo: lambda,
        name: "Lambda",
        alt: "Lambda",
        link: "https://aws.amazon.com/lambda/",
      },
      {
        logo: ec2,
        name: "EC2",
        alt: "EC2",
        link: "https://aws.amazon.com/ec2/",
      },
      {
        logo: ecs,
        name: "ECS",
        alt: "ECS",
        link: "https://aws.amazon.com/ecs/",
      },
      {
        logo: s3,
        name: "S3 Bucket",
        alt: "S3",
        link: "https://aws.amazon.com/s3/",
      },
      {
        logo: cloud9,
        name: "Cloud9",
        alt: "cloud9",
        link: "https://aws.amazon.com/cloud9/",
      },
      {
        logo: beanstalk,
        name: "Beanstalk",
        alt: "beanstalk",
        link: "https://beanstalkapp.com/",
      },
      {
        logo: codebuild,
        name: "CodeBuild",
        alt: "codebuild",
        link: "https://aws.amazon.com/codebuild/",
      },
      {
        logo: sagemaker,
        name: "SageMaker",
        alt: "SageMaker",
        link: "https://docs.aws.amazon.com/sagemaker/",
      },

      {
        logo: smtp,
        name: "SMTP",
        alt: "smtp",
        link: "https://www.smtp.com/"
      }]
  },

  {
    name: "Others",
    info: [
      {
        logo: azurestorage,
        name: "Azure Storage",
        alt: "Azure Storage",
        link: "https://azure.microsoft.com/en-in/products/category/storage",
      },
      {
        logo: appservice,
        name: "App Service",
        alt: "App Service",
        link: "https://azure.microsoft.com/en-in/products/app-service",
      },
      {
        logo: cloud,
        name: "Cloud services",
        alt: "Cloud services",
        link: "https://azure.microsoft.com/en-in/products/cloud-services",
      },
      {
        logo: vm,
        name: "Virtual Machines",
        alt: "Virtual Machines",
        link: "https://azure.microsoft.com/en-in/products/virtual-machines",
      },
      {
        logo: devops,
        name: "Azure DevOps",
        alt: "Azure DevOps",
        link: "https://azure.microsoft.com/en-in/products/devops",
      },
      {
        logo: azurefunction,
        name: "Azure function",
        alt: "Azure function",
        link: "https://azure.microsoft.com/en-in/products/functions",
      },
      {
        logo: azuresql,
        name: "Azure SQL",
        alt: "Azure SQL",
        link: "https://azure.microsoft.com/en-in/products/azure-sql/database",
      },
      {
        logo: ocean,
        name: "Digital Ocean",
        alt: "Digital Ocean",
        link: "https://www.digitalocean.com/",
      },
      {
        logo: a2,
        name: "A2hosting",
        alt: "Digital Ocean",
        link: "https://www.a2hosting.com/",
      },
      {
        logo: cp,
        name: "cPanel",
        alt: "cPanel",
        link: "https://cpanel.net/",
      }]

  }];


const SubService = [
  {
    img: AWS,
    heading: "AWS",
    para: "Experience the power of cloud computing with Lakebrains Technologies' AWS services. We provide secure, scalable, and flexible cloud solutions tailored to your business needs. From cloud storage to advanced computing, our AWS expertise enables businesses to innovate, optimize costs, and achieve operational excellence in the cloud."

  },
  {
    img: GCP,
    heading: "GCP",
    para: "Leverage the cutting-edge capabilities of Google Cloud Platform with Lakebrains Technologies. Our GCP services empower businesses to harness the full potential of cloud technology. Whether it's data analytics, machine learning, or scalable app hosting, we optimize GCP services to accelerate your digital transformation journey. Explore limitless possibilities with our expert GCP solutions."

  },
  {
    img: Azure,
    heading: "Azure",
    para: "Embrace enterprise-grade cloud services with Lakebrains Technologies' Microsoft Azure solutions. Our expert team designs and deploys applications and services on Azure, ensuring seamless integration and optimal performance. From AI-driven applications to IoT solutions, we harness Azure's capabilities to transform your business operations, making them more efficient, scalable, and intelligent."

  },

]

const Service = [
  {
    id: "1",
    Heading: "What is a cloud service?",
    Answer: " A cloud service is a type of service provided over the internet that allows users to access and use computing resources, such as servers, storage, databases, networking, software, and more, on a pay-as-you-go basis.",

  },
  {
    id: "2",
    Heading: " What are the benefits of using cloud services?",
    Answer: "Cloud services offer numerous benefits, including cost savings, scalability, flexibility, accessibility from anywhere, automatic updates, and improved disaster recovery."
  },
  {
    id: "3",
    Heading: "How does cloud storage work?",
    Answer: "Cloud storage involves storing data on remote servers managed by a cloud service provider. Users can access this data from anywhere with an internet connection. The provider takes care of data security, redundancy, and maintenance. ",


  },
  {
    id: "4",
    Heading: " Is my data safe in the cloud?",
    Answer: "Cloud service providers invest heavily in security measures. Your data is typically protected through encryption, access controls, and regular security updates. However, it's essential to follow best practices and use strong passwords to enhance security. ",


  },
  {
    id: "5",
    Heading: "How do I choose the right cloud service provider?",
    Answer: "When selecting a cloud service provider, consider factors such as your specific needs, budget, data security, compliance requirements, and the provider's reputation and track record. ",


  },
  {
    id: "6",
    Heading: "What is the difference between public, private, and hybrid clouds?    ",
    Answer: "A public cloud is owned and operated by a third-party provider and is accessible to the general public. A private cloud is dedicated to a single organization, offering greater control and security. A hybrid cloud combines both public and private clouds, allowing data and applications to move between them. ",


  },
  {
    id: "7",
    Heading: "What is the cost structure for using cloud services?    ",
    Answer: "The cost of cloud services typically depends on usage, such as the number of resources consumed or the amount of data stored. Some services offer a pay-as-you-go model, while others have fixed pricing or a combination of both.    ",

  },
  {
    id: "8",
    Heading: "How can I ensure data compliance in the cloud?    ",
    Answer: "To maintain data compliance in the cloud, understand your regulatory requirements, choose a compliant cloud provider, implement encryption, access controls, and regularly audit and monitor your data.",

  },

  {
    id: "9",
    Heading: "Can I move my applications and data to the cloud?    ",
    Answer: "Yes, many applications and data can be migrated to the cloud. The process may vary depending on the complexity of the system, but most cloud providers offer tools and services to facilitate migration."

  },

]

const CloudNewPage = () => {

  return (
    <>
      <ServiceBanner heading={<span>Cloud<br /> Deployment</span>} subHeading="Cloud deployment is the process of deploying an application through one or more hosting models—software as a service (SaaS), platform as a service (PaaS) and/or infrastructure as a service (IaaS)—that leverage the cloud. This includes architecting, planning, implementing and operating workloads on cloud. We help not only in cloud deployments but also work closely in monitoring and saving your cloud costs. " BannerImg={cloudDeployment} />

      <Services subservices={SubService} />

      <ServiceUseCases UseCases={UseCases} subHeading="Use cases" heading="Cloud Solutions Simplified" description="Our team of Cloud experts is here to tackle a myriad of use-cases and even more complex challenges." />

      <ServiceIndustries industryData={industryData} subHeading="Industries Transformed By Cloud" heading="Industries" description="Our experts will work with you to understand and solve specific industry problems using SaaS." />

      <section>
        <TechStackSaas techDataInfo={data1} />
      </section>

      <ServiceExecution execution={execution} subHeading="Execution" heading="Executing Cloud Solution With Precision" description=" Our improved execution process helps you move faster and build a scalable solution." />

      <ServiceOurBenefits OurBenefitsData={OurBenefitsData} subHeading="Our benefits" heading="Unlock The Value Of Our Offering" description=" We don't just deliver; we collaborate as a dedicated team, supporting you at every stage of development and beyond." />

      <ProblemSolving ProblemSolvingData={ProblemSolvingData} />

      <ConnectOurExperts OurExperts="Chitransh Jain" image={Chitransh} subHeading="Connect with our experts" heading="Let Us Start Your Project Today" description="Unlock the true potential of your browser with our exceptional Chrome extension plugins and AddOns. Contact LakeBrains today to get started on your journey to enhance browsing!" />

      <ServiceFaq serviceData={Service}/>
    </>
  );
};

export default CloudNewPage;


