import React from "react";
import Casebanner from "./CaseBanner/Casebanner";
import data from "./casestudyidealeads.js";
import Bannnerimage from "../../../images/casestudy/idealeades.webp";
import { SuperSEO } from "react-super-seo";

const IdealeadsCaseStudy = () => {
  return (
    <>

      <SuperSEO
        title="Idealeads - Lakebrains"
        description="Quality contacts are greatest asset for any business. Get quality B2B lead database which includes CXO, Owner, Director, Partner, VP & Managers of 150+ sectors."
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: { Bannnerimage },
            summaryCardImageAlt: "Idealeads",
            summaryCardSiteUsername: "Idealeads",
          },
        }}
      />


      {data.map((items, key) => (
        <Casebanner key={key} data={items.data} />
      ))}
    </>
  );
};

export default IdealeadsCaseStudy;
