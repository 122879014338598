import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/LinkReach.jpeg";

const data = [
  {
    slugurl: "/AutomateLinkedin",
    data: [
      {
        Banner: [
          {
            sector:"Productivity/ Technology",
            headingtext: "Automate Linkedin Reachout",
            headingpara:
              "Automate Linkedin Reachout is a puppeteer script to scrap people data from sales navigator and reach out those people through automation (supports multiple accounts)",
            image: Bannnerimage,
          },
        ],
        heading:
          " Automate the process to reachout to people and more focus on meetings",
        casestudy: {
          Problem:
            `This Implementation to scrape people data from LinkedIn Sales Navigator and automate outreach to those people one by one with different linkedin accounts. This solution aims to streamline lead generation and outreach efforts, making it more efficient for sales and marketing teams.`,
          challenge: [
            {
              heading: "The Challenges:- ",
              challengeli: [
                { First: "Multiple Linkedin accounts support" },
                { First: "Avoid authenticate linkedin again and again" },
                { First: "Scrape data from sales navigator " },
                { First: "Manage scraped data" },
                { First: "Send a connection request with a personalised message to those people one by one." },
                { First: "Track connection request accepted or rejected. " },
                { First: "Track messages if any reply is received." },
                { First: "Automatically start on a fixed time and end after sending requests." },
              ],
            },
            {
              heading: "Solutions:-",
              challengeli: [
                { First: "Saved multiple linkedin accounts in google sheet and taking credentials from the sheet one by one and performing actions." },
                { First: "If session cookies (cookies.json) exist, the script loads them to maintain a persistent login session. Otherwise, it logs in using provided credentials and saves the session cookies for future use." },
                { First: "Tool Operator needs to provide a url of filtered people leads of sales navigator and It’ll open a new tab with that url and capture all leads." },
                { First: "Managing all leads in a google sheet and taking leads from the sheet one by one to be processed." },
                { First: "Clicking on a personalised invite button and writing a message through puppeteer and sending it to leads." },
                { First: "It’ll open processed leads one by one and check if connection requests are pending or accepted by observing button text. And will inform by sending a mail to registered email." },
                { First: "If a request is accepted then it’ll open the chat and will check last messages and observe if there is any new message. And will inform by sending a mail to registered email." },
                { First: "Script will run on a server and it’ll be in running state continuously and on a fixed time the execution will start." },
              ],
            },
          ],
        },

        companyinfo: {
          logourl:
            "yasmin",
          about: "About ",
          aboutpara:
            "This tool  illustrates how a business can leverage automated data scraping and outreach on LinkedIn Sales Navigator, supporting multiple LinkedIn accounts. By combining web automation with personalized outreach, this solution empowers sales.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Lakebrains Technologies",
            },
            {
              first: "INDUSTRY:",
              secound: "Productivity",
            },
            {
              first: "LOCATION:",
              secound: "Udaipur, Rajasthan",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
