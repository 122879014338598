import React from "react";

import data from "./KaribuWebData";
import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/karibu.png";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const KaribuWeb = () => {
    return (
        <>
            <SuperSEO 
                title="Karibu - Revolutionizing Online Wellness"
                description="Enhancing Mind and Body Well-Being through a User-Friendly Wellness Platform for Karibu"
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "Karibu",
                        summaryCardSiteUsername: "Karibu",
                    },
                }}
            />

            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default KaribuWeb;
