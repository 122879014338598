import React, { useState } from "react";

// This is for backend section
import ServicesTopBanner from "../../Services/ServicesTopBanner/ServicesTopBanner";
import ServicesBoxSection from "../../Services/ServicesBoxSection/ServicesBoxSection";
import ServiceTechnologiesWeWork from "../../Services/TechnologiesWeWork/TechnologiesWeWork";
// import TechnologiesWeWork from '../TechnologiesWeWork/TechnologiesWeWork';
import OurTestimonials from "../../SubSection/ourtestimonials/OurTestimonials";
import NLP from "../../Services/ServiceOurServices/NLP";
import Whyus from "../../../Comman/Why Us/Whyus";

import ScheduleCall from "../../../Comman/ScheduleCall/ScheduleCall";
import CaseStudySection from "../../../Comman/CaseStudy/CaseStudy";
import FAQ from "../../../Comman/FAQ/FAQ";
import { Helmet } from "react-helmet";
import { SuperSEO } from "react-super-seo";
import BlogSlider from '../../BlogSlider/BlogSlider';
// import Demo from '../../../Comman/HubContactForm/HubContactForm'

const FrontendData = [
    {
        // image: next,
        h6: "Next.js",
        alt: "Next.js",
        link: "https://nextjs.org/",
    },
    {
        // image: react,
        h6: "React.js",
        alt: "React.js",
        link: "https://reactjs.org/",
    },
    {
        // image: javascript,
        h6: "Javascript",
        alt: "Javascript",
        link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
    },
    {
        // image: extension,
        h6: "Extension",
        alt: "Extension",
        link: "https://chrome.google.com/webstore/category/extensions",
    },
    {
        // image: jquery,
        h6: "Jquery",
        alt: "Jquery",
        link: "https://jquery.com/",
    },
    {
        // image: html,
        h6: "Html5",
        alt: "Html5",
        link: "https://en.wikipedia.org/wiki/HTML",
    },
    {
        // image: css,
        h6: "Css3",
        alt: "Css3",
        link: "https://en.wikipedia.org/wiki/CSS",
    },
    {
        // image: bootstrap,
        h6: "Bootstrap",
        alt: "Bootstrap",
        link: "https://getbootstrap.com/",
    },
    {
        // image: less,
        h6: "Less",
        alt: "Less",
        link: "https://lesscss.org/",
    },
    {
        // image: sass,
        h6: "Sass",
        alt: "Sass",
        link: "https://sass-lang.com/",
    },
];

const Bdata = [
    {
        // image: Ai_Based,
        title: "AI Enable Browser Extension",
        alt: "AI Enable Browser Extension",
        Link: "https://blogs.lakebrains.com/nlp-chatbots-2",  //Not working
        LinkText:"More On AI Browser Extension",
        description:
            "We fortunate enough to work on something extra ordinary where we have build the browser extension which is having AI capability like live behavior analysis, transcription, noise cancellation etc. Our team will keep pushing our limits in build more smart browser extensions...",
        href:"/chrome-browser-extension-development-company/Ai-Browser-extenstion/",
            Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use.",
            },
        ],
    },
    {
        // image: Automation,
        title: "Browser Extension for SaaS Products",
        alt: "Browser Extension for SaaS Products",
        Link: "https://blogs.lakebrains.com/migrating_manifest_v2_to_v3",  //not working
        LinkText:"More On Extension for SaaS Products",
        description:
            "Lakebrains has expertise to build subscription based browser extension which require user authentication, dashboard, activity tracking, notification, analytics, payment gateway integration etc...",
            href:"/chrome-browser-extension-development-company/browser-extension-saas-product/",
            Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use.",
            },
        ],
    },
    {
        // image: Migration,
        title: "Migration From Manifest V2 to V3",
        alt: "Migration From Manifest V2 to V3",
        Link: "https://blogs.lakebrains.com/migrating_manifest_v2_to_v3",   //not working
        LinkText:"More On Manifest V2 to V3",
        href:"/chrome-browser-extension-development-company/migration-from-menifest-v2-to-v3/",
        description:
            "We have successfully migrated multiple browser extension for different clients. As we know there are lot of enhancements and changes in manifest v3. Lakebrains always tries to improve browser extension while migrating by using enhancement of manifest V3...",
        Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use.",
            },
        ],
    },
    {
        // image: React_based,
        title: "React Based Browser Extension",
        alt: "React Based Browser Extension",
        Link: "https://blogs.lakebrains.com/nlp-chatbots-2-2",    //not working
        LinkText:"More On React Based Extension",
        description:
            "Building Chrome extensions with React can be complicated and difficult to debug, but we've successfully addressed the key issues. We managed to create a chrome extension using React...",
            href:"/chrome-browser-extension-development-company/react-browser-extension/",
            Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use.",
            },
        ],
    },
    {
        // image: saas_based,
        title: "Extension for Web Automation",
        alt: "Extension for Web Automation",
        Link: "https://blogs.lakebrains.com/migrating_manifest_v2_to_v3",    //not working
        LinkText:"More On Extension for Automation",
        description:
            "Lakebrains developed extension can interact with almost any website to improve website automation.This extension helps reduce repetitive typing and the hassle of copying and pasting. It helps you automate simple activities like filling out forms, but is complex enough to support scripting and injection...",
            href:"/chrome-browser-extension-development-company/Ai-Browser-extenstion/",
        Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use...",
            },
        ],
    },
    {
        // image: web_scraping,
        title: "Extension Based Data Extractor",
        alt: "Extension Based Data Extractor",
        Link: "https://blogs.lakebrains.com/migrating_manifest_v2_to_v3",   //not working
        LinkText:"More On Data Extractor",
        description:
            "Lakebrains developed a Data Extractor extension, where you will see a detailed overview of the company you are looking for when you visit their website. Improve your lead generation and increase your productivity by having the information you need right there...",
            href:"/chrome-browser-extension-development-company/Ai-Browser-extenstion/",
            Case: [
            {
                image: "",
                CaseTitle: "Confidence Analysis Model ",
                CaseDescribtion:
                    "Use this file however you want!It’s yours, free, for personal or commercial use.",
            },
        ],
    },
];

const Fdata = [
    {
        heading: "Browser Extension",
        subData: [
            {
                accordionHead:
                    " Why should you go with Helpful Insight for Chrome extension Development?",
                accordionPara:
                    "Helpful Insight is a leading Chrome extension development company that specialises in creating simple and complex websites in response to social trends. We have a team of developers who are professionals in developing websites for different businesses. We hire developers who can come up with the best development strategy, helping to increase innovation and scalability.Chrome browser extension development services are also provided for enhanced operation.",
                id: "3",
            },
            {
                accordionHead:
                    "How much does it cost to create a Chrome extension at Helpful Insight?",
                accordionPara:
                    "Our goal is to deliver high-quality, fast and efficient websites at competitive prices entirely based on the services our clients require. We estimate application development costs based on business needs and provide the best services available. Please contact us with your specific requirements, and we will give you the best quote.",
                id: "4",
            },
            {
                accordionHead:
                    "Are Browser extensions the Same as apps",
                accordionPara:
                    'No, browser extensions add functionality to your web browser, modify web pages, or integrate with other services you use your browser with. According to Google, an app is "an application that can run in a browser with a dedicated user interface and typically rich user interaction." Browser extensions are used to enhance the functionality of the browser while running an app with a different user interface inside the browser.',
                id: "5",
            },
            {
                accordionHead:
                    "Why develop a browser extension",
                accordionPara:
                    "Today is the day to develop a custom add-on because it would not be an exaggeration to say that people literally use web browsers every day, which means your chances of finding users for the product will not be far off. And if you want to create a desktop-only product, then developing browser extensions is definitely the most cost-effective way to solve a wide range of business problems.",
                id: "6",
            },
            {
                accordionHead:
                    "Who is the owner of the final source code?",
                accordionPara:
                    "Our clients own 100% of all work done. We transfer the intellectual property rights of the work upon the release of the final payment to the project.",
                id: "6",
            },
            {
                accordionHead:
                    "What if we are not happy with the developer/team provided for my Chrome Browser Extension project?",
                accordionPara:
                    "We believe this is not the case, but we do provide the option to change resources/pools if it does. We also communicate with you regularly to ensure that you are satisfied with the services provided.",
                id: "7",
            },
        ],
    },
];

const extension1 = JSON.stringify(
    <g clip-path="url(#0ba41b69f2)">
        <path
            fill="#ffffff"
            d="M 305.355469 187.5 L 273.214844 187.5 L 273.214844 123.214844 C 273.214844 111.386719 263.613281 101.785156 251.785156 101.785156 L 187.5 101.785156 L 187.5 69.644531 C 187.5 51.898438 173.101562 37.5 155.355469 37.5 C 137.613281 37.5 123.214844 51.898438 123.214844 69.644531 L 123.214844 101.785156 L 58.929688 101.785156 C 47.101562 101.785156 37.5 111.386719 37.5 123.214844 L 37.5 187.5 L 69.644531 187.5 C 87.386719 187.5 101.785156 201.898438 101.785156 219.644531 C 101.785156 237.386719 87.386719 251.785156 69.644531 251.785156 L 37.5 251.785156 L 37.5 316.070312 C 37.5 327.898438 47.101562 337.5 58.929688 337.5 L 123.214844 337.5 L 123.214844 305.355469 C 123.214844 287.613281 137.613281 273.214844 155.355469 273.214844 C 173.101562 273.214844 187.5 287.613281 187.5 305.355469 L 187.5 337.5 L 251.785156 337.5 C 263.613281 337.5 273.214844 327.898438 273.214844 316.070312 L 273.214844 251.785156 L 305.355469 251.785156 C 323.101562 251.785156 337.5 237.386719 337.5 219.644531 C 337.5 201.898438 323.101562 187.5 305.355469 187.5 Z M 305.355469 187.5 "
            fill-opacity="1"
            fill-rule="nonzero"
        />
    </g>
);

// console.log("extension1 =>", extension1);

// Service data for TechnologiesWeWork section
const services_data_browser = [
    {
        name: "AI ML  Model/APIs",
        alt: "AI ML  Model/APIs",
        icon: "custom models",
    },
    {
        name: "HTML, CSS,\n Bootstrap, JS,\n Reacy",
        alt: "HTML, CSS,\n Bootstrap, JS,\n Reacy",
        icon: "validation",
    },
    {
        name: "Web Assembly",
        alt: "Web Assembly",
        icon: "engineering",
    },
    {
        name: "Web Audio API",
        alt: "Web Audio API",
        icon: "management",
    },
    {
        name: "Chrome APis",
        alt: "Chrome APis",
        icon: "manufacturing",
    },
];

const CaseData = [
    {
        heading: " Voice Commands",
        alt: " Voice Commands",
        // logo: "https://www.interactpro.ai/hubfs/raw_assets/public/InteractPro_Theme/images/Logo.svg",
        subheading: "Browser Control Through Voice Commands",
        para: "'Browser Control Through Voice Commands' is a free chrome extension that allows you to control your chrome browser through voice commands. You can add the extension to your chrome browser and then give commands to perform your task. ",
        btntext: "More On Voice Commands",
        // imgurl: VoiceCommand,

        caseurl: "/casestudy/browser-control-through-voice-commands",


    },
    {
        heading: "Interact Extension",
        alt: "Interact Extension",
        // logo: "https://idealeads.in/wp-content/uploads/2021/10/ideallead_Logo-e1634960127764.webp",
        subheading: "Interact Chrome Extension",
        para: "Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers.",
        btntext: "More On Interact Extension",
        // imgurl: InteractExtension,
        caseurl: "/casestudy/interact-extension",
    },
    {
        heading: "Transcript Extension",
        alt: "Transcript Extension",
        // logo: "https://meinshort.com/static/media/logo.320ce173.svg",
        subheading: "Live Transcript Extension",
        para: "You could want to record lectures from online meetings, online courses, podcasts, and streaming YouTube videos.",
        btntext: "More On Transcript Extension",
        // imgurl: LiveTranscript,
        caseurl: "/casestudy/capture-transcript-from-a-meet",
    },
];

const ZoomApp = ({ city }) => {

    return (
        <div>
            <SuperSEO
        title="Browser Extension Development Company"
        description="Lakebrains is the best chrome browser extension development services company. Lakebrains build high-quality cross-platform Chrome Extension plugins. Also we help in Migrate manifest V2 to V3."
        lang="en"
        twitter={{
          twitterSummaryCard: {
            // summaryCardImage: {BrowserImage},
            summaryCardImageAlt: 'img',
            summaryCardSiteUsername: "justinmahar",
          },
        }}
      />
            <ServicesTopBanner
                headTitle={
                    city
                        ? `Browser Extension Development company in ${city}`
                        : `Browser Extension Development company`
                }
                headDes="Our browser extension development services are designed in a secure, fast and highly personalized way to deliver a premium browsing experience to your end users and team members."
                // video={BrowserImage}
            />
            
            <ServicesBoxSection techtype="Build AI Powered Chrome Extension with Lakebrains Experts" />
            
            {/* 6 banners */}
            <NLP
                data={Bdata}
                title="Browser Extension"
                subTitle="Lakebrains has build deep expertise in chrome extension which is capable to do anything you want in your browser for yourself or your clients."
            />
            {/* <ServiceTechnologiesWeWork
                Cdata={FrontendData}
                title="Browser"
                subTitle="We have worked tirelessly to create a small and creative browser extension that handles huge amounts of data and complex transactions without sacrificing performance. As a result, our best browser extension development solution uses the latest technologies and ensures the highest quality."
            /> */}

            {/* CASE STUDY SECTION */}
            <CaseStudySection CaseData={CaseData} />
            <Whyus />
            <OurTestimonials />
            <ScheduleCall />
            <BlogSlider />
            <FAQ Fdata={Fdata} ServiceType="Browser Extension" />
        </div>
    );
};

export default ZoomApp;
