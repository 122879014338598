import React from "react";
import Casebanner from "./CaseBanner/Casebanner";
import data from "./casestudyInteractpro";
import { SuperSEO } from "react-super-seo";
import Bannnerimage from "../../../images/banner/interact.webp"

const InteractCaseStudy = () => {
  return (
    <>


      <SuperSEO
        title="Interact Hubspot - Lakebrains"
        description="Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers."
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: { Bannnerimage },
            summaryCardImageAlt: "Interact Hubspot",
            summaryCardSiteUsername: "Interact Hubspot",
          },
        }}
      />

      {data.map((items, key) => (
        <Casebanner key={key} data={items.data} />
      ))}
    </>
  );
};

export default InteractCaseStudy;
