import Bannnerimage from "../../../images/casestudy/meinshort.webp";

const data = [
  {
    slugurl: "/meinshort",
    data: [
      {
        Banner: [
          {
            sector:" Personal Branding/ Digital Networking",
            headingtext: "Meinshort",
            headingpara:
              "Grow your professional network by sharing all your personal and professional work through a single digital card",
            image: Bannnerimage,
          },
        ],
        heading: "Free Professional Card To Show Your Digital Presence",
        casestudy: {
          Problem:
            `The challenges involve creating a digital card as a PDF, ensuring security for contact details, and designing a mobile view display for the card in order to provide a free professional card that showcases one's digital presence.`,
          challenge: [
            {
              heading: "The Challenges ",
              challengeli: [
                {First:"To create a digital-card as pdf."},
                {First:"Security for contact details."},
                {First:"Mobile view display for the card."},
              ],
            },
            {
              heading: "Solutions",
              challengeli: [
                {First:"Used package for pdf card."},
                {First:"Used connections request as security so that individuals can connect and view their contact details with individual permission."},
                {First:"Used two components and combine two pages for mobile card display."},
              ],
            },
            {
              heading: "Impact",
              challengeli: [ {First:`By utilizing a package for creating PDF cards, implementing connection requests for contact detail security, and designing a mobile-friendly display, the solution provides users with a handy, secure, and easily accessible digital card. This enables individuals to effectively present their digital presence, enhance their professional image, and conveniently share their contact information with others.`},],
            },
            {
              heading: "Results",
              challengeli: [
                {First:"You will get a digital card that is handy, secure, and easy to access everywhere."},
              ],
            }
          ],
        },

        companyinfo: {
          logourl: "MeInShort",
          about: "About ",
          aboutpara:
            "MeinShort Is Changing The Way Professionals Grow Their Network By Making Digital Presence More Accessible To Everyone.",
          companysector: [
            {
              first: "INDUSTRY:",
              secound: "Social Networking",
            },
            {
              first: "HQ LOCATION:",
              secound: "Udaipur, Rajasthan",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
