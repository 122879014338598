import React from "react";

import data from "./OvoLinkedlnData";
import BannerImage from "../../../../images/CaseStudiesImagesLogo/Ovolinkedln.png"; 
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const OvoLinkedIn = () => {
    return (
        <>
            <SuperSEO 
                title="OVO - Streamlining LinkedIn Engagement"
                description="Generate customizable LinkedIn comments effortlessly with OVO, enhancing user engagement and interaction."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { BannerImage }, 
                        summaryCardImageAlt: "OVO - LinkedIn ",
                        summaryCardSiteUsername: "OVO",
                    },
                }}
            />

            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default OvoLinkedIn;
