import Logo from "../../../images/logo/logo_lakebrains.webp";
import Bannnerimage from "../../../images/banner/livetranscript.webp";

const data = [
  {
    slugurl: "/livetranscript",
    data: [
      {
        Banner: [
          {
            sector:"Learning/ Communication",
            headingtext: "Live Transcript Extension",
            headingpara: `You could want to record lectures from online meetings, online courses, podcasts, and streaming YouTube videos by our Live Transcript Extension
              `,
            image: Bannnerimage,
          },
        ],
        heading:
          "Want to transcribe meetings, online courses, youtube videos and podcasts?",
        casestudy: {
          Problem: `"Existing transcription extensions for web browsers are often limited to specific apps, requiring manual integration and charging high fees. Additionally, these extensions typically offer limited access to a limited number of meetings per month, hindering users' ability to transcribe effectively."`,
          challenge: [
            {
              heading: "How to use it?",
              challengeli: [
                {First:"Visit the chrome web store. Search Live Transcript Extension."},
                {First:"Select Add to Chrome."},
                {First:"Now pin the extension in the browser."},
                {First:"Click on the extension icon."},
                {First:"Click on the “Click here to get the real time transcript “to start your transcription process."},
                {First:"On clicking you will asked to choose the tab you want to get transcribe of. "},
                {First:"Once you are done, click on the download button, and you will get your transcribe being downloaded in the csv file."},
              ],
            },
            {
              heading:
                "The Challenge:-",
              challengeli: [
                {First:"Many of them are limited only to some apps available on the web i.e. they are platform dependent. Also manual integration also required."},
                {First:"Many of them charge very high"},
                {First:"Many of them have only 10 to 15 meetings available for a month i.e. limited access of extension. "},
              ],
            },
            {
              heading: "The Approach:-",
              challengeli: [
                {First:"This extension can be used to get transcript of any web app available i.e. it is not dependent on any platform or calling app."},
                {First:"It is open and free to use."},
                {First:"There is no limit of access to users."},
              ],
            },
            {
              heading: "Impact",
              challengeli: [ {First:`The introduction of the "Live Transcript Extension" addresses the limitations of existing transcription tools. It provides a platform-independent solution, allowing users to transcribe any web app. Moreover, the extension is open and free to use, ensuring accessibility for users without imposing limitations on their access. This enhances the transcription process for meetings, online courses, YouTube videos, and podcasts, contributing to improved learning experiences and increased efficiency in accessing transcriptions.`},],
            },
          ],
        },

        companyinfo: {
          logourl: Logo,
          videoUrl:
            "https://www.youtube.com/embed/3uU_pYW2hIU?si=wGWqyckKYl83v_Z2",
          about: "About ",
          aboutpara:
            "'Live Transcript Extension' is a Product of Lakebrains Technologies to transcribe meetings, online courses, youtube videos and podcasts. This extension is user friendly and free to use.",
          companysector: [
            {
              first: "INDUSTRY:",
              secound: "Learning",
            },
            {
              first: "HQ LOCATION:",
              secound: "Udaipur, Rajasthan",
            },
            {
              name: "Get Extension",
              url: "https://chrome.google.com/webstore/detail/live-transcript-extension/bbdgdpkamhamfdcbbkbooomiliaiahpn",
            },
          ],
        },
        customer: [
        ],
      },
    ],
  },
];

export default data;
