import Bannnerimage from "../../../images/logo/logo_lakebrains.webp";


const data = [
  {
    slugurl: "/interact",
    data: [
      {
        Banner: [
          {
            sector: "Networking/ Call Center",
            headingtext: "Interact Hubspot Website",
            headingpara:
              "Empower your sales team to build stronger relationships and close more deals with our super-charged AI Chrome extension for sales reps and robust web app for sales managers.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Turn all your Calls into AI-Powered conversation intelligence tools",
        casestudy: {
          Problem:
            `The challenge is to create the Interact website on a CRM Platform while ensuring a user-friendly and pixel-perfect interface, despite limitations in library support.`,
          challenge: [
            {
              heading: "The Challenge ",
              challengeli: [
                { First: "To create the Interact website on a CRM Platform." },
                { First: "The interface should be user-friendly and pixel-perfect." },
                { First: "CRM Platform doesn't support many libraries." },
              ],
            },
            {
              heading: "The Solution ",
              challengeli: [
                { First: "Use Hubspot CRM to create Interact Website." },
                { First: "Create Custom Component in HubL." },
              ],
            },
            {
              heading: "Results",
              challengeli: [{ First: "Created a website on Hubspot CRM with HubL." },],
            },
            {
              heading: "Impact",
              challengeli: [{ First: `The implementation of using Hubspot CRM and creating custom components in HubL allows for the successful creation of the Interact website. This enables the transformation of all calls into AI-powered conversation intelligence tools, providing valuable insights and enhancing communication effectiveness for users.` },],
            },
          ],
        },

        companyinfo: {
          logourl: "",
          about: "About ",
          aboutpara:
            "Anything we can do to eliminate sales rep stress, provide confidence on every call and presentation, and drive more sales is important to us.",
          companysector: [
            {
              first: "INDUSTRY: ",
              secound: "Networking / Call Center",

            },

            {
              first: "HQ LOCATION:",
              secound: "Ohio, Us",

            },
          ],
        },
        customer: [
        ],
      },
    ],
  },
];

export default data;
