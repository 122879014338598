import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/jainCommunity.png"; 
const data = [
  {
    slugurl: "/JainCommunityApp",
    data: [
      {
        Banner: [
          {
            sector: "Community/Technology",
            headingtext: "Jain Community App (S2S Connect)",
            headingpara:
              "S2S Connect is a mobile application designed to enhance networking and job opportunities within the Jain community, fostering connections and engagement.",
            image: Bannnerimage,
          },
                ],
                heading: "Connecting Communities with S2S Connect",
                casestudy: {
                    Problem:
                        "Secure user management, social connectivity features, streamlined job posting and application processes, robust backend infrastructure, intuitive frontend UI, and effective admin management.",
                    challenge: [
                        {
                            heading: "The Challenges:-",
                            challengeli: [
                                { First: "Secure user management and account authentication." },
                                { First: "Efficient job posting and application process." },
                                { First: "Intuitive frontend UI for a seamless user experience." },
                                { First: "Effective admin panel for managing users and posts." },
                            ],
                        },
                        {
                            heading: "The Solutions:-",
                            challengeli: [
                                { First: "Implement secure user account creation and management." },
                                { First: "Streamline job posting and application processes." },
                                { First: "Build a scalable backend with necessary APIs." },
                                { First: "Design a user-friendly frontend interface." },
                                { First: "Create an admin panel for effective management." },
                            ],
                        },
                        {
                            heading: "Impact",
                            challengeli: [
                                { First: "S2S Connect significantly enhances networking and job opportunities within the Jain community. By streamlining the recruitment process and fostering connections among users, the app promotes collaboration and engagement, creating a secure environment for meaningful interactions and relationships that strengthen the community as a whole." },
                            ],
                        },
                    ],
                },
                companyinfo: {
                    logourl: "jaincommunitylogo",
                    about: "About",
                    aboutpara:
                        "S2S Connect is a community-focused mobile application designed to connect users and enhance networking within the Jain community.",
                    companysector: [
                        {
                            first: "Company Name:",
                            secound: "Jain Community App (S2S Connect)",
                        },
                        {
                            first: "INDUSTRY:",
                            secound: "Community/Technology",
                        },
                        {
                            first: "LOCATION:",
                            secound: "Udaipur",
                        },
                    ],
                },
                customer: [],
            },
        ],
    },
];

export default data;
