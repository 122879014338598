import React from "react";
import { PopupButton } from "react-calendly";
import "./ConnectOurExperts.css";


const ConnectOurExperts = ({OurExperts, heading, subHeading, description,image}) => {
    return (
        <>
            <div className="ConnectOurExperts-container">
                <div className="OurCommitment-text d-flex text-center flex-column align-items-center" >
                    <h4>{subHeading}</h4>
                    <h2>{heading}</h2>
                    <p>{description}</p>
                </div>

                <div className="ConnectOurExperts-person d-flex align-items-center justify-content-center">
                    <div className="person_img"><img src={image} alt="" className="person_img" loading="lazy"/></div>
                    <div className="person_text">
                        <h2>{OurExperts}</h2>
                        <PopupButton
                            url="https://calendly.com/khushbu-jpao/30min?back=1&month=2024-05"
                            rootElement={document.getElementById("root")}
                            text="Schedule Meeting"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConnectOurExperts;
