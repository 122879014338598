import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'
import './BlogSlider.css'
import axios from 'axios'
import BBlog1 from '../../../images/Blogs/BBlog1.webp'
import BBlog2 from '../../../images/Blogs/BBlog2.webp'
import { Link } from 'react-router-dom'



const BlogSlider = () => {
    const [blog3, setBlog3] = useState();
    const [blogData, setBlogData] = useState()

    // useEffect(() => {
    //     axios.get("https://blogs.lakebrains.com/wp-json/wp/v2/posts?category_slug=browser-extension")
    //         .then((response) => {
    //             // console.log(response);
    //             // blog3(response)
    //             setBlog3(response);

    //         })
    //     // .catch((err) => console.log(err));

    // }, [])

    useEffect(() => {
        fetch(`https://blogs.lakebrains.com/wp-json/wp/v2/posts?category_slug=browser-extension`)
            .then(res => res.json())
            .then((res) => {
                setBlogData(res);
                console.log(res)
                console.log(blogData)
                // setError(null);
            })
            .catch((error) => {
                // setError(err.message);
                console.log(error)
                // setData(null);
            })
    }, []);

    const BlogWData = [
        {
            title: 'Publish a Chrome Extension to the Chrome Store.',
            description: 'The Guide to Getting Your Chrome Extension Published Introduction This blog gives a description of how you can upload your browser extension to the Chrome Web',
            image: BBlog1,
            link: 'https://blogs.lakebrains.com/publish-chrome-extension/',
            linktext: "More On Chrome Store"
        },
        {
            title: 'Publish a Chrome Extension to the Chrome Store.',
            description: 'The Guide to Getting Your Chrome Extension Published Introduction This blog gives a description of how you can upload your browser extension to the Chrome Web',
            image: BBlog1,
            link: 'https://blogs.lakebrains.com/publish-chrome-extension/',
            linktext: "More On Chrome Store"
        },
        // {
        //     title: '',
        //     description: '',
        //     image: '',
        //     link: '',
        // },
        // {
        //     title: '',
        //     description: '',
        //     image: '',
        //     link: '',
        // },
        {
            title: 'Migrating Manifest V2 to V3',
            description: 'Migrating Manifest V2 to V3 This blog lists changes being implemented as a part of Manifest V3, the next version of the Chrome Extensions platform. There',
            image: BBlog2,
            link: 'https://blogs.lakebrains.com/migrating_manifest_v2_to_v3/',
            linktext: "More On Manifest V2 to V3"
        },
        {
            title: 'Migrating Manifest V2 to V3',
            description: 'Migrating Manifest V2 to V3 This blog lists changes being implemented as a part of Manifest V3, the next version of the Chrome Extensions platform. There',
            image: BBlog2,
            link: 'https://blogs.lakebrains.com/migrating_manifest_v2_to_v3/',
            linktext: "More On Manifest V2 to V3"
        },
    ]





    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 4, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1204, min: 920 },
            items: 3,
            slidesToSlide: 2, // optional, default to 1.
        },
        smalltablet: {
            breakpoint: { max: 920, min: 620 },
            items: 2,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 620, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };
    // const Data = blog3
    // console.log(blog3);
    return (
        <div>
            <div className="">
                <div className="homepage-heading "><h4></h4><h2 className="text-capitalize"></h2><p></p></div>

                <Carousel
                    swipeable={true}
                    draggable={true}
                    //   showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={10000}
                    keyBoardControl={true}
                    customTransition="all 1s ease-in-out"
                    transitionDuration={1000}
                    containerclassName="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    //   deviceType={props.deviceType}
                    dotListclassName="custom-dot-list-style"
                    itemclassName="carousel-item-padding"
                    className=" text-center align-center pb-4 "
                >
                    {/* {console.log(blog3)} */}
                    {BlogWData.map((item, index) => (
                        <div className="blog-card-01" key={index}>
                            <div className="blog-card-header-01">
                                <img src={item.image} alt="Image" />
                            </div>
                            <div className="blog-card-body-01">
                                <span className="blog-tag-01 blog-tag-teal-01">Extension</span>
                                <h4>
                                    {item.title}
                                </h4>
                                <p>
                                    {item.description}
                                    {/* {item.description.length > 35 ? <span>{item.description.slice(0, 55)}...</span> : item.description} */}
                                </p>
                                <div className='d-flex justify-content-end w-100'><a href={item.link}><button className="btn blog-BlogReadMore-btn-01">{item.linktext}</button></a></div>
                                {/* <div className="user">Read More
                                    <img src="https://yt3.ggpht.com/a/AGF-l7-0J1G0Ue0mcZMw-99kMeVuBmRxiPjyvIYONg=s900-c-k-c0xffffffff-no-rj-mo" alt="user" />
                                    <div className="user-info">
                                        <h5>July Dec</h5>
                                        <small>2h ago</small>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    ))}

                    {/* <div className="card">
                        <div className="card-header">
                            <img src="https://www.newsbtc.com/wp-content/uploads/2020/06/mesut-kaya-LcCdl__-kO0-unsplash-scaled.jpg" alt="ballons" />
                        </div>
                        <div className="card-body">
                            <span className="tag tag-purple">Popular</span>
                            <h4>
                                How to Keep Going When You Don’t Know What’s Next
                            </h4>
                            <p>
                                The future can be scary, but there are ways to
                                deal with that fear.
                            </p>
                            <div className="user">
                                <img src="https://lh3.googleusercontent.com/ogw/ADGmqu8sn9zF15pW59JIYiLgx3PQ3EyZLFp5Zqao906l=s32-c-mo" alt="user" />
                                <div className="user-info">
                                    <h5>Eyup Ucmaz</h5>
                                    <small>Yesterday</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <img src="https://images6.alphacoders.com/312/thumb-1920-312773.jpg" alt="city" />
                        </div>
                        <div className="card-body">
                            <span className="tag tag-pink">Design</span>
                            <h4>
                                10 Rules of Dashboard Design
                            </h4>
                            <p>
                                Dashboard Design Guidelines
                            </p>
                            <div className="user">
                                <img src="https://studyinbaltics.ee/wp-content/uploads/2020/03/3799Ffxy.jpg" alt="user" />
                                <div className="user-info">
                                    <h5>Carrie Brewer</h5>
                                    <small>1w ago</small>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Carousel>

            </div>
        </div>
    )
}

export default BlogSlider