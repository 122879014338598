import React from "react";
import "./ourservise.css";
import whatImage from "../../../images/chrome-extension-develpment/WWimage.webp";

function ourservices() {
  return (
    <div className="container">
      <div className="os-main">
        <div className="os-texts">
          <h2 className="os-h1">
            Our services: Google Chrome AddOns Development
          </h2>
          <h1 className="os-h2">Empowering your Browser Experience</h1>
          <span className="os-p">
            At Lakebrains, we specialise in building custom chrome extensions
            that can help you achieve your goals for individuals or businesses.
          </span>
        </div>
        <div className="boxes-3">
          <div className="os-R1">
            <div className="w-box Services">
              <div className="d-flex flex-column os-b">
                <div className="w-boxImg">
                  <img className="img-fluid ServicesImg" alt="Logo" src={whatImage} />
                </div>
                <h1 className="box-h Services-h">
                  Custom Chrome Extension Development
                </h1>
                <p className="box-p Services-p">
                  We specialize in creating bespoke Chrome Plugins that align
                  with your business goals and enhance user experiences. We will
                  work with you to understand your needs and create a custom
                  solution that meets or exceeds your expectations.
                </p>
              </div>
            
            </div>
            <div className="w-box Services">
              <div className="d-flex flex-column os-b">
                <div className="w-boxImg">
                  <img className="img-fluid ServicesImg" alt="Logo" src={whatImage} />
                </div>
                <h1 className="box-h Services-h">
                  User Interface (UI) and User Experience (UX) Design
                </h1>
                <p className="box-p Services-p">
                  Create visually appealing and user-friendly Chrome plugins
                  that captivate your audience. Our talented designers will
                  craft intuitive UI designs and optimize the UX to ensure a
                  smooth and enjoyable user journey.
                </p>
              </div>
              
            </div>
            
          </div>
          <div className="os-R2">
            <div className="w-box Services">
              <div className="d-flex flex-column os-b">
                <div className="w-boxImg">
                  <img className="img-fluid ServicesImg" alt="Logo" src={whatImage} />
                </div>
                <h1 className="box-h Services-h">
                  Chrome Extension Testing and Quality Assurance
                </h1>
                <p className="box-p Services-p">
                  Ensure your Chrome addons performs flawlessly across different
                  environments and scenarios. This rigorous testing process
                  guarantees a seamless and error-free user experience on both
                  MacBooks and Windows systems.
                </p>
              </div>
             
            </div>
           
            <div className="w-box Services">
              <div className="d-flex flex-column os-b">
                <div className="w-boxImg">
                  <img className="img-fluid ServicesImg" alt="Logo" src={whatImage} />
                </div>
                <h1 className="box-h Services-h">
                  Chrome Extension Maintenance and Support
                </h1>
                <p className="box-p Services-p">
                  Our services don't end with development. We provide ongoing
                  maintenance and support to ensure your Chrome extension
                  remains up-to-date, secure, and compatible with the latest
                  Chrome browser updates. Our dedicated support team can address
                  any concerns and provide timely assistance.
                </p>
              </div>
            
            </div>
          </div>
          <div className="os-R2">
          <div className="w-box Services">
              <div className="d-flex flex-column os-b">
                <div className="w-boxImg">
                  <img className="img-fluid ServicesImg" alt="Logo" src={whatImage} />
                </div>
                <h1 className="box-h Services-h">
                  Streamlined Chrome Web Store Publishing Solutions
                </h1>
                <p className="box-p Services-p">
                  Let us handle the process of publishing your Chrome extension
                  on the Chrome Web Store. We will guide you through the
                  submission requirements, optimize your extension listing for
                  better discoverability, and ensure a smooth publishing
                  process.
                </p>
              </div>
            
            </div>
            <div className="w-box Services">
              <div className="d-flex flex-column os-b">
                <div className="w-boxImg">
                  <img className="img-fluid ServicesImg" alt="Logo" src={whatImage} />
                </div>
                <h1 className="box-h Services-h">
                  Integration with AI Models and API like ChatGPT
                </h1>
                <p className="box-p Services-p">
                  Experience advanced capabilities with our Chrome extensions,
                  integrating AI models and powerful APIs like ChatGPT for
                  enhanced functionality and user interactions. 
                </p>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ourservices;
