import React from "react";
import "./ServicesTopBanner.css";

const ServicesTopBanner = ({ headTitle, headDes, video }) => {
  return (
    <>
      <div className="container-fluid example-classname vh-100 LakeHome--Wrapper-000-0">
        <div className="container ">
          <div className="LakeHome-CaseS-Wrapper">
            <div className="">
              <div className="">
                <div className="LakeHome-CaseS-HeadingContainer">
                  {/* <div className="LakeHome-CaseS-Heading m-auto">
                    <h4>Problem's We Solve</h4>
                    <h2>Case Study</h2>
                    <p className="m-auto px-5 px-md-0">
                      One of the most notable examples is the rise of artificial
                      intelligence (AI). Lakebrains helped startups to automate
                      various tasks, such as customer service, marketing, sales
                      through AI.
                    </p>
                  </div> */}
                </div>
                <div className="LakeHome-CaseS-CorouselWrapper">
                  <div className="LakeHome-CaseS-Container">
                    <div>
                      <div className=" py-4 mx-3 Lake-Case-Carousel-Container">
                        <div className="col-md-6 order-md-1 order-2 Lake-Case-Carousel-Wrapper d-flex justify-content-center align-items-center">
                          <div className="LaKeHome-Case-Carousel-TextWrapper">
                            <div className="row LakeHome-CaseS-Heading text-left banner_content-service">
                              {/* <h2 className="col-12 my-5">Natural Language Procesing</h2> */}
                              <h1 className="col-12 mb-2 px-0 ">{headTitle}</h1>
                              {/* <h4 className="my-2 col-12 px-5 px-md-0">
                                Undersand Humans Behavior and Emotions Through Power of NLP.
                              </h4> */}
                              <p className="my-2 col-12 px-0">{headDes}</p>
                              <div>
                              <a href="/api/empathy/" >
                                  <button className="NLP-Btn">{"Empathy"}</button>
                                </a>
                              </div>
                              {/* <div className="col-6 Lake-Case-Carousel-Btn mt-5">
                                <a href={"#"}>
                                  <button>{"Schedule Meeting"}</button>
                                </a>
                              </div>
                              <div className="col-6 Lake-Case-Carousel-Btn mt-5">
                                <a href={"#"}>
                                  <button>{"Case Studies"}</button>
                                </a>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 lakeH-banner-nlp  d-flex justify-content-center align-items-center order-md-2 order-1">
                          <div className="Lake-Case-Carousel-ImageWrapper">
                            <div className="Lake-Case-Carousel-image">
                              <img className="w-100 h-100" src={video} alt="video" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesTopBanner;
