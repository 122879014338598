import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/LinkNavigator.png";

const data = [
  {
    slugurl: "/LinkedinSales",
    data: [
      {
        Banner: [
          {
            sector:"Productivity/ Technology",
            headingtext: "Linkedin Sales Navigator Data Scraping",
            headingpara:
              " Linkedin Sales Navigator Data Scraping is a puppeteer script to scrap people data from sales navigators.",
            image: Bannnerimage,
          },
        ],
        heading:
          " Get better leads from linkedin sales navigator",
        casestudy: {
          Problem:
            `The Implementation allows us to input a LinkedIn Sales Navigator URL and specify the number of pages to scrape. It logs in to LinkedIn, retrieves session cookies for persistent login, and extracts information such as full name, job title, company name, company URL, location, and LinkedIn profile URL for each profile on the specified number of pages.`,
          challenge: [
            {
              heading: "The Challenges:- ",
              challengeli: [
                { First: "Input Handling to make it easy to use." },
                { First: "Avoid authenticate linkedin again and again" },
                { First: "Handle pagination dynamically." },
                { First: "Web Scraping" },
                { First: "Store data in csv file" },
                { First: "Error Handling" },
              ],
            },
            {
              heading: "Solutions:-",
              challengeli: [
                { First: "The script accepts command-line arguments, including the LinkedIn Sales Navigator URL (url) and the number of pages to scrape (last_page). It performs basic argument validation to ensure the provided inputs are valid." },
                { First: "If session cookies (cookies.json) exist, the script loads them to maintain a persistent login session. Otherwise, it logs in using provided credentials and saves the session cookies for future use." },
                { First: "The script iterates through each page till the last_page by specifying page no in the query parameter of the url." },
                { First: "It scrolls down the page to load more profiles dynamically and waits for the profiles to load. For each profile on the page, it extracts information such as full name, job title, company name, company URL, location, and LinkedIn profile URL." },
                { First: "The extracted data is stored in a CSV file (output.csv) using the json2csv library. The script appends data to the CSV file, creating a new file if it doesn't exist or adding rows without headers if the file already exists" },
                { First: "The script includes error handling to handle potential issues during web scraping, such as missing elements or network errors." },
              ],
            },
            {
              heading: "Benefits:-",
              challengeli: [
                { First: "Automation: The script automates the tedious task of manually extracting LinkedIn profile information." },
                { First: "Efficiency: Users can scrape multiple pages of profiles quickly, saving time." },
                { First: "Data Consolidation: Extracted data is stored in a structured CSV file for further analysis or use." },
                { First: "Reusability: The session cookies enable persistent login, reducing the need to log in repeatedly." },
              ],
            },
          ],
        },

        companyinfo: {
          logourl:
            "yasmin",
          about: "About ",
          aboutpara:
            "The Implementation allows us to input a LinkedIn Sales Navigator URL and specify the number of pages to scrape. It logs in to LinkedIn, retrieves session cookies for persistent login, and extracts information such as full name, job title, company name, company URL, location, and LinkedIn profile URL for each profile on the specified number of pages.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Lakebrains Technologies",
            },
            {
              first: "INDUSTRY:",
              secound: "Productivity",
            },
            {
              first: "LOCATION:",
              secound: "Udaipur, Rajasthan",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
