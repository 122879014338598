import React from "react";
import Casebanner from "./CaseBanner/Casebanner";
import data from "./casestudylivetranscriptext";
import Bannnerimage from "../../../images/banner/livetranscript.webp";
import { SuperSEO } from "react-super-seo";

const LiveTranscriptCaseStudy = () => {
  return (
    <>

      <SuperSEO
        title="Live Transcript - Lakebrains"
        description="You could want to record lectures from online meetings, online courses, podcasts, and streaming YouTube videos by our Live Transcript Extension"
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: { Bannnerimage },
            summaryCardImageAlt: "Live Transcript",
            summaryCardSiteUsername: "Live Transcript",
          },
        }}
      />


      {data.map((items, key) => (
        <Casebanner key={key} data={items.data} />
      ))}
    </>
  );
};

export default LiveTranscriptCaseStudy;
