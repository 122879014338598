import React from "react";
import Feature1 from "../../../../images/LiveTranscriptionExtension/transcript_feature/Puzzle1.png";
import Feature2 from "../../../../images/LiveTranscriptionExtension/transcript_feature/Puzzle2.png";
import Feature3 from "../../../../images/LiveTranscriptionExtension/transcript_feature/Puzzle3.png";
import Feature4 from "../../../../images/LiveTranscriptionExtension/transcript_feature/Puzzle4.png";
import Feature5 from "../../../../images/LiveTranscriptionExtension/transcript_feature/Puzzle5.png";
import Feature6 from "../../../../images/LiveTranscriptionExtension/transcript_feature/Puzzle6.png";
import Feature7 from "../../../../images/LiveTranscriptionExtension/transcript_feature/Puzzle7.svg";

import "./LiveTranscriptFeature.css";

const LiveTranscriptFeature = () => {
  return (
    <>
      <div className="container-lg transcript-feature-container">
        <div className="">
          {/* d-flex justify-content-between */}
          <h1 className="transcript-feature-heading d-flex justify-content-center">
            Feature
          </h1>
          <div className="row align-items-center live-transcript-feature">
            <div className="transcript-feature-img col-6">
              <img className="feature-img1" src={Feature1} alt="" />
              <img className="feature-img3" src={Feature3} alt="" />
              <img className="feature-img6" src={Feature6} alt="" />
              <img className="feature-img2" src={Feature2} alt="" />
              <img className="feature-img5" src={Feature5} alt="" />
              <img className="feature-img4" src={Feature4} alt="" />
            </div>
            <div className="transcript-feature-radio col-6">
              <div className="feature-content d-flex align-items-center">
                {" "}
                <img className="feature-img7" src={Feature7} alt="" />
                <span className="feature-option">Universal Platform Support</span>
              </div>
              <div className="feature-content d-flex align-items-center">
                {" "}
                <img className="feature-img7" src={Feature7} alt="" />
                <span className="feature-option">Security & Privacy Assurance:</span>
              </div>
              <div className="feature-content d-flex align-items-center">
                {" "}
                <img className="feature-img7" src={Feature7} alt="" />
                <span className="feature-option">Unrestricted Word and Time Usage:</span>
              </div>
              <div className="feature-content d-flex align-items-center">
                {" "}
                <img className="feature-img7" src={Feature7} alt="" />
                <span className="feature-option">100% Free:</span>
              </div>
              <div className="feature-content d-flex align-items-center">
                {" "}
                <img className="feature-img7" src={Feature7} alt="" />
                <span className="feature-option">No Registration or Credit Card Needed</span>
              </div>
              <div className="feature-content d-flex align-items-center">
                {" "}
                <img className="feature-img7" src={Feature7} alt="" />
                <span className="feature-option">Download Transcribe</span>
              </div>
              <div className="feature-content d-flex align-items-center">
                {" "}
                <img className="feature-img7" src={Feature7} alt="" />
                <span className="feature-option">Integration with Google Drive</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveTranscriptFeature;
