import React from "react";
import "./what.css";
// import whatImage from "../../../images/chrome-extension-develpment/WWimage.svg";
import whatImage from "../../../images/chrome-extension-develpment/WWimage.webp";

function what() {
  return (
    <div className="container">
      <div className="what-main">
        <div className="what-texts">
          <h2 className="what-h1">Reasons our clients Rely on us</h2>
          <h1 className="what-h2">Custom Chrome Extension Development</h1>
          <p className="what-p">
            Our team of experts tailors solutions to meet their specific needs
            to their Chrome Plugins, providing high-quality results that surpass
            expectations. With efficient workflows, we deliver projects on time
            and within budget
          </p>
        </div>
        <div className="boxes-3">
          <div className="what-R1">
            <div className="w-box">
              <div className="w-boxImg">
                <img className="img-fluid AIimg" alt="Logo" src={whatImage} />
              </div>
              <h1 className="box-h">Personalization</h1>
              <p className="box-p">
                Allowing you to mold your browsing experience to make your
                preferences and requirements. 
              </p>
            </div>
            <div className="w-box">
              <div className="w-boxImg">
                <img className="img-fluid AIimg"  alt="Logo" src={whatImage} />
              </div>
              <h1 className="box-h">Expertise</h1>
              <p className="box-p">
                Experience in building custom Chrome Addons that can meet your
                specific needs. 
              </p>
            </div>
          </div>
          <div className="what-R2">
            <div className="w-box">
              <div className="w-boxImg">
                <img className="img-fluid AIimg" alt="Logo" src={whatImage} />
              </div>
              <h1 className="box-h">User-Friendly Solutions</h1>
              <p className="box-p">
                Our development process prioritizes user experience, focusing on
                creating intuitive and user-friendly plugins to enrich your
                browsing journey.
              </p>
            </div>
            <div className="w-box">
              <div className="w-boxImg">
                <img className="img-fluid  AIimg" alt="Logo" src={whatImage} />
              </div>
              <h1 className="box-h">Secure and reliable</h1>
              <p className="box-p">
                We ensure that your data is safe and that your Chrome Plugin
                works smoothly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default what;
