import React from "react";

import data from "./DaisyZendeskExtensionData";
import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/DaisyZendesk.png";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const DaisyZendeskExtension = () => {
    return (
        <>
            <SuperSEO
                title="Daisy Zendesk - Lakebrains"
                description="The Zendesk Thread Summarizer Chrome extension boosts workflow efficiency by leveraging OpenAI's GPT-4 and Daisy AI API to extract and summarise ticket thread information."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "Daisy Zendesk",
                        summaryCardSiteUsername: "Daisy Zendesk",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default DaisyZendeskExtension;
