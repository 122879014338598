import React from "react";
// This is for Front end SEction ;
import next from "../../../../images/OurtechStack/nextjs.webp";
import react from "../../../../images/OurtechStack/react.webp";
import express from "../../../../images/OurtechStack/express.svg";
import html from "../../../../images/OurtechStack/html5.svg";
import css from "../../../../images/OurtechStack/css3.svg";
import bootstrap from "../../../../images/OurtechStack/bootstrap.svg";
import less from "../../../../images/OurtechStack/less.svg";
import sass from "../../../../images/OurtechStack/sass.svg";
import extension from "../../../../images/OurtechStack/extension.svg";
import javascript from "../../../../images/OurtechStack/javascript.svg";
import jquery from "../../../../images/OurtechStack/jquery.svg";
import sails from "../../../../images/OurtechStack/sails.webp";

// This is for backend section
import mysql from "../../../../images/OurtechStack/mysql.svg";
import mongodb from "../../../../images/OurtechStack/mongodb.svg";
import api from "../../../../images/OurtechStack/api.webp";
import django from "../../../../images/OurtechStack/django.svg";
import flask from "../../../../images/OurtechStack/flask.webp";
import nodejs from "../../../../images/OurtechStack/nodejs.svg";
import ServicesTopBanner from "../ServicesTopBanner/ServicesTopBanner";
import ServicesBoxSection from "../ServicesBoxSection/ServicesBoxSection";
import ServiceTechnologiesWeWork from "../ServiceTechnologiesWeWork/ServiceTechnologiesWeWork";
import NLP from "../ServiceOurServices/NLP";
import Whyus from "../../../Comman/Why Us/Whyus";
import OurTestimonials from "../../SubSection/ourtestimonials/OurTestimonials";
// import TechnologiesWeWork from '../TechnologiesWeWork/TechnologiesWeWork';
import { Helmet } from "react-helmet";
import { SuperSEO } from "react-super-seo";

import ai_based_web from "../../../../images/web_development_icons/ai_based.svg";
import hubspot from "../../../../images/web_development_icons/hubspot.svg";
import saas_web from "../../../../images/web_development_icons/saas.svg";
import mern from "../../../../images/web_development_icons/mern.svg";
import visualization from "../../../../images/web_development_icons/visualization.svg";
import web_scraping from "../../../../images/chrome_extension_icons/web_scraping.svg";
import ScheduleCall from "../../../Comman/ScheduleCall/ScheduleCall";
import CaseStudySection from "../../../Comman/CaseStudy/CaseStudy";
import WebImage from "../../../../images/banner/WebServiceImage.webp";
import FAQ from "../../../Comman/FAQ/FAQ";

import casestudy1 from "../../../../images/casestudy/Ideleads.webp";
import casestudy2 from "../../../../images/casestudy/Interact.webp";
import casestudy3 from "../../../../images/casestudy/mein-short.webp";
import TechnologiesWeWork from "../ServiceOurProcessNLP/ServiceOurProcessNLP";

const FrontendData = [
  {
    image: next,
    h6: "Next.js",
    alt: "Next.js",
  },
  {
    image: react,
    h6: "React.js",
    alt: "React.js",
  },
  {
    image: javascript,
    h6: "Javascript",
    alt: "Javascript",
  },
  {
    image: extension,
    h6: "Extension",
    alt: "Extension",
  },
  {
    image: jquery,
    h6: "Jquery",
    alt: "Jquery",
  },
  {
    image: html,
    h6: "Html5",
    alt: "Html5",
  },
  {
    image: css,
    h6: "Css3",
    alt: "Css3",
  },
  {
    image: bootstrap,
    h6: "Bootstrap",
    alt: "Bootstrap",
  },
  {
    image: less,
    h6: "Less",
    alt: "Less",
  },
  {
    image: sass,
    h6: "Sass",
    alt: "Sass",
  },
  {
    image: nodejs,
    h6: "Node.js",
    alt: "Node.js",
  },
  {
    image: express,
    h6: "Express.js",
    alt: "Express.js",
  },
  {
    image: flask,
    h6: "Flask",
    alt: "Flask",
  },
  {
    image: django,
    h6: "Django",
    alt: "Django",
  },
  {
    image: api,
    h6: "Fast API",
    alt: "Fast API",
  },
  {
    image: sails,
    h6: "Sails.js",
    alt: "Sails.js",
  },
  {
    image: mongodb,
    h6: "MongoDB",
    alt: "MongoDB",
  },
  {
    image: mysql,
    h6: "MySQL",
    alt: "MySQL",
  },
];

// Service data for TechnologiesWeWork section
const services_data_web = [
  {
    name: "AI ML  Model/APIs",
    alt: "AI ML  Model/APIs",
    icon: "custom models",
  },
  {
    name: "HTML, CSS,\n Bootstrap, JS, \nReacy",
    alt: "HTML, CSS,\n Bootstrap, JS, \nReacy",
    icon: "validation",
  },
  {
    name: "Web Assembly",
    alt: "Web Assembly",
    icon: "engineering",
  },
  {
    name: "AWS EC2, ECS, Lambda, \nSagemaker",
    alt: "AWS EC2, ECS, Lambda, \nSagemaker",
    icon: "management",
  },
  {
    name: "Hubspot CMS\n Wordpress CMS",
    alt: "Hubspot CMS\n Wordpress CMS",
    icon: "manufacturing",
  },
  {
    name: "Node Js,  Django, \nFlask",
    alt: "Node Js,  Django, \nFlask",
    icon: "technical",
  },
];

const Bdata = [
  {
    image: saas_web,
    title: "Web Based SaaS Products",
    alt: "Web Based SaaS Products",
    Link: "https://blogs.lakebrains.com/useful-array-methods-in-javascript/",
    LinkText:"More On SaaS Products",
    description:
      "Lakebrains Technologies, a leading company in the field of software product development, is definitely your 'effective assistant,' with a team of professional programmers, standard operating procedures, and  guaranteed quality of software. research, develop, launch, and maintain  product concepts.",
      href:"/web-application-development-company/web-based-saas-product/",
      Case: [
      {
        image: "",
        CaseTitle: "Sentiment Analysis / Intent Analysis ",
        CaseDescribtion:
          "Companies may understand better what their consumers are saying about them by using NLP-based sentiment analysis. This method of machine learning automatically analyzes data and analyzes the text's emotion.",
      },
    ],
  },
  {
    image: web_scraping,
    title: "AI Enabled Web Application",
    alt: "AI Enabled Web Application",
    Link: "https://blogs.lakebrains.com/useful-array-methods-in-javascript/",
    LinkText:"More On AI Web Application",
    description:
      "Lakebrains Technologies is a leading software development company in India specialising in providing advanced Artificial Intelligence (AI) and Machine Learning (ML) development services. Explore our technology solutions to scale your business with AI and ML.",
      href:"/web-application-development-company/ai-web-application/",   
    Case: [
      {
        image: "",
        CaseTitle: "MERN stack Web Development",
        CaseDescribtion:
          "As the leading MERN Stack development company, we help global companies build innovative web applications with attractive user interfaces that promise customer attention. Hire MERN Stack developers to help you build custom software solutions.",
      },
    ],
  },
  {
    image: mern,
    title: "MERN stack Web Development",
    alt: "MERN stack Web Development",
    Link: "https://blogs.lakebrains.com/useful-array-methods-in-javascript/",
    LinkText:"More On Web Development",
    description:
      "As the leading MERN Stack development company, we help global companies build innovative web applications with attractive user interfaces that promise customer attention. Hire MERN Stack developers to help you build custom software solutions.",
      href:"/web-application-development-company/mern-stack-web-development/",
      Case: [
      {
        image: "",
        CaseTitle: "Confidence Analysis Model ",
        CaseDescribtion:
          "Use this file however you want!It’s yours, free, for personal or commercial use.",
      },
    ],
  },
  {
    image: visualization,
    title: "Web Based Data Visualizer",
    alt: "Web Based Data Visualizer",
    Link: "https://blogs.lakebrains.com/useful-array-methods-in-javascript/",
    LinkText:"More On Data Visualizer",
    description:
      "Lakebrains Technologies continuously ingests data from your old and modern data sources so you can analyze the data in transit and feed new data to your business intelligence tools.",
      href:"/web-application-development-company/web-data-visualizer/",
      Case: [
      {
        image: "",
        CaseTitle: "Confidence Analysis Model ",
        CaseDescribtion:
          "Use this file however you want!It’s yours, free, for personal or commercial use.",
      },
    ],
  },
  {
    image: hubspot,
    title: "HubSpot CMS Development",
    alt: "Hubspot CMS Development",
    Link: "https://blogs.lakebrains.com/useful-array-methods-in-javascript/",
    LinkText:"More On CMS Development",
    description:
      "Lakebrains create complete websites and web applications using HubSpot CMS development. We make use of our industry knowledge and technical know-how to produce beautiful, growth-oriented websites quickly and within budget.",
      href:"/web-application-development-company/hubspot-cms-development/",
      Case: [
      {
        image: "",
        CaseTitle: "Confidence Analysis Model ",
        CaseDescribtion:
          "Use this file however you want!It’s yours, free, for personal or commercial use.",
      },
    ],
  },
];

const Fdata = [
  {
    heading: "Web Development",
    subData: [
      {
        accordionHead: "How much does it cost to build a Web application?",
        accordionPara: "The features, complexity, development time, and level of customization of a web application all influence its cost.\nFeatures: The number of third-party integrations, APIs, content types, mobile compatibility, and complexity of the database, among other things.\nDesign for UI/UX: Compared to ready-made design templates, fully customizable user interface UI/UX design apps are more expensive.\nThe project's complexity: The price will be higher if your project requires the expertise and skills of skilled, experienced developers.\nIf you have any requirements, please let us know, and a reputable web application development company will give you a free estimate of costs and a schedule.",
        id: "1",
      },
      {
        accordionHead: "What is the difference between Web App and SaaS?",
        accordionPara: "Web apps are only meant to be accessed through a web browser.Server-side scripts like ASP.NET, PHP, and so on are combined in these.and script on the client side (HTML, JavaScript, etc.).The web browser, or thin client, retrieves web server components installed in the system's backend infrastructure in order to provide the system's core functional web services.Examples of web applications include Flipkart, Medium, Facebook, Twitter, Smashing Magazine, and others.\nSaaS, on the other hand, is a more advanced web application.It's also used to access services like web apps over the internet, but it doesn't always need the web browser to work.SaaS products are designed to facilitate seamless integration with resilient back-end infrastructure and a richer user customization experience. Evernote, Salesforce, Hubspot, Zoho, and Dropbox are a few typical examples. Etc.",
        id: "2",
      },
      {
        accordionHead: "Will I have a dedicated full-time developer?",
        accordionPara: "Yes! Throughout the duration of your project, you will have your own dedicated developers who will work at the appropriate hours.You can use our ready-to-use IT infrastructure and dedicated leased lines to avoid interruptions to your work. ",
        id: "3",
      },
      {
        accordionHead: "Will we need a meeting to talk about the idea of making a web app?",
        accordionPara: "Yes you have. Only after implementing your app idea can you develop an app that fits your purpose and price. After the initial meeting, we used project management tools to discuss the progress of the application from time to time.",
        id: "4",
      },
      {
        accordionHead: "How can I connect with LakeBrains Technologies to develop a web application?",
        accordionPara: "The process begins when we receive your request. Our team will analyse your requirements and respond with an offer. To proceed, you can choose the engagement model that best suits you. Then start the development process.",
        id: "5",
      },
      {
        accordionHead: "How to apply AI in web development?",
        accordionPara: "If you want to build an AI-powered web application, you need to start by designing an architecture that will integrate different AI models. To make it 'future proof' we recommend not just focusing on the samples you are starting with. Don't just focus on temporary needs. Instead, try to identify all the places where there is potential to be powered by AI in the future, regardless of the data you currently have or your current business processes, needs, and priorities. As a result, you will be able to plan the entire architecture of this new system in a way that will allow the integration of other artificial intelligence models in the future.",
        id: "6",
      },
      {
        accordionHead: "Why should you custom build your SaaS platform?",
        accordionPara: "No two companies in the same industry are the same. Each company has its own set of requirements and procedures. In order to stay ahead of the competition, businesses must adapt to customer demands in order to keep up with shifting markets. Having software that matches specific processes is the best approach.Choosing software that evolves with your business is rarely an easy task.Since applications are typically developed for entire industries, it can be challenging to adapt them to your company's specific needs.SaaS platforms clearly benefit from this. They are extremely adaptable because they are built with web technologies. To put it another way, they are much simpler to modify to suit the requirements of your company as opposed to those of a third party's product.You own the entire concept and product with a custom-built SaaS platform.Its design and features are entirely up to you.",
        id: "7",
      },
      {
        accordionHead: "Do I have full ownership of the project, if so, do you provide documentation?",
        accordionPara: "Yes, You will have full ownership of your project as well as the source code. In addition, we provide you with the NDA, which includes copyright, source code, intellectual property rights, and more.",
        id: "8",
      },
    ],
  },
];

const CaseData = [
  {
    heading: "Interact",
    alt: "Interact",
    logo: "https://www.interactpro.ai/hubfs/raw_assets/public/InteractPro_Theme/images/Logo.svg",
    subheading: "Interact Technologies",
    para: "Give your sales representative an AI-powered prospecting tool that ensures they engage with prospects and close more deals. At the same time, Interact provides guidance on what to say, when to say it, and at the same time, coaches your sales reps on good behaviour and emotional intelligence. It's all done in real-time.",
    btntext: "More On Interact",

    imgurl: casestudy2,

    caseurl: "/casestudy/meinshort",
  },
  {
    heading: "Idealeads",
    alt: "Idealeads",
    logo: "https://idealeads.in/wp-content/uploads/2021/10/ideallead_Logo-e1634960127764.webp",
    subheading: "Genrate Links For Rapid Growth",
    para: "Deploy a Lead Generation Tool as a SaaS Tool on the Web. So customers can easily access the tool from anywhere.",
    btntext: "More On Idealeads",
    imgurl: casestudy1,
    caseurl: "/casestudy/idealeads",
  },
  {
    heading: "Meinshort",
    alt: "Meinshort",
    logo: "https://meinshort.com/static/media/logo.320ce173.svg",
    subheading: "Create your Professional digital card",
    para: "Make a perfect professional digital card in 5-10 minutes that helps to grow your professional network. Create a professional card today for free.",
    btntext: "More On Meinshort",
    imgurl: casestudy3,
    caseurl: "/casestudy/interact",
  },
];

const WebApplication = ({ city }) => {
  return (
    <div>


      <SuperSEO
        title="Custom Web App Development Company- Lakebrains"
        description="Lakebrains is the best custom web application development company. Lakebrains develop custom react js based web apps which are secure and user friendly."
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: {WebImage},
            summaryCardImageAlt: 'img',
            summaryCardSiteUsername: "justinmahar",
          },
        }}
      />

      <ServicesTopBanner
        headTitle={
          city
            ? `Web Application Development Services in ${city}`
            : `Web Application Development Services`
        }
        headDes="Lakebrains Scales The Digital Transformation Of Growing Businesses Using Its Strong Service Portfolio To Develop Custom Web Applications Using Advanced Technologies And Tools."
        video={WebImage}
      />
      <ServicesBoxSection techtype="Build AI Powered Web Application with Lakebrains Experts" />
      <NLP
        data={Bdata}
        title="Web Development"
        subTitle="We offer the best app solutions to help you propel your business into the digital age as a leader in web application development."
      />
      <ServiceTechnologiesWeWork
        Cdata={FrontendData}
        title="web"
        subTitle="Our web development services not only create beautiful applications, but they are also feature-rich, easy-to-use, scalable, and universally compatible."
      />
      {/* <TechnologiesWeWork Sdata={services_data_web} title="Web Dev" subTitle="Web app development is the core of Lakebrains Technologies and we do it the way you want. We don't just build web apps, we help you every step of the way. We have an innovative way of working and we always aim for concrete results." /> */}
      <CaseStudySection CaseData={CaseData} />
      <Whyus />
      <OurTestimonials />
      <ScheduleCall />
      <FAQ ServiceType="Web Application" Fdata={Fdata} />
    </div>
  );
};

export default WebApplication;
