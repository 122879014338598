import React, { useEffect } from "react";
import "./LiveTranscriptHeader.css";
import Logo from "../../../../images/logo/logo_lakebrains.webp";
const LiveTranscriptHeader = () => {
  useEffect(() => {
    const bodyHasClassA = document.querySelector(".live-transcript-header");

    if (bodyHasClassA) {
      const bodyHasClassb = document.querySelector(".header-wrap");
      const bodyHasClassc = document.querySelector(".footer");
      bodyHasClassc.style.display = "none";
      bodyHasClassb.style.display = "none";
    }
  });

  return (
    <>
      <div className="container-lg live-transcript-header">
        <div className="d-flex justify-content-between navcon">
          <div className="d-flex navIcon">
            <a href="/">
              <img className="logoImg" src={Logo} alt="" />
            </a>
          </div>
          <div className="navBtn">
            <button
              className="transcript-header-btn-1"
              onClick={() => {
                window.open(
                  "https://chrome.google.com/webstore/detail/live-transcript-extension/bbdgdpkamhamfdcbbkbooomiliaiahpn"
                );
              }}
            >
              Getting Started
            </button>
            <button
              className="transcript-header-btn-2"
              onClick={() => {
                window.open(
                  "https://calendly.com/khushbu-jpao/30min?back=1&month=2024-05"
                );
              }}
            >
              Request a demo
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveTranscriptHeader;
