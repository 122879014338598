import React from "react";
import { PopupButton } from "react-calendly";
import "./ServicesBoxSection.css";

const ServicesBoxSection = ({ techtype }) => {
  return (
    <div>
      <div className="container">
        <div className="BoxSection-container1-wrapper text-center">
          <h3 className=" font-w-700 h4">{techtype}</h3>
          <div className=" justify-content-center">
            <div className="">
              <div className="  ">

                <PopupButton
                  className="btn BoxSection-Btn mt-3"
                  text="Schedule Meeting"
                  url="https://calendly.com/khushbu-jpao/30min?back=1&month=2024-05"
                  rootElement={document.getElementById("root")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesBoxSection;
