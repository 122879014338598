import React, { useEffect } from 'react'
import './CitiesFooter.css'
import city from '../../routes/ITCities.json'
import { useLocation } from "react-router-dom"

const CitiesFooter = () => {
    const location = useLocation()
 
    if (window.location.href == 'https://lakebrains.com/nlp-service-provider-company/') {
        return (
            <div className='row  FooterCitiesLink-wrapper'>
                {city.map((cities, index) => (
                    <div className='FooterCitiesLink' key={index}>
                        {/* {`web Services in ${cities.Cities}`} */}
                        <a href={`https://lakebrains.com/nlp-service-provider-company-in-${cities.Cities}/`}> {`Nlp services in ${cities.Cities}`}</a>
                    </div>
                ))}
            </div>
        )
    }
    else if (window.location.href == 'https://lakebrains.com/browser-extension-development-company') {
        return (
            <div className='row  FooterCitiesLink-wrapper'>
                {city.map((cities, index) => (
                    <div className='FooterCitiesLink' key={index}>
                        {/* {`web Services in ${cities.Cities}`} */}
                        <a href={`https://lakebrains.com/browser-extension-development-company-in-${cities.Cities}/`}> {`Browser services in ${cities.Cities}`}</a>
                    </div>

                ))}
            </div>
        )
    }
    else if (window.location.href == 'https://lakebrains.com/web-application-development-company/') {
        return (
            <div className='row  FooterCitiesLink-wrapper'>
                {city.map((cities, index) => (
                    <div className='FooterCitiesLink' key={index}>
                        {/* {`web Services in ${cities.Cities}`} */}
                        <a href={`https://lakebrains.com/web-application-development-company-in-${cities.Cities}/`}> {`Web services in ${cities.Cities}`}</a>
                    </div>

                ))}
            </div>
        )
    }


}

export default CitiesFooter