import React from "react";
import data from "./PreScouter_UserData";
import Bannnerimage from "../../../../images/casestudy/texthighlight.webp";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const TextHighlights = () => {
    return (
        <>
            <SuperSEO
                title="PreScouterUser - Lakebrains"
                description="sales and other department executives of PreScouter to know about all the past interactions"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "PreScouterUser",
                        summaryCardSiteUsername: "PreScouterUser",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default TextHighlights;
