import React from "react";
import Casebanner from "./../CaseBanner/Casebanner";
import data from "./casestudyCommunication";
import { SuperSEO } from "react-super-seo";
import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/Squire.png";

const CommunicationCaseStudy = () => {
  return (
    <>


      <SuperSEO
        title="Squire integration - Lakebrains"
        description="To extend the existing integration functionalities in Squire to include BT Cloud (RingCentral), ensuring seamless interaction between the two platforms, easy access for users, and effective data processing."
        lang="en"
        twitter={{
          twitterSummaryCard: {
            summaryCardImage: { Bannnerimage },
            summaryCardImageAlt: "Squire integration",
            summaryCardSiteUsername: "Squire integration",
          },
        }}
      />

      {data.map((items, key) => (
        <Casebanner key={key} data={items.data} />
      ))}
    </>
  );
};

export default CommunicationCaseStudy;
