import React from "react";

import data from "./SquireZoomMarketplaceData";
import Bannnerimage from "../../../../images/casestudy/SQUIRE_Img.jpg";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const SquireZoomMarketplace = () => {
    return (
        <>
            <SuperSEO
                title="SquireZoom - Lakebrains"
                description="Empower recruiters with seamless Zoom integration."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "SquireZoom",
                        summaryCardSiteUsername: "SquireZoom",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default SquireZoomMarketplace;
