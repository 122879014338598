import React from "react";

import "./techStackSaas.css";

const TechStackSaas = ({ techDataInfo }) => {


  return (
    <div className="example-classname contianer-fluid mb-5">
      <div className="ourtechstack-wrapper container px-0 mb-5">
        <div className="homepage-heading ">
          <h4>TECHNOLOGIES WE WORK WITH?</h4>
          <h2>Our Tech Stack</h2>
          <p>Our web develpment services not only create beautiful applications, but they are also feature-rich, ease-to-use, scalable, and universal compatible.</p>
        </div>
        <div className=" d-flex flex-column-reverse">
          <div className="container-fluid row mx-0  px-0 ourtechstack-sub-wrapper">

            {techDataInfo.map((dataBlock, index) => {
              return (
                <div className="col-lg col-md ots-tech-items-endborder-000 px-0 ">
                  <div className="px-md-0 px-3 text-center pb-4 row justify-content-around d-flex  ots-tech-items-wrapper ots-tech-items-wrapper2 border-d-xaxis flex-direction- mx-0">
                    {dataBlock.info.map((data, i) => {
                      return (
                        <div className="ots-tech-items col-5" key={i}>
                          <a href={data.link}>
                            <div className="ots-tech-items-01">
                              <img src={data.logo} alt={data.alt} loading="lazy"/>
                            </div>
                            <div className="pt-2 w-100">{data.name}</div>
                          </a>
                        </div>
                      )
                    })}
                  </div>
                  <div className=" text-center ots-heading">{dataBlock.name}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechStackSaas;
