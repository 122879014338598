import React from 'react';
import './connect.css';
import MrKamlesh from '../../../images/chrome-extension-develpment/unnamed.png'
import { PopupButton } from "react-calendly";

function connect() {
  return (
    <div className='container-fluid c-bg'>
    <div className='connect-main  '>
        <div className='connect-texts'>
            <h1 className='connect-h1'>Connect with our experts </h1>
            <h1 className='connect-h2'>Let us start your Project Today</h1>
            <p className='connect-p'>
            Unlock the true potential of your browser with our exceptional Chrome extension plugins and AddOns. Contact LakeBrains today to get started on your journey to enhanced browsing!

            </p>
        </div>
        <div className='k-div'>
            <div className='k-imgD'>
                <img className='k-img' alt='Kamlesh Image' src={MrKamlesh}/>
            </div>
            <div className='k-divt'>
            <h1 className='k-h'>CHITRANSH JAIN</h1>
            <p className='k-p'>	CEO  Lakebrains Technologies</p>
            {/* <button type="button" className="">Schedule Meeting</button> */}
            <PopupButton
              className="con-btn"
              url="https://calendly.com/khushbu-jpao/30min?back=1&month=2024-05"
              rootElement={document.getElementById("root")}
              text="Schedule Meeting"
            />
            </div>
        </div>

        <div className='connect-2 '>
        <div className='c-1 '>
             <h1 className='c-h'>Talk to One of Our Industry Experts</h1>
             <p className='c-p'>An expert of our team will work with you to understand your goals </p>
        </div>
        <div className='c-1 '>
             <h1 className='c-h'>Work With hand-selected Talent</h1>
             <p className='c-p'> Within days, we'll introduce you to the right talent for your project. The average time to match is less than 24 hours.</p>
        </div>
        <div className='c-1 '>
             <h1 className='c-h'>The Right Fit, Guaranteed</h1>
             <p className='c-p'>Work with your new team member on a trial basis (pay only if satisfied), ensuring you hire the right people for the job. </p>
        </div>

        </div>
    </div>
    </div>
  )
}

export default connect