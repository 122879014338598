import React from "react";

import data from "./Seven3BitWebData";
import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/Bitlogo.png";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const Seven3BitWeb = () => {
    return (
        <>
            <SuperSEO
                title="73bit - Lakebrains"
                description="Innovating technology solutions for your business success and growth."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { Bannnerimage },
                        summaryCardImageAlt: "73bit",
                        summaryCardSiteUsername: "73bit",
                    },
                }}
            />


            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default Seven3BitWeb;
