import Bannnerimage from "../../../images/banner/infaticalogo.webp";

const data = [
  {
    slugurl: "/videodownloader",
    data: [
      {
        Banner: [
          {
            sector:" Media/ Entertainment",
            headingtext: "Video Detector Extension",
            headingpara:
              "Video downloader by Detector Video is a valuable chrome extension for downloading videos from social media sites.",
            image: Bannnerimage,
          },
        ],
        heading:
          " This chrome extension gives you the capability to download videos from different sites including social media platforms",
        casestudy: {
          Problem:
            `"Existing Chrome extensions for downloading videos from different sites, including social media platforms, face challenges related to creating a SaaS tool using the Django framework and connecting automation tools to the framework. Additionally, limitations in the media APIs and deprecated APIs further complicate the development process."`,
          challenge: [
            {
              heading: "How to use it:-",
              challengeli: [
                {First:"Download Detector Video Chrome Extension"},
                {First:"Open any site from the web you want to download"},
                {First:"Scroll to the video you want to download"},
                {First:"Click on the extension icon"},
                {First:"Click on the start button it will show the videos available to download"},
                {First:"See the preview of the video you want to download"},
                {First:"Click on the download button it will relocate to the download link"},
                {First:"Steps Video => https://drive.google.com/file/d/1gVieXN9iTjnHwPQsNA7xWdlQBfwBm-Oq/view?usp=share_link"},
              ],
            },
            {
              heading: "The Challenges:-",
              challengeli: [
                {First:"Create SaaS tool using Django Framework."},
                {First:"Connect all automation tool with Django framework."},
              ],
            },
            {
              heading: "The Approaches ",
              challengeli: [
                {First:"Run all the libraries in background/service worker (media APIs are not working in service worker so unable to run those libraries in service worker)"},
                {First:"fix webRequestBlocking API in manifest v3 because it is deprecated in manifest v3."},
                {First:"Start background script and terminate background script on button click."},
                {First:"We run all libraries in a dynamic popup window because it works the same as the service worker and supports media APIs."},
                {First:"We changed the “webRequestBlocking” API with its alternative 'declarativeNetRequest' to solve the issue."},
                {First:"We added a start/stop button in the popup as per the requirement to start and stop dynamic popup windows respectively."},
              ],
            },
            {
              heading: "Impact",
              challengeli: [ {First:`The implementation of the solution addresses the challenges faced by existing video downloading extensions. By running all the necessary libraries in a dynamic popup window, the extension is able to overcome limitations related to service workers and media APIs. The use of alternative APIs and the inclusion of a start/stop button in the popup window enhance the functionality and user experience. Overall, the solution provides users with an efficient and reliable tool to download videos from various sites, contributing to improved accessibility and convenience in video content consumption.              `},],
            },
          ],
        },

        companyinfo: {
          logourl: "infaticaLogo",
          about: "About ",
          aboutpara:
            " Video downloader by Detector Video is a valuable chrome extension to download videos from any website you want.",
          companysector: [
            {
              first: "INDUCompany Name:",
              secound: "Infatica",
            },
            {
              first: "INDUSTRY:",
              secound: "Video Downloader",
            },
            {
              first: "LOCATION:",
              secound: " Netherlands",
            },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
