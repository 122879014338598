import Bannnerimage from "../../../../images/CaseStudiesImagesLogo/FloomyLogo.svg";


const data = [
  {
    slugurl: "/FloomyWeb",
    data: [
      {
        Banner: [
          {
            sector: "Recruitment/ Technology",
            headingtext: "Floomy",
            headingpara:
              "Unleashing creativity and productivity through seamless project management solutions.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Simplifying advanced fibre networks through GlobalConnect.",
        casestudy: {
          Problem:
            `Floomy is a cutting-edge platform designed to streamline project management processes. With a focus on intuitive user experience, it offers a suite of collaboration tools that empower teams to work efficiently and effectively. From task management to file sharing and communication, Floomy.io enhances productivity and project coordination.`,
          challenge: [
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Managing complex code dependencies efficiently while ensuring scalability." },
                { First: "Optimising application performance across diverse devices and platforms." },
                { First: "Ensuring data security and privacy compliance in a constantly evolving regulatory environment." },
                { First: "Scaling infrastructure to meet fluctuating user demand without compromising performance." },
              ],
            },
            {
              heading: "The Solutions:",
              challengeli: [
                { First: "Implement a modular architecture with clear interfaces and version control." },
                { First: "Utilise performance profiling tools and optimise code for specific hardware/software configurations." },
                { First: "Implement robust encryption standards, access controls, and regularly audit data handling practices." },
                { First: "Utilize cloud-based solutions with auto-scaling capabilities and load balancing algorithms." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: `Floomy impact extends beyond streamlined project management, influencing team dynamics and productivity. By fostering clear communication, task transparency, and efficient collaboration, it cultivates a positive work culture. This translates into quicker project completion, improved resource utilisation, and ultimately, enhanced business outcomes and customer satisfaction.` },],
            },
          ],
        },

        companyinfo: {
          logourl:
            "yasmin",
          about: "About ",
          aboutpara:
            "Floomy empowers creativity and productivity with its seamless project management solutions, enabling efficient collaboration and effective task management..",
          companysector: [
            {
              first: "Company Name:",
              secound: "Lakebrains Technologies",
            },
            {
              first: "INDUSTRY:",
              secound: "Recruitment/ Technology",
            },
            {
              first: "LOCATION:",
              secound: "Udaipur",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
