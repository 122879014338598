import Bannnerimage from "../../../../images/casestudy/SQUIRE_Img.jpg";

const data = [
  {
    slugurl: "/SquireZoom",
    data: [
      {
        Banner: [
          {
            sector: "Recruitment/ Technology",
            headingtext: "Zoom App (Phone/Meeting)",
            headingpara:
              "Squire's Zoom Marketplace App lets you easily capture conversations with candidates and push the recording to the Squire application for processing, automatically updating your ATS with the relevant candidate information and an AI-generated recruiter write-up.",
            image: Bannnerimage,
          },
        ],
        heading:
          "Empower recruiters with seamless Zoom integration.",
        casestudy: {
          Problem:
            `"Introducing our Zoom app, designed to empower recruiters with enhanced capabilities for managing candidate interactions seamlessly within the Zoom ecosystem. By automating call recording and data extraction, recruiters can focus on fostering meaningful connections while ensuring accurate information flows effortlessly into their Applicant Tracking System (ATS). With features tailored to reduce mental load and maximise the value of every interaction, our Zoom app revolutionises the recruitment process, offering efficiency and precision at every step."`,
          challenge: [
            {
              heading: "WHY USE THIS ZOOM APP?",
              challengeli: [
                { First: "Increase Recruiter Capacity: Seamlessly integrate with Zoom, enhancing recruiters' ability to handle more candidates efficiently." },
                { First: "Reduce Recruiter's Mental Load: Automate call recording and data extraction, relieving recruiters of tedious tasks." },
                { First: "Accurate and Valuable Info in Your ATS: Ensure that your Applicant Tracking System (ATS) receives precise and relevant data from Zoom meetings and calls." },
                { First: "Capture Value in Every Interaction: Maximise the value of recruiter-candidate interactions by capturing and analysing information from every Zoom call and meeting." },
              ],
            },
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "OAuth Login: Implement OAuth login through Google and LinkedIn, along with email authentication using Django API." },
                { First: "Background Audio Capture: Record Zoom phone and meeting calls in the background, sending audio chunks securely to the Django server after call completion." },
                { First: "Secure User Input Capture: Safely capture user inputs during calls and meetings, sending them to the server with additional context." },
                { First: "Dynamic Question Display: Retrieve job type-specific questions from AWS database and display them dynamically based on user selection." },
                { First: "Capturing Transcripts: Extract closed captions or transcripts from Zoom meetings in real-time and send them securely to the backend." },
              ],
            },
            {
              heading: "Solutions",
              challengeli: [
                { First: "Seamless Integration with Zoom: Utilise Zoom APIs to support Zoom phone calls and meeting calls, enabling accurate data transfer to the ATS along with meeting and call recordings." },
                { First: "Background Audio Capture: Detect phone and meeting calls to send meeting recordings securely to Squire's backend after call completion." },
                { First: "Secure User Input Handling: Utilise local storage to collect user inputs during calls and meetings, and upon call completion, send the data to the Django API along with additional call context." },
                { First: "Dynamic Question Display: Fetch job type-specific questions from the database via API and dynamically present them in the Zoom app's interface." },
                { First: "Transcription Handling: Implement mechanisms to handle meeting recordings without scraping, ensuring compliance with data privacy and security regulations." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [{ First: `The impact of our Zoom app on users' work is substantial. By seamlessly integrating with Zoom, recruiters experience a significant increase in capacity, allowing them to handle a higher volume of candidate interactions efficiently. With automated call recording and accurate data extraction, recruiters can focus more on building relationships and less on administrative tasks, reducing their mental load and improving productivity. The ability to capture valuable information from every interaction ensures that recruiters have access to precise data in their ATS, leading to more informed decision-making and better candidate management. Overall, our Zoom app streamlines the recruitment process, saving time, enhancing productivity, and ultimately improving outcomes for recruiters and candidates alike.` },],
            },
          ],
        },

        companyinfo: {
          logourl:
            "Bannnerimage",
          about: "About ",
          aboutpara:
            "Squire is a product of https://olas.io/ which is developed by Lakebrains Technologies. Squire's Chrome Extension lets you easily capture conversations with candidates and save the recording for processing an AI-generated recruiter write-up.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Squire",
            },
            {
              first: "INDUSTRY:",
              secound: "Software Development",
            },
            {
              first: "LOCATION:",
              secound: "Ireland",
            },
          ],
        },
        customer: [

        ],
      },
    ],
  },
];

export default data;
