import React, { useEffect } from "react";
import "./CaseStudySlider.css";
import Ceo1 from "../../../../images/chrome-extension-develpment/Eric_Hansen 1.svg";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import Ceo2 from '../../../../images/chrome-extension-develpment/ceo1.svg';
import Ceo3 from '../../../../images/chrome-extension-develpment/ceo2.svg';
import Ceo4 from '../../../../images/chrome-extension-develpment/ceo3.svg';
import Testimonials from '../../../../images/NewHomePage/Testimonials.svg';

import { Link } from "react-router-dom";


const Slideshow = () => {
    const slides = [
        {
            image: Ceo1,
            head: 'Eric Hansen',
            comapany: 'smartrepl.ai',
            text: "So, as we've built out my application Interact technologies, which will be released shortly after the new year, they've taken over all programming, such as Website Development, Chrome Extension, NLP, Keyword Extraction, and on the Back-end, building out HubSpot CMS, and what's nice is that everything is communicating really well. I think probably the best part of working with LakeBrains Technologies is the relationship that we have built that goes way beyond the Client- Vendor Relationship."
        },

        {
            image: Ceo2,
            head: 'Jack Sands',
            comapany: 'Interact',
            text: "I've been working with Chitransh for a while now and he never ceases to amaze me!! You are a very smart business person and an amazing programmer. He documents everything that's done using Jira, Miro, Figma, and other tools. This makes the building process very organized and in the end produces exactly what we need. We rely exclusively on Chitransh to handle all of our programming. He built an incredible chrome extension and web app utilizing AI machine learning. In addition he built our website using HubSpot CMS. We will be using Chitransh for ever!",

        },
        {
            image: Ceo3,
            head: 'Joseph Ugalde',
            comapany: 'Systems X',
            text: 'We worked with Chitransh and team to develop a Chrome Extention that allows one of our customers to add more functionalities inside their Cloud ERP system. The communication was clear, professional and the timing always was right. Their guidance and support through the project was key to have a happy customer. Keep it up the good work.',

        },
        {
            image: Ceo4,
            head: 'Boston Consulting Group',
            comapany: 'BCG',
            text: 'Overall, I was very happy with the Data Scraping. The quality of the data was up to the mark and encapsulated everything that I had asked for. You guys also had a great turnaround time for the job. Thanks for all the help!',
        },
    ];
    const [current, setCurrent] = React.useState(0);

    const nextSlide = () => {
        setCurrent(current === slides.length - 1 ? 0 : current + 1);
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? slides.length - 1 : current - 1);
    }

    useEffect(() => {
        // Define a function to switch to the next slide
        const handleNextSlide = () => {
            nextSlide();
        };

        // Set up an interval to change the slide every 5 seconds (adjust the duration as needed)
        const intervalId = setInterval(handleNextSlide, 5000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [current]);

    return (
        <div className="slid-main ">
            <div className='test-slider'>
                {slides.map((slide, index) => {
                    return (
                        <div className="CaseStudySlider-test-2 "
                            key={index}
                            style={{ display: index === current ? 'block' : 'none' }}
                        >


                            <span className="">{slide.text}</span>
                            <div className="ceo-idiv">
                                <img className='ceo-image' src={slide.image} alt="slide" loading="lazy"/>
                                <div className="t-div">
                                    <h1 className="ceo-name">{slide.head}</h1>
                                    <span className="CEO">CEO , {slide.comapany}</span>

                                </div>
                            </div>
                            <img className="Testimonials-bg" src={Testimonials} alt="slide"  loading="lazy"/>
                        </div>
                    )
                })}
                <div className='test-slid-btns NewSlider-Btn'>

                    <FontAwesomeIcon className="left1" icon={faCircleArrowLeft} onClick={prevSlide}></FontAwesomeIcon>
                    <FontAwesomeIcon className="right1" icon={faCircleArrowRight} onClick={nextSlide}></FontAwesomeIcon>

                </div>
            </div>
        </div>

    );
}

function CaseStudySlider() {
    return (
        <div className="container">
            <div className="test-main d-flex justify-content-center">
                <div className="CaseStudySlider-text ">
                    <div>
                    <h4 className="">OUR TESTIMONIALS</h4>
                    <h2 className="">What Clients Say About Us</h2>
                    <p className="">
                        We deliver the best results by creating dynamic, custom, and scalable experiences. Check out our testimonials.
                    </p>
                    </div>

                    <div className="ProblemSolving-button test-btn d-flex justify-content-end" style={{paddingRight:"15%"}}>
                        <Link target="_parent" to="https://testimonial.to/lakebrains-technologies/all">
                            <button>View all</button>
                        </Link>
                    </div>
                </div>

                <Slideshow />
            </div>
        </div>
    );
}

export default CaseStudySlider;

