import React from 'react'
import ReactPlayer from 'react-player'
import './Ourtestimonials.css'


const Popup1 = (props) => {

    return props.trigger ?
        (
            <div className='SaveHome-Meeto-Corosulpopup d-flex justify-content-center align-items-center'>
                <div className='SaveHome-Meeto-Corosulpopupopacity'>
                    <div>
                        <div className='close-btn' onClick={() => props.onClick(false)}>X</div>
                        <div className='vedio-container'>
                            <ReactPlayer
                                width='70vw'
                                height='70vh'
                                controls={true} url={props.url} />
                        </div>
                    </div>
                </div>
            </div >
        ) : (' ');
}

export default Popup1