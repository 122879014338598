import BannerImage from "../../../../images/CaseStudiesImagesLogo/BankTransaction.png";

const data = [
  {
    slugurl: "/BankTransaction",
    data: [
      {
        Banner: [
          {
            sector: "Finance/Technology",
            headingtext: "Bank Transaction Scraper",
            headingpara:
              "This extension automates the scraping of transaction data from bank statements, enhancing accuracy and efficiency for financial services.",
            image: BannerImage,
          },
        ],
        heading:
          "Streamlining Financial Operations with Automated Transaction Data Scraping",
        casestudy: {
          Problem:
            `Manual scraping of transaction data is time-consuming and error-prone, requiring a solution that automates this process efficiently.`,
          challenge: [
            {
              heading: "The Challenges:-",
              challengeli: [
                { First: "Manual data scraping is labor-intensive and inconsistent." },
                { First: "Varying formats across different banks complicate automation." },
                { First: "Time inefficiency detracts from productivity." },
              ],
            },
            {
              heading: "The Solution:-",
              challengeli: [
                { First: "User-friendly design for easy navigation." },
                { First: "Automated scraping of transaction data." },
                { First: "Customizable intervals for timely data retrieval." },
                { First: "Processing and formatting of scraped data." },
              ],
            },
            {
              heading: "Impact",
              challengeli: [
                { First: `The extension enhances accuracy and efficiency, significantly reducing manual effort for financial institutions and accounting firms.` },
              ],
            },
          ],
        },

        companyinfo: {
          logourl: "banktransaction",
          about: "About ",
          aboutpara:
            "The Automated Transaction Data Scraping Extension provides a reliable and efficient solution for banks and financial institutions, revolutionizing transaction data retrieval.",
          companysector: [
            {
              first: "INDUSTRY:",
              secound: "Technology/Finance",
            },
            {
              first: "LOCATION:",
              secound: "Udaipur, India",
            },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
