import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./service.css";

import Behaviour from "../../../../images/NLP_icons/behaviour.svg";
import Emotions from "../../../../images/NLP_icons/emotions.svg";
import ChatBots from "../../../../images/NLP_icons/chat_bot.svg";
import Keyword from "../../../../images/NLP_icons/keyword_extraction.svg";
import Topic from "../../../../images/NLP_icons/topic_modelling.svg";
import AudioToText from "../../../../images/NLP_icons/audio_to_text.svg";
import Notes from "../../../../images/NLP_icons/class-notes-svgrepo-com.svg";

import nlpVideo from "../../../../images/Videos/nlp_side_video.webm";

import Ai_Based from "../../../../images/chrome_extension_icons/ai_based.svg";
import Automation from "../../../../images/chrome_extension_icons/automation.svg";
import Migration from "../../../../images/chrome_extension_icons/migration_v2_to_v3.svg";
// import React_based from "../../../../images/chrome_extension_icons/react_based.svg";
import chatgpt from "../../../../images/chrome_extension_icons/chatgpt-icon.svg";
import saas_based from "../../../../images/chrome_extension_icons/saas_based.svg";
import web_scraping from "../../../../images/chrome_extension_icons/web_scraping.svg";

import extension_video from "../../../../images/Videos/browser_extension_video.webm";

import ai_based_web from "../../../../images/web_development_icons/ai_based.svg";
import hubspot from "../../../../images/web_development_icons/hubspot.svg";
import saas_web from "../../../../images/web_development_icons/saas.svg";
import mern from "../../../../images/web_development_icons/mern.svg";
import visualization from "../../../../images/web_development_icons/visualization.svg";
import web_video from "../../../../images/Videos/web_development_video.webm";

const Service = () => {
  return (
    <>
      <div className="services-section example-classname">
        <div className="container">
          <div className="homepage-heading">
            <h4>What We Do?</h4>
            <h2>Our Services</h2>
            <p>
              At Lakebrains technologies, we offer a suite of client-focused,
              specially tailored design and development services for both web
              and mobile platforms.
            </p>
          </div>
          <div className="tabsForServices">
            <Tabs>
              <TabList>
                <Tab>SaaS Product Engineering</Tab>
                <Tab>AI/ML Development</Tab>
                <Tab>Cloud Deployment</Tab>
              </TabList>

              {/* Browser Extension Tabpanel*/}

              <TabPanel>
                <div className="secondTabWrap tab-cntnt">
                  <div className="row service-bg-row">
                    <div className="col-md-5">
                      <div className="sideImageWrap">
                        <video
                          muted
                          autoPlay={"autoplay"}
                          preload="auto"
                          loop
                          alt="Browser Extension Development Company"
                        >
                          <source src={extension_video} type="video/webm" />
                        </video>
                      </div>
                    </div>
                    <div className="col-md-7 ServiceSection">
                      <div className="content">
                        <div className="technologies">
                          <div className="row justify-content-center">
                            <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/chrome-browser-extension-development-company/">
                                  <img
                                    src={Ai_Based}
                                    alt="Browser Extension with AI "
                                  />
                                  <p>Web</p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/chrome-browser-extension-development-company/">
                                  <img
                                    src={saas_based}
                                    alt="Browser Extension For SaaS Products"
                                  />
                                  <p>Mobile</p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/chrome-browser-extension-development-company/">
                                  <img
                                    src={Migration}
                                    alt="Migration From Manifest V2 to V3"
                                  />
                                  <p>Plugin</p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a
                                  href="/
                                /chrome-browser-extension-development-company/"
                                >
                                  <img
                                    src={chatgpt}
                                    alt="React Based Browser Extension"
                                  />
                                  <p>Chatgpt Based Browser Extension</p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/chrome-browser-extension-development-company/">
                                  <img
                                    src={Automation}
                                    alt="Extension For Web Automation"
                                  />
                                  <p>Extension For Web Automation</p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/chrome-browser-extension-development-company/">
                                  <img
                                    src={web_scraping}
                                    alt="Extension Based Data Extractor"
                                  />
                                  <p>Extension Based Data Extractor</p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

              {/* Web Development Tabpanel */}

              <TabPanel>
                <div className="thirdTabWrap tab-cntnt">
                  <div className="row service-bg-row">
                    <div className="col-md-5">
                      <div className="sideImageWrap">
                        {/* <img src={Service_Tab} alt="Service NLP" /> */}
                        <div>
                          {" "}
                          <video
                            // className=""
                            muted
                            autoPlay={"autoplay"}
                            preload="auto"
                            loop
                            alt="Web App Develpment Company"
                          >
                            <source src={web_video} type="video/webm" />
                          </video>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="content">
                        {/* <div className="head">
                          <h4>
                            Lakebrains has proven experience of working on state
                            of art NLP algorithms.
                          </h4>
                        </div> */}
                        <div className="technologies">
                          <div className="row justify-content-center">
                            <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/web-application-development-company/">
                                  <img
                                    src={saas_web}
                                    alt="Web Based SaaS Products"
                                  />
                                  <p>Generative AI</p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/web-application-development-company/">
                                  <img
                                    src={ai_based_web}
                                    alt="AI Enabled Web Application"
                                  />
                                  <p>NLP</p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/web-application-development-company/">
                                  <img
                                    src={mern}
                                    alt="MERN Stack Web Development"
                                  />
                                  <p>Computer Vision</p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/web-application-development-company/">
                                  <img
                                    src={visualization}
                                    alt="Web Based Data Visualizer"
                                  />
                                  <p>Web Based Data Visualizer</p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/web-application-development-company/">
                                  <img
                                    src={hubspot}
                                    alt="HubSpot CMS Development"
                                  />
                                  <p>HubSpot CMS Development</p>
                                </a>
                              </div>
                            </div>
                            {/* <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <img src={Keyword} alt="Keyword Extraction" />
                                <p>Keyword Extraction</p>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        {/* <div className="caseStudyWrap">
                          <div className="head underline">
                            <h3>Case Study</h3>
                          </div>
                          <div className="casestudy-slider">
                            <Carousel
                              showArrows={true}
                              showThumbs={false}
                              showStatus={false}
                              showIndicators={false}
                            >
                              <div>
                                <div className="slideWrap">
                                  <div className="">
                                    <div className="row tabSlide">
                                      <div className="col-lg-4">
                                        <img src={Service_Tab} alt="" />
                                      </div>
                                      <div className="col-lg-8">
                                        <div className="inner-cntnt">
                                          <h3>Case Study Title</h3>
                                          <p>
                                            Lorem ipsum dolor sit, amet
                                            consectetur adipisicing elit. Facere
                                            aliquid iste, placeat quam quaerat
                                            facilis ratione laboriosam corrupti
                                            illo nobis.
                                          </p>
                                          <a href="#" className="link">
                                            Read More
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="slideWrap">
                                  <div className="">
                                    <div className="row tabSlide">
                                      <div className="col-lg-4">
                                        <img src={Service_Tab} alt="" />
                                      </div>
                                      <div className="col-lg-8">
                                        <div className="inner-cntnt">
                                          <h3>Case Study Title</h3>
                                          <p>
                                            Lorem ipsum dolor sit, amet
                                            consectetur adipisicing elit. Facere
                                            aliquid iste, placeat quam quaerat
                                            facilis ratione laboriosam corrupti
                                            illo nobis.
                                          </p>
                                          <a href="#" className="link">
                                            Read More
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="slideWrap">
                                  <div className="">
                                    <div className="row tabSlide">
                                      <div className="col-lg-4">
                                        <img src={Service_Tab} alt="" />
                                      </div>
                                      <div className="col-lg-8">
                                        <div className="inner-cntnt">
                                          <h3>Case Study Title</h3>
                                          <p>
                                            Lorem ipsum dolor sit, amet
                                            consectetur adipisicing elit. Facere
                                            aliquid iste, placeat quam quaerat
                                            facilis ratione laboriosam corrupti
                                            illo nobis.
                                          </p>
                                          <a href="#" className="link">
                                            Read More
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Carousel>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

              {/* NLP Tabpanel */}
              <TabPanel>
                <div className="firstTabWrap tab-cntnt">
                  <div className="row service-bg-row">
                    <div className="col-md-5">
                      <div className="sideImageWrap">
                        <div>
                          <video
                            muted
                            autoPlay={"autoplay"}
                            preload="auto"
                            loop
                            alt="NLP Service Provider Company"
                          >
                            <source src={nlpVideo} type="video/webm" />
                          </video>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="content">
                        <div className="technologies">
                          <div className="row justify-content-center">
                            <div className="col-md-5 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/nlp-service-provider-company/">
                                  <img
                                    src={Behaviour}
                                    alt="Behavior Analysis in NLP"
                                  />
                                  <p>AWS</p>
                                </a>
                              </div>
                            </div>
                            {/* <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/services/nlp-service-provider-company/">
                                <img src={Emotions} alt="Emotion Analysis in NLP" />
                                <p>Emotion Analysis</p>
                                </a>
                              </div>
                            </div> */}
                            <div className="col-md-5 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/nlp-service-provider-company/">
                                  <img
                                    src={ChatBots}
                                    alt="Enterprise Chatbots"
                                  />
                                  <p>GCP</p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-5 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/nlp-service-provider-company/">
                                  <img
                                    src={AudioToText}
                                    alt="Audio to Text in NLP"
                                  />
                                  <p>Azure</p>
                                </a>
                              </div>
                            </div>
                            {/* <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/services/nlp-service-provider-company/">
                                <img src={Topic} alt="Topic Modeling in NLP" />
                                <p>Topic Modeling</p>
                                </a>
                              </div>
                            </div>
                            <div className="col-md-4 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="/services/nlp-service-provider-company/">
                                <img src={Keyword} alt="Keyword Extraction in NLP" />
                                <p>Keyword Extraction</p>
                                </a>
                              </div>
                            </div> */}

                            <div className="col-md-5 col-6  singleTechWrap">
                              <div className="singleTech">
                                <a href="s/nlp-service-provider-company/">
                                  <img
                                    src={Notes}
                                    alt="Keyword Extraction in NLP"
                                  />
                                  <p>Note Captures</p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;









// import React from "react";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";
// import "./service.css";

// import Behaviour from "../../../../images/NLP_icons/behaviour.svg";
// import Emotions from "../../../../images/NLP_icons/emotions.svg";
// import ChatBots from "../../../../images/NLP_icons/chat_bot.svg";
// import Keyword from "../../../../images/NLP_icons/keyword_extraction.svg";
// import Topic from "../../../../images/NLP_icons/topic_modelling.svg";
// import AudioToText from "../../../../images/NLP_icons/audio_to_text.svg";
// import Notes from "../../../../images/NLP_icons/class-notes-svgrepo-com.svg";

// import nlpVideo from "../../../../images/Videos/nlp_side_video.webm";

// import Ai_Based from "../../../../images/chrome_extension_icons/ai_based.svg";
// import Automation from "../../../../images/chrome_extension_icons/automation.svg";
// import Migration from "../../../../images/chrome_extension_icons/migration_v2_to_v3.svg";
// // import React_based from "../../../../images/chrome_extension_icons/react_based.svg";
// import chatgpt from "../../../../images/chrome_extension_icons/chatgpt-icon.svg";
// import saas_based from "../../../../images/chrome_extension_icons/saas_based.svg";
// import web_scraping from "../../../../images/chrome_extension_icons/web_scraping.svg";

// import extension_video from "../../../../images/Videos/browser_extension_video.webm";

// import ai_based_web from "../../../../images/web_development_icons/ai_based.svg";
// import hubspot from "../../../../images/web_development_icons/hubspot.svg";
// import saas_web from "../../../../images/web_development_icons/saas.svg";
// import mern from "../../../../images/web_development_icons/mern.svg";
// import visualization from "../../../../images/web_development_icons/visualization.svg";
// import web_video from "../../../../images/Videos/web_development_video.webm";

// const Service = () => {
//   return (
//     <>
//       <div className="services-section example-classname">
//         <div className="container">
//           <div className="homepage-heading">
//             <h4>What We Do?</h4>
//             <h2>Our Services</h2>
//             <p>
//               At Lakebrains technologies, we offer a suite of client-focused,
//               specially tailored design and development services for both web
//               and mobile platforms.
//             </p>
//           </div>
//           <div className="tabsForServices">
//             <Tabs>
//               <TabList>
//                 <Tab>Browser Extension</Tab>
//                 <Tab>Web Development</Tab>
//                 <Tab>Natural Language Processing</Tab>
//               </TabList>

//               {/* Browser Extension Tabpanel*/}

//               <TabPanel>
//                 <div className="secondTabWrap tab-cntnt">
//                   <div className="row service-bg-row">
//                     <div className="col-md-5">
//                       <div className="sideImageWrap">
//                         <video
//                           muted
//                           autoPlay={"autoplay"}
//                           preload="auto"
//                           loop
//                           alt="Browser Extension Development Company"
//                         >
//                           <source src={extension_video} type="video/webm" />
//                         </video>
//                       </div>
//                     </div>
//                     <div className="col-md-7">
//                       <div className="content">
//                         <div className="technologies">
//                           <div className="row">
//                             <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/chrome-browser-extension-development-company/">
//                                   <img
//                                     src={Ai_Based}
//                                     alt="Browser Extension with AI "
//                                   />
//                                   <p>AI Enable Browser Extension</p>
//                                 </a>
//                               </div>
//                             </div>
//                             <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/chrome-browser-extension-development-company/">
//                                   <img
//                                     src={saas_based}
//                                     alt="Browser Extension For SaaS Products"
//                                   />
//                                   <p>Browser Extension For SaaS Products</p>
//                                 </a>
//                               </div>
//                             </div>
//                             <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/chrome-browser-extension-development-company/">
//                                   <img
//                                     src={Migration}
//                                     alt="Migration From Manifest V2 to V3"
//                                   />
//                                   <p>Migration From Manifest V2 to V3</p>
//                                 </a>
//                               </div>
//                             </div>
//                             <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a
//                                   href="/
//                                 /chrome-browser-extension-development-company/"
//                                 >
//                                   <img
//                                     src={chatgpt}
//                                     alt="React Based Browser Extension"
//                                   />
//                                   <p>Chatgpt Based Browser Extension</p>
//                                 </a>
//                               </div>
//                             </div>
//                             <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/chrome-browser-extension-development-company/">
//                                   <img
//                                     src={Automation}
//                                     alt="Extension For Web Automation"
//                                   />
//                                   <p>Extension For Web Automation</p>
//                                 </a>
//                               </div>
//                             </div>
//                             <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/chrome-browser-extension-development-company/">
//                                   <img
//                                     src={web_scraping}
//                                     alt="Extension Based Data Extractor"
//                                   />
//                                   <p>Extension Based Data Extractor</p>
//                                 </a>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </TabPanel>

//               {/* Web Development Tabpanel */}

//               <TabPanel>
//                 <div className="thirdTabWrap tab-cntnt">
//                   <div className="row service-bg-row">
//                     <div className="col-md-5">
//                       <div className="sideImageWrap">
//                         {/* <img src={Service_Tab} alt="Service NLP" /> */}
//                         <div>
//                           {" "}
//                           <video
//                             // className=""
//                             muted
//                             autoPlay={"autoplay"}
//                             preload="auto"
//                             loop
//                             alt="Web App Develpment Company"
//                           >
//                             <source src={web_video} type="video/webm" />
//                           </video>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-md-7">
//                       <div className="content">
//                         {/* <div className="head">
//                           <h4>
//                             Lakebrains has proven experience of working on state
//                             of art NLP algorithms.
//                           </h4>
//                         </div> */}
//                         <div className="technologies">
//                           <div className="row justify-content-center">
//                             <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/web-application-development-company/">
//                                   <img
//                                     src={saas_web}
//                                     alt="Web Based SaaS Products"
//                                   />
//                                   <p>Web Based SaaS Products</p>
//                                 </a>
//                               </div>
//                             </div>
//                             <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/web-application-development-company/">
//                                   <img
//                                     src={ai_based_web}
//                                     alt="AI Enabled Web Application"
//                                   />
//                                   <p>AI Enabled Web Application</p>
//                                 </a>
//                               </div>
//                             </div>
//                             <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/web-application-development-company/">
//                                   <img
//                                     src={mern}
//                                     alt="MERN Stack Web Development"
//                                   />
//                                   <p>MERN Stack Web Development</p>
//                                 </a>
//                               </div>
//                             </div>
//                             <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/web-application-development-company/">
//                                   <img
//                                     src={visualization}
//                                     alt="Web Based Data Visualizer"
//                                   />
//                                   <p>Web Based Data Visualizer</p>
//                                 </a>
//                               </div>
//                             </div>
//                             <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/web-application-development-company/">
//                                   <img
//                                     src={hubspot}
//                                     alt="HubSpot CMS Development"
//                                   />
//                                   <p>HubSpot CMS Development</p>
//                                 </a>
//                               </div>
//                             </div>
//                             {/* <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <img src={Keyword} alt="Keyword Extraction" />
//                                 <p>Keyword Extraction</p>
//                               </div>
//                             </div> */}
//                           </div>
//                         </div>
//                         {/* <div className="caseStudyWrap">
//                           <div className="head underline">
//                             <h3>Case Study</h3>
//                           </div>
//                           <div className="casestudy-slider">
//                             <Carousel
//                               showArrows={true}
//                               showThumbs={false}
//                               showStatus={false}
//                               showIndicators={false}
//                             >
//                               <div>
//                                 <div className="slideWrap">
//                                   <div className="">
//                                     <div className="row tabSlide">
//                                       <div className="col-lg-4">
//                                         <img src={Service_Tab} alt="" />
//                                       </div>
//                                       <div className="col-lg-8">
//                                         <div className="inner-cntnt">
//                                           <h3>Case Study Title</h3>
//                                           <p>
//                                             Lorem ipsum dolor sit, amet
//                                             consectetur adipisicing elit. Facere
//                                             aliquid iste, placeat quam quaerat
//                                             facilis ratione laboriosam corrupti
//                                             illo nobis.
//                                           </p>
//                                           <a href="#" className="link">
//                                             Read More
//                                           </a>
//                                         </div>
//                                       </div>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                               <div>
//                                 <div className="slideWrap">
//                                   <div className="">
//                                     <div className="row tabSlide">
//                                       <div className="col-lg-4">
//                                         <img src={Service_Tab} alt="" />
//                                       </div>
//                                       <div className="col-lg-8">
//                                         <div className="inner-cntnt">
//                                           <h3>Case Study Title</h3>
//                                           <p>
//                                             Lorem ipsum dolor sit, amet
//                                             consectetur adipisicing elit. Facere
//                                             aliquid iste, placeat quam quaerat
//                                             facilis ratione laboriosam corrupti
//                                             illo nobis.
//                                           </p>
//                                           <a href="#" className="link">
//                                             Read More
//                                           </a>
//                                         </div>
//                                       </div>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                               <div>
//                                 <div className="slideWrap">
//                                   <div className="">
//                                     <div className="row tabSlide">
//                                       <div className="col-lg-4">
//                                         <img src={Service_Tab} alt="" />
//                                       </div>
//                                       <div className="col-lg-8">
//                                         <div className="inner-cntnt">
//                                           <h3>Case Study Title</h3>
//                                           <p>
//                                             Lorem ipsum dolor sit, amet
//                                             consectetur adipisicing elit. Facere
//                                             aliquid iste, placeat quam quaerat
//                                             facilis ratione laboriosam corrupti
//                                             illo nobis.
//                                           </p>
//                                           <a href="#" className="link">
//                                             Read More
//                                           </a>
//                                         </div>
//                                       </div>
//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                             </Carousel>
//                           </div>
//                         </div> */}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </TabPanel>

//               {/* NLP Tabpanel */}
//               <TabPanel>
//                 <div className="firstTabWrap tab-cntnt">
//                   <div className="row service-bg-row">
//                     <div className="col-md-5">
//                       <div className="sideImageWrap">
//                         <div>
//                           <video
//                             muted
//                             autoPlay={"autoplay"}
//                             preload="auto"
//                             loop
//                             alt="NLP Service Provider Company"
//                           >
//                             <source src={nlpVideo} type="video/webm" />
//                           </video>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-md-5">
//                       <div className="content">
//                         <div className="technologies">
//                           <div className="row justify-content-center">
//                             <div className="col-md-5 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/nlp-service-provider-company/">
//                                   <img
//                                     src={Behaviour}
//                                     alt="Behavior Analysis in NLP"
//                                   />
//                                   <p>Behavior Analysis</p>
//                                 </a>
//                               </div>
//                             </div>
//                             {/* <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/services/nlp-service-provider-company/">
//                                 <img src={Emotions} alt="Emotion Analysis in NLP" />
//                                 <p>Emotion Analysis</p>
//                                 </a>
//                               </div>
//                             </div> */}
//                             <div className="col-md-5 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/nlp-service-provider-company/">
//                                   <img
//                                     src={ChatBots}
//                                     alt="Enterprise Chatbots"
//                                   />
//                                   <p>Chatbots</p>
//                                 </a>
//                               </div>
//                             </div>
//                             <div className="col-md-5 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/nlp-service-provider-company/">
//                                   <img
//                                     src={AudioToText}
//                                     alt="Audio to Text in NLP"
//                                   />
//                                   <p>Audio to Text</p>
//                                 </a>
//                               </div>
//                             </div>
//                             {/* <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/services/nlp-service-provider-company/">
//                                 <img src={Topic} alt="Topic Modeling in NLP" />
//                                 <p>Topic Modeling</p>
//                                 </a>
//                               </div>
//                             </div>
//                             <div className="col-md-4 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="/services/nlp-service-provider-company/">
//                                 <img src={Keyword} alt="Keyword Extraction in NLP" />
//                                 <p>Keyword Extraction</p>
//                                 </a>
//                               </div>
//                             </div> */}

//                             <div className="col-md-5 col-6  singleTechWrap">
//                               <div className="singleTech">
//                                 <a href="s/nlp-service-provider-company/">
//                                   <img
//                                     src={Notes}
//                                     alt="Keyword Extraction in NLP"
//                                   />
//                                   <p>Note Captures</p>
//                                 </a>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </TabPanel>
//             </Tabs>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Service;
