import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

import Integrity from "../../../../images/teams/Integrity.webp";
import ContinouesLearning from "../../../../images/teams/Continoues Learning.webp";
import Transparancy from "../../../../images/wordpress/Transparency.webp";

const OurValues = ({ heading, subHeading, description }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const data1 = [
    {
      number: 1,
      para: {
        li: [
          "Provide an environment where we have an honest relationship between our resources and clients.",
        ],
      },
      h6: "Transparency",
      alt: "Transparency",
      image: Transparancy,
    },
    {
      number: 2,
      para: {
        li: [
          `With high moral values and ethics, we always give truthful commitments to our clients.`,
        ],
      },
      h6: "Integrity",
      alt: "Integrity",
      image: Integrity,
    },
    {
      number: 3,
      para: {
        li: [
          `We enhance our skills through continuous learning using the latest technologies.`,
        ],
      },
      h6: "Continuous Learning",
      alt: "Continuous Learning",
      image: ContinouesLearning,
    },
  ];

  return (
    <div className="container-fluid position-relative">
      <div className="example-classname" style={{ padding: "55px 0" }}>
        <div className="container overflow-hidden">
          <div className="LakeComm-Whyu-Wrapper position-relative">
            <div className="OurCommitment-text d-flex text-center flex-column align-items-center" style={{ marginBottom: "65px" }}>
              <h4>{subHeading}</h4>
              <h2>{heading}</h2>
              <p>{description}</p>
            </div>
            <div className="LakeComm-Whyu-Content">
              <div className="row justify-content-center">
                {data1.map((item, key) => (
                  <div key={key} className="col-md-4 col-sm-6 my-2">
                    <div className="LakeComm-Whyu-colum our-services m-1 py-5 px-3">
                      <div className="w-100 h-00">
                        <div className="LakeComm-Whyu-ContentImage m-3 mx-auto">
                          <img
                            className="w-100"
                            src={item.image}
                            alt={item.alt}
                            loading="lazy"
                          />
                        </div>
                        <div className="LakeComm-Whyu-ContentReason text-center">
                          <h6>{item.h6}</h6>
                        </div>
                        <div className="LakeComm-Whyu-Contentpara text-center">
                          <ul>
                            {item.para.li.map((text, index) => (
                              <li key={index}>{text}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurValues;
